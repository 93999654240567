import {
  GaugeCollectionItem,
  NeedleSizeCollectionItem,
  WashAndCareCollectionItem,
  YarnColorCollectionItem,
  YarnPriceCollectionItem,
} from '@/common/interfaces/collection.interface';
import { YarniverseStepsKeys } from '@/pages/yarniverse-page/steps';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ArchiveYarnPayload,
  PublishYarnPayload,
  SetYarnPricePayload,
  WashAndCarePayload,
  Yarn,
  YarnColorImageRemovePayload,
  YarnColorPayload,
  YarnColorRemovePayload,
} from '../types/yarniverse';
import { logoutSuccessAction } from './authorization.reducer';

export interface YarniverseState {
  currentStep: YarniverseStepsKeys;
  isLoading: boolean;
  data: Yarn;
  colors: YarnColorCollectionItem[];
  prices: YarnPriceCollectionItem[];
  gauges: Array<GaugeCollectionItem & { junctionId: number }>;
  needleSizes: Array<NeedleSizeCollectionItem & { junctionId: number }>;
  washAndCare: Array<WashAndCareCollectionItem & { junctionId: number }>;
}

const initialState: YarniverseState = {
  currentStep: YarniverseStepsKeys.Start,
  isLoading: false,
  data: {},
  colors: [],
  prices: [],
  gauges: [],
  needleSizes: [],
  washAndCare: [],
};

export const yarniverseSlice = createSlice({
  name: 'yarniverse',
  initialState,
  reducers: {
    setYarniverseStepAction: (state, action: PayloadAction<YarniverseStepsKeys>) => {
      state.currentStep = action.payload;
    },

    // Get yarn data
    getYarniverseDataAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getYarniverseDataSuccessAction: (state, action: PayloadAction<Yarn>) => {
      state.isLoading = false;
      state.data = action.payload;
    },

    // Update data without saving to backend
    updateYarnStateData(state, action: PayloadAction<Yarn>) {
      state.data = action.payload;
    },

    //Set yarn data with save to backend
    setYarnDataAction: (state, _: PayloadAction<Yarn>) => {
      state.isLoading = true;
    },
    setYarnDataSuccessAction: (state, action: PayloadAction<Yarn>) => {
      state.isLoading = false;
      state.data = action.payload;
    },

    setYarnMainColorAction: (
      state,
      _: PayloadAction<{ yarnId: number; colorId: number | null }>,
    ) => {
      state.isLoading = true;
    },

    setYarnMainColorSuccessAction: (state, action: PayloadAction<Yarn>) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    // Get yarn colors
    getYarnColorsAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getYarnColorsSuccessAction: (state, action: PayloadAction<YarnColorCollectionItem[]>) => {
      state.isLoading = false;
      state.colors = action.payload;
    },
    // Create yarn color
    createYarnColorAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },

    createYarnColorSuccessAction: (state, action: PayloadAction<YarnColorCollectionItem>) => {
      state.isLoading = false;
      state.colors = [...state.colors, action.payload];
    },
    // Update yarn color
    setYarnColorAction: (state, _: PayloadAction<YarnColorPayload>) => {
      state.isLoading = true;
    },
    setYarnColorSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Remove yarn color
    removeYarnColorAction: (state, _: PayloadAction<YarnColorRemovePayload>) => {
      state.isLoading = true;
    },
    removeYarnColorSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Remove yarn color image
    removeYarnColorImageAction: (state, _: PayloadAction<YarnColorImageRemovePayload>) => {
      state.isLoading = true;
    },
    removeYarnColorImageSuccessAction: (state) => {
      state.isLoading = false;
    },

    setYarnWashAndCareAction: (state, _: PayloadAction<WashAndCarePayload>) => {
      state.isLoading = true;
    },
    setYarnWashAndCareSuccessAction: (state, action: PayloadAction<Yarn>) => {
      state.isLoading = false;
      state.data = action.payload;
    },

    // Set price yarn
    setYarnPriceAction: (state, _: PayloadAction<SetYarnPricePayload>) => {},
    setYarnPriceSuccessAction: (state, action: PayloadAction<any>) => {},

    // Get yarn prices
    getYarnPricesAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getYarnPricesSuccessAction: (state, action: PayloadAction<YarnPriceCollectionItem[]>) => {
      state.isLoading = false;
      state.prices = action.payload;
    },
    getYarnGaugesAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getYarnGaugesSuccessAction: (
      state,
      action: PayloadAction<Array<GaugeCollectionItem & { junctionId: number }>>,
    ) => {
      state.isLoading = false;
      state.gauges = action.payload;
    },
    createYarnGaugeAction: (state, _: PayloadAction<{ yarnId: number; gaugeId: number }>) => {
      state.isLoading = true;
    },
    removeYarnGaugeAction: (state, _: PayloadAction<{ yarnId: number; junctionId: number }>) => {
      state.isLoading = true;
    },
    createYarnGaugeSuccessAction: (state, action: PayloadAction<Yarn>) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    removeYarnGaugeSuccessAction: (state) => {
      state.isLoading = false;
    },
    getYarnNeedleSizesAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getYarnNeedleSizesSuccessAction: (
      state,
      action: PayloadAction<Array<NeedleSizeCollectionItem & { junctionId: number }>>,
    ) => {
      state.isLoading = false;
      state.needleSizes = action.payload;
    },
    createYarnNeedleSizeAction: (
      state,
      _: PayloadAction<{ yarnId: number; needleSizeId: number }>,
    ) => {
      state.isLoading = true;
    },
    removeYarnNeedleSizeAction: (
      state,
      _: PayloadAction<{ yarnId: number; junctionId: number }>,
    ) => {
      state.isLoading = true;
    },
    createYarnNeedleSizeSuccessAction: (state, action: PayloadAction<Yarn>) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    removeYarnNeedleSizeSuccessAction: (state) => {
      state.isLoading = false;
    },
    getYarnWashAndCareAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getYarnWashAndCareSuccessAction: (
      state,
      action: PayloadAction<Array<WashAndCareCollectionItem & { junctionId: number }>>,
    ) => {
      state.isLoading = false;
      state.washAndCare = action.payload;
    },
    createYarnWashAndCareAction: (
      state,
      _: PayloadAction<{ yarnId: number; washAndCareId: number }>,
    ) => {
      state.isLoading = true;
    },
    removeYarnWashAndCareAction: (
      state,
      _: PayloadAction<{ yarnId: number; junctionId: number }>,
    ) => {
      state.isLoading = true;
    },
    createYarnWashAndCareSuccessAction: (state, action: PayloadAction<Yarn>) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    removeYarnWashAndCareSuccessAction: (state) => {
      state.isLoading = false;
    },
    // Publish yarn
    publishYarnAction: (state, _: PayloadAction<PublishYarnPayload>) => {
      state.isLoading = true;
    },
    publishYarnSuccessAction: (state) => {
      state.isLoading = false;
    },

    // Clear store
    clearYarniverseAction: () => {
      return initialState;
    },

    // Error action
    yarniverseErrorAction: (state) => {
      state.isLoading = false;
    },

    createMultipleYarnColorsAction: (
      state,
      _: PayloadAction<{ yarnId: number; yarnColors: { name: string; image: File }[] }>,
    ) => {
      state.isLoading = true;
    },
    createMultipleYarnColorsSuccessAction: (state) => {
      state.isLoading = false;
    },
    // Archive yarn
    archiveYarnAction: (state, _: PayloadAction<ArchiveYarnPayload>) => {
      state.isLoading = true;
    },
    archiveYarnSuccessAction: (state) => {
      state.isLoading = false;
    },
    archiveYarnErrorAction: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccessAction, () => initialState);
  },
});

export const {
  setYarniverseStepAction,
  setYarnDataAction,
  setYarnDataSuccessAction,
  getYarnColorsAction,
  getYarnColorsSuccessAction,
  setYarnColorAction,
  setYarnColorSuccessAction,
  yarniverseErrorAction,
  removeYarnColorAction,
  removeYarnColorSuccessAction,
  removeYarnColorImageAction,
  removeYarnColorImageSuccessAction,
  updateYarnStateData,
  getYarniverseDataAction,
  getYarniverseDataSuccessAction,
  setYarnPriceAction,
  setYarnPriceSuccessAction,
  getYarnPricesAction,
  getYarnPricesSuccessAction,
  setYarnMainColorAction,
  setYarnMainColorSuccessAction,
  publishYarnAction,
  publishYarnSuccessAction,
  clearYarniverseAction,
  createYarnColorAction,
  createYarnColorSuccessAction,
  createMultipleYarnColorsAction,
  createMultipleYarnColorsSuccessAction,
  getYarnNeedleSizesAction,
  getYarnNeedleSizesSuccessAction,
  removeYarnNeedleSizeSuccessAction,
  createYarnNeedleSizeAction,
  removeYarnNeedleSizeAction,
  createYarnNeedleSizeSuccessAction,
  getYarnWashAndCareAction,
  getYarnWashAndCareSuccessAction,
  removeYarnWashAndCareSuccessAction,
  createYarnWashAndCareAction,
  removeYarnWashAndCareAction,
  createYarnWashAndCareSuccessAction,
  getYarnGaugesAction,
  getYarnGaugesSuccessAction,
  removeYarnGaugeSuccessAction,
  createYarnGaugeAction,
  removeYarnGaugeAction,
  createYarnGaugeSuccessAction,
  archiveYarnAction,
  archiveYarnSuccessAction,
  archiveYarnErrorAction,
} = yarniverseSlice.actions;

export const yarniverseReducer = yarniverseSlice.reducer;
