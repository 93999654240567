import { Dropdown } from '@/components/dropdown';
import { Input } from '@/components/input/input';
import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import { setYarnDataAction } from '@/store/reducers/yarniverse.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { Yarn } from '@/store/types/yarniverse';
import { Grid } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  yarn: Yarn;
}
const WEIGHT_UNITS = ['g', 'ounce'];
const LENGTH_UNITS = ['cm ', 'm', 'in', 'yards'];

export const Amount: FC<Props> = (props) => {
  const { yarn } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { Units } = useSelector(getCollectionDataSelector);

  const [weight, setWeight] = useState<string | undefined>(yarn.amount_weight);
  const [length, setLength] = useState<string | undefined>(yarn.amount_lenght);
  const [weightUnit, setWeightUnit] = useState<number | undefined>(yarn.amount_weight_unit);
  const [lengthUnit, setLengthUnit] = useState<number | undefined>(yarn.amount_lenght_unit);

  const [typeWeight, setTypeWeight] = useInputWithDelay(
    yarn.amount_weight || '',
    (value) => setWeight(value),
    1000,
  );
  const [typeLength, setTypeLength] = useInputWithDelay(
    yarn.amount_lenght || '',
    (value) => setLength(value),
    1000,
  );

  const UnitOptions = useMemo(() => {
    return Units?.map((el) => ({ label: el.name, value: el.id }));
  }, [Units]);

  useEffect(() => {
    dispatch(
      setYarnDataAction({
        ...yarn,
        amount_weight: weight,
        amount_weight_unit: weightUnit,
        amount_lenght: length,
        amount_lenght_unit: lengthUnit,
      }),
    );
  }, [weight, weightUnit, length, lengthUnit]);

  return (
    <Container>
      <Grid container direction='row'>
        <Input
          placeholder={t('Yarniverse.typeWeight')}
          value={typeWeight}
          onChange={(e) => setTypeWeight(e.target.value)}
        />
        <Dropdown
          value={weightUnit}
          options={UnitOptions.filter((el) => WEIGHT_UNITS.includes(el.label))}
          onChange={(value) => setWeightUnit(value as number)}
          placeholder={t('Yarniverse.selectUnitOfMeasurement')}
        />
      </Grid>

      <Grid container direction='row'>
        <Input
          placeholder={t('Yarniverse.typeLength')}
          value={typeLength}
          onChange={(e) => setTypeLength(e.target.value)}
        />
        <Dropdown
          value={lengthUnit}
          options={UnitOptions.filter((el) => LENGTH_UNITS.includes(el.label))}
          onChange={(value) => setLengthUnit(value as number)}
          placeholder={t('Yarniverse.selectUnitOfMeasurement')}
        />
      </Grid>
    </Container>
  );
};
