import { styled } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';

const PlaceholderContainer = styled('button')(({ theme }) => ({
  width: '207px',
  minHeight: '580px',
  padding: '16px',
  backgroundColor: theme.palette.secondary.main,
  opacity: 0.7,
  border: `1px solid black`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '36px',
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  '&:disabled': {
    cursor: 'not-allowed',
  },
  '&:hover:not(:disabled)': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const StyledAddIcon = styled(AddIcon)({
  fontSize: '48px',
  color: 'black',
  marginBottom: '8px',
});

const Text = styled('p')({
  color: 'black',
  margin: 0,
  fontSize: '1rem',
  textAlign: 'center',
});

interface Props {
  onClick?: () => void;
  disabled: boolean;
}

export const EmptyYarnColorPlaceholder: FC<Props> = ({ onClick, disabled }) => {
  const { t } = useTranslation();

  return (
    <PlaceholderContainer disabled={disabled} onClick={onClick}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <StyledAddIcon />
        <Text>{t('Yarniverse.addColor')}</Text>
      </div>
    </PlaceholderContainer>
  );
};
