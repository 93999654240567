import { Button } from '@/components/button/button';
import { styled } from '@mui/material';

export const Header = styled('div')(({ theme }) => ({
  minHeight: '60rem',
  width: '100%',
  display: 'grid',
  grid: 'none',
  borderLeft: '1px solid #000000',
  borderRight: '1px solid #000000',
  borderBottom: '1px solid #000000',
  overflow: 'hidden',
  [theme.breakpoints.up('laptop')]: {
    grid: 'auto / 1fr 1fr',
  },
}));

export const ButtonsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  marginTop: '2rem',
});

export const Buttons = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
});

export const PromotedButton = styled(Button)({
  marginBottom: '2rem',
});

export const PageContainer = styled('section')(() => ({
  maxWidth: '1600px',
  margin: '0 auto',
  paddingBottom: '2rem',
}));

export const TabsSection = styled('section')({
  borderBottom: '1px solid #000',
  padding: '1.25rem 0',
});

export const UpperCaseTitle = styled('div')({
  padding: '1rem 1.5rem',
  textTransform: 'uppercase',
  lineHeight: '6vw',
  fontSize: '7.5vw',
  fontWeight: 'bold',
});

export const GridContainer = styled('div')<{ rows: number }>(({ rows }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridTemplateRows: `repeat(${rows}, auto)`,
  gap: '2rem',
}));

export const GridItem = styled('div')<{ column: number; row: number }>(({ column, row }) => ({
  gridColumn: column,
  gridRow: row,
  display: 'flex',
  alignItems: 'end',
  flexWrap: 'wrap',
  gap: '1rem',
  borderBottom: '1px solid #000',
  paddingBottom: '4px',
  marginBottom: '8px',
}));

export const HeaderItem = styled('div')<{ column: number; row: number }>(({ column, row }) => ({
  gridColumn: column,
  gridRow: row,
  fontSize: '40px',
}));

export const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
  },
}));

export const Block = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '2rem',
  border: '1px solid #000',
  borderTop: 'none',
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up('laptop')]: {
    '&:first-child': {
      borderRight: 'none',
    },
  },
}));

export const AccordionTitle = styled('div')({
  fontSize: '40px',
  display: 'flex',
  alignItems: 'center',
  gap: '2rem',
  cursor: 'pointer',
});

export const TechnicalInformationTitle = styled('h4')({
  fontSize: '22px',
  fontWeight: 'normal',
});

export const TechnicalInformationValue = styled('h2')({
  fontSize: '50px',
  fontWeight: 'normal',
});
