import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import { FileItem, ManyItems, OneItem, TransportResponse } from '@directus/sdk';
import { FileFolders } from '@/common/constants/fileFolders';
import { Collections } from '@/common/interfaces/collection.interface';
import {
  createResourceDataAction,
  createResourceDataSuccessAction,
  createResourceDataErrorAction,
  getResourceDataAction,
  getResourceDataSuccessAction,
  getResourceDataErrorAction,
  archiveResourceAction,
  archiveResourceSuccessAction,
  archiveResourceErrorAction,
} from '../reducers/resource.reducer';
import {
  getKnitryResourcesAction,
  getKnitryResourcesSuccessAction,
  getKnitryResourcesErrorAction
} from '../reducers/resource-all.reducer';
import { getCollectionDataSelector } from '../selectors/collection.selector';
import { ProductStatus } from '@/common/constants/product-status';
import { displayErrorAction } from '../reducers/system.reducer';
import { ResourceWithStage } from '../types/pattern';
import { DirectusFields } from '@/common/constants/directus-fields';

function* getKnitryResourcesRequest(api: ApiType, action: ReturnType<typeof getKnitryResourcesAction>) {
  try {
    const response: TransportResponse<ResourceWithStage[]> = yield call(
      api.getCollection,
      Collections.Resources,
      {
        filter: {
          isOfficial: true,
        },
        fields: DirectusFields.Resource,
      },
    );
    if (!response.data) {
      throw new Error();
    }
    yield put(getKnitryResourcesSuccessAction(response.data || []));
  } catch (error) {
    yield put(getKnitryResourcesErrorAction());
  }
}

function* createResourceDataRequest(
  api: ApiType,
  action: ReturnType<typeof createResourceDataAction>,
) {
  const resource = action.payload;

  const categorizationData: any = {
    [Collections.ResourceType]: resource[Collections.ResourceType]?.map(id => ({
      resource_type_id: id,
    })) ?? [],
    [Collections.ResourceContentType]: resource[Collections.ResourceContentType]?.map(id => ({
      resource_content_type_id: id,
    })) ?? [],
  }

  try {
    if (!resource.files?.length) {
      throw new Error('No files provided');
    }
    //create resource
    const resourceResponse: OneItem<any> = yield call(api.createResource, {
      ...resource,
      ...categorizationData,
      files: [],
      status: ProductStatus.Published,
    });

    if (!resourceResponse) {
      throw new Error('Resource creation failed');
    }
    //files upload
    let files;

    const fileResponse: OneItem<FileItem> = yield call(
      api.uploadFile,
      FileFolders.Resources,
      resource.files,
    );
    if (fileResponse) {
      files = Array.isArray(fileResponse) ? fileResponse : [fileResponse];
    } else {
      throw new Error('File upload failed');
    }
    //create files collection items
    const fileCollectionItemsResponse: ManyItems<any> = yield call(
      api.createCollectionItems,
      Collections.ResourcesImages,
      files.map((file) => ({
        file: file.id,
        resource: resourceResponse.id,
      })),
    );
    if (fileCollectionItemsResponse) {
      yield put(createResourceDataSuccessAction(fileCollectionItemsResponse));
    } else {
      throw new Error('Files have not been added to the collection');
    }
  } catch (error) {
    console.error(error);
    yield put(createResourceDataErrorAction(error));
  }
}

function* archiveResourceRequest(api: ApiType, action: ReturnType<typeof archiveResourceAction>) {
  try {
    const { resourceId, callback } = action.payload;

    yield call(api.updateResource, {
      id: resourceId,
      status: ProductStatus.Archived,
    });

    yield put(archiveResourceSuccessAction());

    yield call(callback, true);
  } catch (error: any) {
    yield put(archiveResourceErrorAction(error?.message));
    yield put(displayErrorAction(error?.message));
  }
}

export const resourceSaga = function* (api: ApiType) {
  yield all([takeLatest(createResourceDataAction.type, createResourceDataRequest, api)]);
  yield all([takeLatest(archiveResourceAction.type, archiveResourceRequest, api)]);
  yield all([takeLatest(getKnitryResourcesAction.type, getKnitryResourcesRequest, api)]);
};
