import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ItemInBasketPayload, BasketItem } from '../types/basket';
import { logoutSuccessAction } from './authorization.reducer';

export interface BasketState {
  open: boolean;
  data: BasketItem[];
  isLoading: boolean;
}

const initialState: BasketState = {
  open: false,
  data: [],
  isLoading: false,
};

export const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    openBasketAction(state) {
      state.open = true;
    },
    closeBasketAction(state) {
      state.open = false;
    },
    addItemToBasketAction(state, action: PayloadAction<ItemInBasketPayload>) {
      const { quantity = 1, ...restOfPayload } = action.payload;

      state.data = (state.data || []).find(
        (el) => action.payload.id === el.id && action.payload.type === el.type,
      )
        ? state.data
        : [...(state.data || []), { ...restOfPayload, data: { quantity } }];
      state.open = true;
    },
    removeItemInBasketAction(state, action: PayloadAction<ItemInBasketPayload>) {
      state.data = [
        ...state.data.filter(
          (item) => item.id !== action.payload.id || item.type !== action.payload.type,
        ),
      ];
      state.open = true;
    },
    incrementItemInBasketAction(state, action: PayloadAction<BasketItem>) {
      state.open = true;
    },
    incrementItemInBasketSuccessAction(state, action: PayloadAction<BasketItem[]>) {
      state.isLoading = false;
      state.data = action.payload;
    },
    incrementItemInBasketErrorAction(state) {
      state.isLoading = false;
    },
    decrementItemInBasketAction(state, action: PayloadAction<BasketItem>) {
      state.open = true;
    },
    // Get items from basket
    loadItemsFromBasketAction(state) {
      state.isLoading = true;
    },
    loadItemFromBasketSuccessAction(state, action: PayloadAction<BasketItem[]>) {
      state.isLoading = false;
      state.data = action.payload;
    },
    loadItemFromBasketErrorAction(state) {
      state.isLoading = false;
    },
    clearBasketAction(state) {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccessAction, () => initialState);
  },
});

export const {
  addItemToBasketAction,
  openBasketAction,
  closeBasketAction,
  loadItemsFromBasketAction,
  loadItemFromBasketSuccessAction,
  loadItemFromBasketErrorAction,
  clearBasketAction,
  removeItemInBasketAction,
  incrementItemInBasketAction,
  incrementItemInBasketSuccessAction,
  incrementItemInBasketErrorAction,
  decrementItemInBasketAction,
} = basketSlice.actions;

export const basketReducer = basketSlice.reducer;
