import { styled } from '@mui/material/styles';
import { FC, useState, useEffect } from 'react';
import { Typography, MenuItem, Select, CircularProgress, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import useDirectus from '@/hooks/directus/useDirectus'; // Assuming the Directus hook is implemented

export interface StoreSelectorProps {
  onStoreChange?: (storeId: string | null) => void; // Callback for when a store is selected
}

const Container = styled('div')({
  margin: '0 -0.25rem',
});

export const StoreSelector: FC<StoreSelectorProps> = ({ onStoreChange }) => {
  const { list, loading, error } = useDirectus();
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState<string | null>(null);

  useEffect(() => {
    // Fetch stores from the "Stores" collection
    const fetchStores = async () => {
      try {
        const query = {
          fields: ['id', 'name'], // Only fetch the required fields
          filter: { status: { _eq: 'published' } }, // Filter for published stores
        };
        const response = await list('stores', query); // Fetch from Stores collection
        setStores(response.data);
      } catch (err) {
        console.error('Error fetching stores:', err);
      }
    };
    fetchStores();
  }, [list]);

  const handleStoreChange = (event: SelectChangeEvent<string>) => {
    const storeId = event.target.value as string;
    setSelectedStore(storeId);
    if (onStoreChange) onStoreChange(storeId);
  };

  return null; // Placeholder

  return (
    <Container>      
      {loading ? (
        <CircularProgress size={24} />
      ) : error ? (
        <Typography color="error">Failed to load stores</Typography>
      ) : (
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="store-selector-label">Selected store</InputLabel>
          <Select
            labelId="store-selector-label"
            value={selectedStore || ''}
            onChange={handleStoreChange}
            label="Selected store"
          >
            <MenuItem value="">
              <em>Select store</em>
            </MenuItem>
            {stores.map((store: { id: string; name: string }) => (
              <MenuItem key={store.id} value={store.id}>
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Container>
  );
};