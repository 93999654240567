import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { ImgVideo } from '@/components/img-video/img-video';

export const ListEl = styled('li')<{ isActive?: boolean }>(({ theme, isActive }) => ({
  borderBottom: '1px solid #000',
  marginBottom: '1.5rem',
  position: 'relative',
  background: isActive ? '#F6F4ED' : 'transparent',

  [theme.breakpoints.down('laptop')]: {
    marginBottom: '1rem',
  },

  ':hover .MuiButtonBase-root': {
    visibility: 'visible',
  },

  // Show copy button by default on mobile
  [theme.breakpoints.down('laptop')]: {
    '.MuiButtonBase-root': {
      visibility: 'visible',
      opacity: 0.8,
    }
  }
}));

export const Image = styled(ImgVideo)(({ theme }) => ({
  display: 'block',
  width: '100%',
  cursor: 'pointer',

  [theme.breakpoints.down('laptop')]: {
    minHeight: '120px', // Ensure minimum touch target size
  }
}));

export const Title = styled('p')(({ theme }) => ({
  margin: 0,
  fontSize: '1.125rem',
  lineHeight: '1.5rem',
  [theme.breakpoints.down('laptop')]: {
    fontSize: '1rem',
    padding: '0.75rem 0', // Larger touch target
  }
}));

export const CopyBtn = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem',
  backgroundColor: '#fff',
  color: '#000',
  visibility: 'hidden',
  padding: '4px 8px',

  [theme.breakpoints.down('laptop')]: {
    padding: '8px 12px', // Larger touch target
  },

  ':hover': {
    backgroundColor: '#F6F4ED',
  },
}));