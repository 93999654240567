import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { YarnManufacturer } from '@/common/interfaces/collection.interface';

export interface YarnManufactureWeekState {
  isYarnManufactureWeekLoading: boolean;
  yarnManufactureWeek: YarnManufacturer[];
  error: boolean;
}

const initialState: YarnManufactureWeekState = {
  isYarnManufactureWeekLoading: false,
  yarnManufactureWeek: [],
  error: false,
};

export const yarnManufactureWeekSlice = createSlice({
  name: 'yarn-manufacture-week',
  initialState,
  reducers: {
    // Get yarn by id
    getYarnManufactureWeekAction: (state) => {
      state.isYarnManufactureWeekLoading = true;
    },
    getYarnManufactureWeekSuccessAction: (state, action: PayloadAction<YarnManufacturer[]>) => {
      state.isYarnManufactureWeekLoading = false;
      state.yarnManufactureWeek = action.payload;
    },
    getManufacturWeekErrorAction: (state) => {
      state.isYarnManufactureWeekLoading = false;
    },
  },
});

export const {
  getYarnManufactureWeekAction,
  getYarnManufactureWeekSuccessAction,
  getManufacturWeekErrorAction,
} = yarnManufactureWeekSlice.actions;

export const yarnManufactureWeekReducer = yarnManufactureWeekSlice.reducer;
