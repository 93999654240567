import { CheckboxInput } from '@/components/radio-checkbox-input/radio-checkbox-input';
import { ResourceWithStage } from '@/store/types/pattern';
import { noop } from '@/utils/noop';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChangeEvent, FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImgVideo } from '../img-video/img-video';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';

export interface ResourcesBrowseProps {
  browseText?: string;
  onResourceSelect?: (selectedResources: number[]) => void;
  selectedResources?: number[];
  resourcesCollection?: ResourceWithStage[];
  stepsCount?: number;
}

const Container = styled('div')({
  height: '100%',
  overflow: 'scroll',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
});

const Title = styled('div')(({ theme }) => ({
  ...theme.typography.h2,
  borderBottom: '1px solid #000',
  padding: '2rem 2rem 1rem 2rem',
}));

const ResourcesContainer = styled('div')({
  display: 'flex',
  padding: '1rem 2rem',
  gap: '1rem',
  flexWrap: 'wrap',
});

const ResourceContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // gap: '0.5rem',
  // padding: '1rem',
  border: '1px solid #000',
});

export const ResourcesBrowse: FC<ResourcesBrowseProps> = ({
  browseText,
  onResourceSelect = noop,
  selectedResources = [],
  resourcesCollection = [],
  stepsCount,
}) => {
  const { t } = useTranslation();

  const [_, rerender] = useState(false);
  const selectedIdsCache = useRef(new Map<number, boolean>());

  const onChangeHandler = ({ target: { value, checked } }: ChangeEvent<HTMLInputElement>) => {
    const val = +value;
    const valueToSet: number[] = checked
      ? [...selectedResources, val]
      : selectedResources.filter((id) => id !== val);

    selectedIdsCache.current.set(val, checked);
    rerender(v => !v);

    onResourceSelect(valueToSet);
  };

  return (
    <Container>
      <Title>{browseText}</Title>
      {stepsCount !== undefined && stepsCount < 1
        ? <Typography variant="body1" color="red" sx={{ padding: '1rem 2rem 0 2rem' }}>{t('resourcesSidebar.noStepDescription')}</Typography>
        : <Typography variant="body1" sx={{ padding: '1rem 2rem 0 2rem' }}>{t('resourcesSidebar.selectResources')}</Typography>}
      <ResourcesContainer>
        {resourcesCollection.map(({ name, id, files }) => {
          const checkedCached = id ? selectedIdsCache.current.get(id) : undefined;
          const checked = checkedCached ?? selectedResources.includes(id as number);
          return (
            <ResourceContainer key={id}>
              <CheckboxInput
                label={name}
                key={id}
                checked={checked}
                disabled={stepsCount !== undefined && stepsCount < 1}
                noBorder={true}
                inputProps={{
                  value: id,
                  onChange: onChangeHandler,
                  name,
                  checked,
                }}
              />
              <ImgVideo src={files && files.length > 0 ? getAssetBackendUrl(files[0]?.file, 600) : undefined}/>
            </ResourceContainer>
          );
        })}
      </ResourcesContainer>
    </Container>
  );
};
