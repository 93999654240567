export type Coordinates = [number, number];

export const oslo: Coordinates = [59.91273, 10.74609];
export const asker: Coordinates = [59.8346, 10.4346];
export const bergen: Coordinates = [60.3913, 5.3221];
export const voksenlia: Coordinates = [59.9933, 10.6367];
export const stavanger: Coordinates = [58.9701, 5.7331];
export const kristiansand: Coordinates = [58.1462, 7.9956];

export const london: Coordinates = [51.505, -0.09];
export const newYork: Coordinates = [40.7128, -74.0060];
export const sydney: Coordinates = [-33.8688, 151.2093];
export const dubai: Coordinates = [25.276987, 55.296249];
export const stockholm: Coordinates = [59.3293, 18.0686];
export const malmo: Coordinates = [55.604981, 13.003822];
export const trelleborg: Coordinates = [55.3759, 13.1569];