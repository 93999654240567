import { useIntersection } from '@/hooks/useIntersection';
import { isEmptyAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { ComponentPropsWithRef, FC, memo, MouseEvent, useMemo, useRef, useState, useEffect } from 'react';
import { Container, Img, Loader, Video } from './styles';

export interface ImgVideoProps extends ComponentPropsWithRef<'span'> {
  src?: string | null;
  placeholder?: string | null;
  videoProps?: ComponentPropsWithRef<'video'>;
  playVideoOnHover?: boolean;
  paddingBottom?: string;
  paddingBottomMobile?: string;
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  backgroundColor?: string;
  naturalAspectRatio?: boolean;
}

export const ImgVideo: FC<ImgVideoProps> = memo(
  ({ src, videoProps, placeholder, playVideoOnHover = false, paddingBottom, paddingBottomMobile, objectFit, backgroundColor, naturalAspectRatio: naturalSize, ...rest }) => {
    const elRef = useRef<HTMLSpanElement>(null);
    const isVisible = useIntersection<HTMLSpanElement>(elRef);
    const [loading, setLoading] = useState(true);
    const [isVideo, setIsVideo] = useState(false);

    useEffect(() => {
      setIsVideo(false);
      setLoading(true);
    }, [src]);

    const loadSuccessHandler = () => {
      setLoading(false);
    };

    const imgLoadErrorhandler = (e: any) => {
      setIsVideo(true);
    };

    const videoLoadErrorhandler = (e: any) => {
      setLoading(false);
    };

    const isLoaded = !loading || isVisible;

    const videoOnHoverProps = playVideoOnHover
      ? {
          onMouseEnter: ({ target }: MouseEvent) => (target as HTMLVideoElement).play(),
          onMouseLeave: ({ target }: MouseEvent) => (target as HTMLVideoElement).pause(),
        }
      : {};

    const isValidSrc: boolean = useMemo(() => {
      return !isEmptyAssetBackendUrl(src);
    }, [src]);

    return (
      <Container backgroundColor={backgroundColor} paddingBottom={paddingBottom} paddingBottomMobile={paddingBottomMobile} naturalSize={naturalSize} {...rest} ref={elRef}>
        {src && isValidSrc && isLoaded && (
          <>
            {isVideo ? (
              <Video
                src={src}
                onCanPlay={loadSuccessHandler}
                onError={videoLoadErrorhandler}
                muted
                naturalSize={naturalSize}
                {...videoOnHoverProps}
                {...videoProps}
              />
            ) : (
              <Img objectFit={objectFit} src={src} onError={imgLoadErrorhandler} onLoad={loadSuccessHandler} naturalSize={naturalSize}/>
            )}
            {loading && <Loader />}
          </>
        )}
      </Container>
    );
  },
);
