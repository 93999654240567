import React, { useState } from 'react';
import { 
  Container, 
  ResourceList, 
  AccordionTitle, 
  StyledAccordion, 
  Spacer, 
  ToggleButton,
} from './styles';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Resources } from '../resources';
import { ResourceWithStage } from '@/store/types/pattern';
import { useTranslation } from 'react-i18next';
import { ResourceViewDrawer } from '@/components/resource-view-drawer/resource-view-drawer';

interface ResourcesPanelProps {
  resources: ResourceWithStage[];
  diagrams: ResourceWithStage[];
  knitryResources: ResourceWithStage[];
  onOpenResource: (id: number, type: 'resource' | 'diagram') => void;
  isMenuVisible: boolean;
  showInPanel?: boolean;
  isMobile?: boolean;
}

export const ResourcesPanel: React.FC<ResourcesPanelProps> = ({ 
  resources, 
  diagrams,
  knitryResources,
  onOpenResource,
  isMenuVisible,
  showInPanel = true,
  isMobile = false
}) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);
  const [resourceViewId, setResourceViewId] = useState<number | null>(null);
  const [resourceViewType, setResourceViewType] = useState<'resource' | 'diagram' | null>(null);

  const handleToggle = () => {
    setIsVisible(!isVisible);
  };

  const handleOpenResource = (id: number, type: 'resource' | 'diagram') => {
    setResourceViewId(id);
    setResourceViewType(type);
  };

  const handleCloseResource = () => {
    setResourceViewId(null);
    setResourceViewType(null);
  };

  return (
    <>
      {!isMobile && <Spacer isVisible={isVisible} />}
      <Container isVisible={isVisible} isMenuVisible={isMenuVisible} isMobile={isMobile}>
        <ResourceList>
          {/* Charts & Schematics with new diagram viewer */}
          <StyledAccordion
            defaultExpanded
            renderSummary={() => <AccordionTitle>{t('knitmode.chartsSchematics')}</AccordionTitle>}
            renderDetails={() => (
              <Resources 
                resources={diagrams} 
                onOpenPreview={(id) => onOpenResource(id, 'diagram')}
              />
            )}
          />

          {/* Pattern Resources with original viewer */}
          <StyledAccordion
            renderSummary={() => <AccordionTitle>{t('knitmode.patternResources')}</AccordionTitle>}
            renderDetails={() => (
              <Resources 
                resources={resources} 
                onOpenPreview={(id) => handleOpenResource(id, 'resource')}
              />
            )}
          />

          {/* Knitry Resources with original viewer */}
          <StyledAccordion
            renderSummary={() => <AccordionTitle>{t('knitmode.knitryResources')}</AccordionTitle>}
            renderDetails={() => (
              <Resources 
                resources={knitryResources} 
                onOpenPreview={(id) => handleOpenResource(id, 'resource')}
              />
            )}
          />
        </ResourceList>

        {!isMobile && (
          <ToggleButton 
            onClick={handleToggle}
            aria-label={t('knitmode.toggleMenu')}
          >
            {isVisible ? <ChevronRight /> : <ChevronLeft />}
          </ToggleButton>
        )}

        {/* Original resource viewer drawer for non-diagram resources */}
        {resourceViewId && resourceViewType && (
          <ResourceViewDrawer
            resourceId={resourceViewId}
            resourceType={resourceViewType}
            onClose={handleCloseResource}
            showInPanel={showInPanel}
          />
        )}
      </Container>
    </>
  );
};
