import React from 'react';
import styled from '@emotion/styled';

const Bound = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1rem 0;
    background-color: white;
`;

const ImageContainer = styled.div`
    position: relative;
    width: 336px
    height: 336px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-bottom: 1px solid black;
    }
`;

interface CardProps {
    text: string;
    quantity: number;
    url: string;
    placeholder?: string;
}

export const Card = ({ text, quantity, url, placeholder = 'Company logo' }: CardProps) => {
    return (
        <Bound>
            <p>{text}</p>
            <ImageContainer>
                <img src={url} alt={placeholder} />
            </ImageContainer>
            <p>{quantity} yarn qualities</p>
        </Bound>
    );
}