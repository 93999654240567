import { PageContentCollectionItem } from '@/common/interfaces/pages-content.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PatternWithStage } from '../types/pattern';
import { User } from '@/common/interfaces/user.interface';
import { YarnWithStage } from '../types/yarniverse';

export interface CollectionState {
  isLoading: boolean;
  content: PageContentCollectionItem[];
  isRecentPatternUploadsLoading: boolean;
  recentPatternUploads: PatternWithStage[];
  isDesignersOfTheWeekLoading: boolean;
  designersOfTheWeek: User[];
  isPromotedPatternsLoading: boolean;
  promotedPatterns: PatternWithStage[];
  isRecentDesignersLoading: boolean;
  recentDesigners: User[];
  isPromotedYarnLoading: boolean;
  promotedYarn: PatternWithStage[];
  isRecentYarnUploadsLoading: boolean;
  recentYarnUploads: YarnWithStage[];
  isYarnItemsListLoading: boolean;
  yarnItemsList: YarnWithStage[];
}

const initialState: CollectionState = {
  isLoading: false,
  content: [],
  isRecentPatternUploadsLoading: false,
  recentPatternUploads: [],
  isDesignersOfTheWeekLoading: false,
  designersOfTheWeek: [],
  isPromotedPatternsLoading: false,
  promotedPatterns: [],
  isRecentDesignersLoading: false,
  recentDesigners: [],
  isPromotedYarnLoading: false,
  promotedYarn: [],
  isRecentYarnUploadsLoading: false,
  recentYarnUploads: [],
  isYarnItemsListLoading: false,
  yarnItemsList: [],
};

export const collectionSlice = createSlice({
  name: 'pages-content',
  initialState,
  reducers: {
    // Get pages content
    getPagesContentAction: (state) => {
      state.isLoading = true;
    },
    getPagesContentSuccessAction: (state, action: PayloadAction<PageContentCollectionItem[]>) => {
      state.isLoading = false;
      state.content = { ...action.payload };
    },
    getPagesContentErrorAction: (state) => {
      state.isLoading = false;
    },
    // Get recent pattern uploads
    getRecentPatternUploadsAction: (state) => {
      state.isRecentPatternUploadsLoading = true;
    },
    getRecentPatternUploadsSuccessAction: (state, action: PayloadAction<PatternWithStage[]>) => {
      state.isRecentPatternUploadsLoading = false;
      state.recentPatternUploads = action.payload;
    },
    getRecentPatternUploadsErrorAction: (state) => {
      state.isRecentPatternUploadsLoading = false;
    },
    // Get promoted patterns
    getPromotedPatternsAction: (state) => {
      state.isPromotedPatternsLoading = true;
    },
    getPromotedPatternsSuccessAction: (state, action: PayloadAction<PatternWithStage[]>) => {
      state.isPromotedPatternsLoading = false;
      state.promotedPatterns = action.payload;
    },
    getPromotedPatternsErrorAction: (state) => {
      state.isPromotedPatternsLoading = false;
    },
    // Get designers of the week
    getDesignersOfTheWeekAction: (state) => {
      state.isDesignersOfTheWeekLoading = true;
    },
    getDesignersOfTheWeekSuccessAction: (state, action: PayloadAction<User[]>) => {
      state.isDesignersOfTheWeekLoading = false;
      state.designersOfTheWeek = action.payload;
    },
    getDesignersOfTheWeekErrorAction: (state) => {
      state.isDesignersOfTheWeekLoading = false;
    },
    // Get recent designers
    getRecentDesignersAction: (state) => {
      state.isRecentDesignersLoading = true;
    },
    getRecentDesignersSuccessAction: (state, action: PayloadAction<User[]>) => {
      state.isRecentDesignersLoading = false;
      state.recentDesigners = action.payload;
    },
    getRecentDesignersErrorAction: (state) => {
      state.isRecentDesignersLoading = false;
    },
    // Get recent yarn uploads
    getRecentYarnUploadsAction: (state) => {
      state.isRecentYarnUploadsLoading = true;
    },
    getRecentYarnUploadsSuccessAction: (state, action: PayloadAction<YarnWithStage[]>) => {
      state.isRecentYarnUploadsLoading = false;
      state.recentYarnUploads = action.payload;
    },
    getRecentYarnUploadsErrorAction: (state) => {
      state.isRecentYarnUploadsLoading = false;
    },
    // Get yarn items list
    getYarnItemsListAction: (state) => {
      state.isYarnItemsListLoading = true;
    },
    getYarnItemsListSuccessAction: (state, action: PayloadAction<YarnWithStage[]>) => {
      state.isYarnItemsListLoading = false;
      state.yarnItemsList = action.payload;
    },
    getYarnItemsListErrorAction: (state) => {
      state.isYarnItemsListLoading = false;
    },
  },
});

export const {
  getPagesContentAction,
  getPagesContentSuccessAction,
  getPagesContentErrorAction,
  getRecentPatternUploadsAction,
  getRecentPatternUploadsSuccessAction,
  getRecentPatternUploadsErrorAction,
  getPromotedPatternsAction,
  getPromotedPatternsSuccessAction,
  getPromotedPatternsErrorAction,
  getDesignersOfTheWeekAction,
  getDesignersOfTheWeekErrorAction,
  getDesignersOfTheWeekSuccessAction,
  getRecentDesignersAction,
  getRecentDesignersErrorAction,
  getRecentDesignersSuccessAction,
  getRecentYarnUploadsAction,
  getRecentYarnUploadsSuccessAction,
  getRecentYarnUploadsErrorAction,
  getYarnItemsListAction,
  getYarnItemsListSuccessAction,
  getYarnItemsListErrorAction,
} = collectionSlice.actions;

export const pagesContentReducer = collectionSlice.reducer;
