import { PATH } from '@/common/constants/path';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button } from '@/components/button/button';
import {
  getYarnColorsAction,
  getYarnPricesAction,
  publishYarnAction,
  setYarnDataAction,
  setYarnMainColorAction,
} from '@/store/reducers/yarniverse.reducer';
import { getCurrentUserSelector } from '@/store/selectors/user.selector';
import {
  getYarniverseColorsSelector,
  getYarniverseCurrentStepSelector,
  getYarniverseDataSelector,
  getYarniverseGaugesSelector,
  getYarniverseNeedleSizesSelector,
  getYarniverseWashAndCareSelector,
  getYarnPricesSelector,
} from '@/store/selectors/yarniverse.selector';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { YarniverseStepsKeys } from '../steps';
import { Colors } from './components/colors';
import { Info } from './components/info';
import { WashAndCare } from './components/wash-and-care';
import {
  AccordionTitle,
  Block,
  Buttons,
  ButtonsWrapper,
  GridContainer,
  GridItem,
  Header,
  HeaderItem,
  PageContainer,
  PromotedButton,
  Row,
  TabsSection,
  TechnicalInformationTitle,
  TechnicalInformationValue,
  UpperCaseTitle,
} from './styles';
import { useTranslation } from 'react-i18next';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { getFibersByYarnIdAction } from '@/store/reducers/collection.reducer';
import React from 'react';
import { FiberCollectionItemWithStage } from '@/store/types/collection';
import { YarnColorCollectionItem } from '@/common/interfaces/collection.interface';
import { PhotoViewer } from './components/photo';

const FiberGrid = ({ fibers }: { fibers: Array<FiberCollectionItemWithStage> }) => {
  const { t } = useTranslation();

  const getNumGridRows = (items: Array<FiberCollectionItemWithStage>) => {
    return Math.ceil((items.length + 3) / 4) * 3; // Ensure rows are in multiples of 3
  };

  const getGridItemStyle = (index: number) => {
    const column = (Math.floor(index / 3) % 4) + 1;
    const row = (index % 3) + 1 + Math.floor(index / 12) * 3;
    return { column, row };
  };

  return (
    <GridContainer rows={getNumGridRows(fibers)}>
      <HeaderItem {...getGridItemStyle(0)}>{t('search.yarnFilter.fiber')}</HeaderItem>
      <HeaderItem {...getGridItemStyle(1)}>{t('search.yarnFilter.amount')}</HeaderItem>
      <HeaderItem {...getGridItemStyle(2)}>{t('Yarniverse.origin')}</HeaderItem>
      {fibers.map((item, index) => {
        if (!item) return null;
        return (
          <React.Fragment key={item?.id}>
            <GridItem {...getGridItemStyle((index + 1) * 3)}>{item?.type?.name}</GridItem>
            <GridItem {...getGridItemStyle((index + 1) * 3 + 1)}>{item?.pourcent}</GridItem>
            <GridItem {...getGridItemStyle((index + 1) * 3 + 2)}>{item?.country?.name}</GridItem>
          </React.Fragment>
        );
      })}
    </GridContainer>
  );
};

export const Advertisement = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [showTechnicalInformation, setShowTechnicalInformation] = useState(false);
  const yarn = useSelector(getYarniverseDataSelector);
  const { Units, Fiber } = useSelector(getCollectionDataSelector);
  const yarnGauges = useSelector(getYarniverseGaugesSelector);
  const yarnNeedleSizes = useSelector(getYarniverseNeedleSizesSelector);
  const user = useSelector(getCurrentUserSelector);
  const yarnColors = useSelector(getYarniverseColorsSelector);
  const prices = useSelector(getYarnPricesSelector);
  const currentStep = useSelector(getYarniverseCurrentStepSelector);
  const { t } = useTranslation();

  const [currentImage, setCurrentImage] = useState<YarnColorCollectionItem | null>(null);

  useEffect(() => {
    if (yarnColors.length > 0) {
      setCurrentImage(yarnColors.find((x) => x.id === yarn.main_color) || yarnColors[0]);
    }
  }, [yarnColors, yarn.main_color]);

  const setMainImage = (color: YarnColorCollectionItem) => {
    if (!yarn.id) return;
    const unset = yarn.main_color === color.id;

    dispatch(setYarnMainColorAction({ yarnId: yarn.id, colorId: unset ? null : color.id }));
  };

  useEffect(() => {
    if (yarn.prices?.length && yarn.id) {
      dispatch(getYarnPricesAction(yarn.id));
    }
  }, [yarn.prices]);

  useEffect(() => {
    if (currentStep === YarniverseStepsKeys.Advertisement && yarn.id) {
      dispatch(getYarnColorsAction(yarn.id));
      dispatch(getFibersByYarnIdAction(yarn.id));
    }
  }, [currentStep, yarn.id]);

  const handlePublish = () => {
    dispatch(
      publishYarnAction({
        yarnId: yarn.id as number,
        callback() {
          navigate(PATH.PROFILE);
        },
      }),
    );
  };

  const handleSelectColor = (id: number) => {
    const color = yarnColors.find((x) => x.id === id);
    if (color) {
      setCurrentImage(color);
    }
  };

  return (
    <>
      <TabsSection>
        <UpperCaseTitle>{yarn.name}</UpperCaseTitle>
      </TabsSection>

      <PageContainer>
        <Header>
          <PhotoViewer
            mainImageId={yarn.main_color}
            images={yarnColors}
            onMain={setMainImage}
            currentImage={currentImage}
            onImageChange={setCurrentImage}
          />
          <Colors
            name={yarn.name || ''}
            yarnColors={yarnColors}
            username={user?.display_name || user?.first_name || user?.username || ''}
            onColorSelect={handleSelectColor}
            selectedColorId={currentImage?.id}
          />
        </Header>

        <Row>
          <Info yarn={yarn} prices={prices || []} />
        </Row>

        <Row>
          <Block>
            <Typography>
              <AccordionTitle
                onClick={() => setShowTechnicalInformation(!showTechnicalInformation)}
              >
                {t('Yarniverse.technicalInformation')}
                <ArrowForwardIosIcon
                  style={{
                    transform: showTechnicalInformation ? 'rotate(90deg)' : 'none',
                    width: '30px',
                    height: '30px',
                  }}
                />
              </AccordionTitle>
              {showTechnicalInformation && (
                <>
                  <TechnicalInformationTitle>{t('common.weight')}</TechnicalInformationTitle>
                  <TechnicalInformationValue>
                    {yarn.amount_weight}
                    {Units?.find((x) => x.id === yarn.amount_weight_unit)?.name || '-'}
                  </TechnicalInformationValue>
                </>
              )}
            </Typography>
          </Block>
          <Block>
            {showTechnicalInformation && (
              <div
                style={{
                  marginTop: 'auto',
                }}
              >
                <TechnicalInformationTitle>
                  {t('search.yarnFilter.gauge')}
                </TechnicalInformationTitle>
                <TechnicalInformationValue>
                  {yarnGauges?.length > 0
                    ? [...yarnGauges]
                        .sort((a, b) => {
                          if (
                            a.sort === undefined ||
                            a.sort === null ||
                            b.sort === undefined ||
                            b.sort === null
                          )
                            return 0;
                          if (a.sort === b.sort) return 0;
                          if (a.sort > b.sort) return 1;
                          return -1;
                        })
                        .map((gauge) => gauge.thickness)
                        .join('/')
                    : '-'}
                </TechnicalInformationValue>
              </div>
            )}
          </Block>
        </Row>
        {showTechnicalInformation && (
          <>
            <Row>
              <Block>
                <TechnicalInformationTitle>{t('common.length')}</TechnicalInformationTitle>

                <TechnicalInformationValue>
                  {yarn.amount_lenght}
                  {Units?.find((x) => x.id === yarn.amount_lenght_unit)?.name || '-'}
                </TechnicalInformationValue>
              </Block>
              <Block>
                <TechnicalInformationTitle>
                  {t('search.yarnFilter.needleSize')}
                </TechnicalInformationTitle>
                <TechnicalInformationValue>
                  {yarnNeedleSizes?.length
                    ? yarnNeedleSizes.map((needleSize) => needleSize.size).join(', ')
                    : '-'}
                </TechnicalInformationValue>
              </Block>
            </Row>
            <Row>
              <Block>
                <FiberGrid fibers={Fiber} />
              </Block>
              <Block>
                <WashAndCare />
              </Block>
            </Row>
          </>
        )}
        <ButtonsWrapper>
          <Buttons>
            <PromotedButton>{t('Yarniverse.promoteYarn')}</PromotedButton>
            <Button colored onClick={handlePublish}>
              {t('Yarniverse.publish')}
            </Button>
          </Buttons>
        </ButtonsWrapper>
      </PageContainer>
    </>
  );
};
