import React, { useState, ChangeEvent } from 'react';
import styled from '@emotion/styled';
import AnimatedCheckbox from '@/components/checkbox/animated-checkbox';

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 25px;
    margin: 2px;
    height: 25px;
    gap: 0.25rem;

    p {
        margin: 0;
        font-size: 22px;
    }
`;

const StyledInput = styled.input`
    height: 25px;
    border: none;
    outline: none;
    font-size: 22px;
    font-family: 'ES Allianz', sans-serif;

    &::-webkit-calendar-picker-indicator {
        display: none;
    }
`;

type Model = {
    text: string;
    closed: boolean;
    opening: string;
    closing: string;
}

type TimeEditRowViewProps = {
    id: number;
    model: Model;
    edit: boolean;
    onChange?: (id: number, open: string, close: string, closed: boolean) => void;
}

// Time edit row
export const TimeEditRowView = ({ id, edit, model, onChange }: TimeEditRowViewProps) => {
    const [open, setOpen] = useState<string>(model.opening);
    const [close, setClose] = useState<string>(model.closing);
    const [closed, setClosed] = useState<boolean>(model.closed);

    const onEditOpen = (event: ChangeEvent<HTMLInputElement>) => {
        if (!edit) return;
        setOpen(event.target.value);

        if (onChange) {
            onChange(id, event.target.value, close, closed);
        }
    }

    const onEditClose = (event: ChangeEvent<HTMLInputElement>) => {
        if (!edit) return;
        setClose(event.target.value);
        
        if (onChange) {
            onChange(id, open, event.target.value, closed);
        }
    }

    const onClosedEdit = (event: ChangeEvent<HTMLInputElement>) => {
        setClosed(event.target.checked);
        
        if (onChange) {
            onChange(id, open, close, event.target.checked);
        }
    }

    return (
        <RowWrapper>
            <StyledInput type='time' value={open} onChange={onEditOpen} />
            <p>-</p>
            <StyledInput type='time' value={close} onChange={onEditClose} />
            {edit && <AnimatedCheckbox checked={closed} onChange={onClosedEdit} />}
        </RowWrapper>
    );
};