import { DirectusFields } from '@/common/constants/directus-fields';
import { FileFolders } from '@/common/constants/fileFolders';
import { ProductStatus } from '@/common/constants/product-status';
import {
    CreatePatternStepsRequest,
  PatternStepCreation,
  UploadPatternResponse,
} from '@/common/interfaces/api/upload-pattern.dto';
import {
  Collections,
  FileCollectionItem,
  LanguageCollectionItem,
  PatternImageCollectionItem,
} from '@/common/interfaces/collection.interface';
import { Pattern } from '@/common/interfaces/pattern.interface';
import { User } from '@/common/interfaces/user.interface';
import { sseConnector } from '@/services/sse';
import { getAvailableSizesForStep, getPatternDataSelector } from '@/store/selectors/pattern.selector';
import {
  normalizePattern,
  normalizePatternSteps,
  PatternStepWithCollection,
  PatternWithCollection,
} from '@/utils/normalizePattern';
import { OneItem, TransportResponse } from '@directus/sdk';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import {
  addPatternStepSize,
  createPatternStepsAction,
  createPatternStepsErrorAction,
  createPatternStepsSuccessAction,
  deletePatternStepAction,
  deletePatternStepErrorAction,
  deletePatternStepSuccessAction,
  deleteStepDiagramAction,
  deleteStepDiagramErrorAction,
  deleteStepDiagramSuccessAction,
  deletePatternImageAction,
  deletePatternImageErrorAction,
  deletePatternImageSuccessAction,
  getPatternDesignerAction,
  getPatternDesignerErrorAction,
  getPatternDesignerSuccessAction,
  getPatternForEditAction,
  getPatternForEditErrorAction,
  getPatternForEditSuccessAction,
  getPatternImagesAction,
  getPatternImagesErrorAction,
  getPatternImagesSuccessAction,
  getPatternLanguagesAction,
  getPatternLanguagesErrorAction,
  getPatternLanguagesSuccessAction,
  getPatternStepsAction,
  getPatternStepsErrorAction,
  getPatternStepsSuccessAction,
  getProcessingStatusAction,
  publishPatternAction,
  publishPatternErrorAction,
  publishPatternSuccessAction,
  unpublishPatternAction,
  unpublishPatternErrorAction,
  unpublishPatternSuccessAction,
  archivePatternAction,
  archivePatternErrorAction,
  archivePatternSuccessAction,
  removePatternStepSize,
  setAvailableSizeAction,
  setPatternDataAction,
  setPatternDataErrorAction,
  setPatternDataSuccessAction,
  translatePatternAction,
  translatePatternErrorAction,
  translatePatternSuccessAction,
  unsetAvailableSizeAction,
  updatePatternStepAction,
  updatePatternStepErrorAction,
  updatePatternStepsOrderAction,
  updatePatternStepsOrderErrorAction,
  updatePatternStepsOrderSuccessAction,
  updatePatternStepSuccessAction,
  updateStepDiagramAction,
  updateStepDiagramErrorAction,
  updateStepDiagramSuccessAction,
  uploadPatternAction,
  uploadPatternErrorAction,
  uploadPatternImageAction,
  uploadPatternImageErrorAction,
  uploadPatternStepDiagramAction,
  uploadPatternStepDiagramsErrorAction as uploadPatternStepDiagramErrorAction,
  uploadPatternStepDiagramsSuccessAction as uploadPatternStepDiagramSuccessAction,
  uploadPatternSuccessAction,
  addDesignElementAction,
  removeDesignElementAction,
  updateDesignElementSuccessAction,
  addGaugeAction,
  removeGaugeAction,
  updateGaugeSuccessAction,
  setCraftAction,
  craftSuccessAction,
  addProjectAction,
  projectSuccessAction,
  projectErrorAction,
  removeProjectAction,
  craftErrorAction,
  addPasserTilAction,
  updatePasserTilSuccessAction,
  updatePasserTilErrorAction,
  removePasserTilAction,
} from '../reducers/pattern.reducer';
import { displayErrorAction } from '../reducers/system.reducer';
import { GetPatternForEditPayload, PatternStepWithStage, ResourceWithStage } from '../types/pattern';
import { getAllRessourcesAction, getAllRessourcesErrorAction, getAllRessourcesSuccessAction } from '../reducers/resource-all.reducer';

function* setPatternDataRequest(api: ApiType, action: ReturnType<typeof setPatternDataAction>) {
  const pattern = action.payload;
  try {
    const response: OneItem<Pattern> = yield call(
      pattern.id ? api.updatePattern : api.createPattern,
      pattern,
    );

    if (response) {
      yield put(setPatternDataSuccessAction(response));
      if (action.payload.callback) {
        yield call(action.payload.callback)
      }
    } else {
      throw new Error();
    }
  } catch (error: any) {
    yield put(setPatternDataErrorAction(error));
    yield put(displayErrorAction(error?.message));
  }
}

function* setCraftRequest(api: ApiType, action: ReturnType<typeof setCraftAction>) {
  try {
    const pattern: Pattern = yield select(getPatternDataSelector);
    yield call(api.updatePattern, { id: pattern.id, craft: action.payload.craftId, });
    yield put(craftSuccessAction());
  } catch (error: any) {
    yield put(craftErrorAction(error));
    yield put(displayErrorAction(error?.message));
  }
}

function* updateProjectRequest(api: ApiType, action: ReturnType<typeof addProjectAction>) {
  try {
    const pattern: Pattern = yield select(getPatternDataSelector);
    yield call(api.updatePattern, { id: pattern.id, type_of_project: pattern?.type_of_project?.map((id) => ({ type_of_project_id: id })) });
    yield put(projectSuccessAction());
  } catch (error: any) {
    yield put(projectErrorAction(error));
    yield put(displayErrorAction(error?.message));
  }
}

function* updateDesignElementRequest(api: ApiType, action: ReturnType<typeof addDesignElementAction>) {
  try {
    const pattern: Pattern = yield select(getPatternDataSelector);
    yield call(api.updatePattern, { id: pattern.id, design_elements: pattern?.design_elements?.map((id) => ({ design_element_item_id: id })) });
    yield put(updateDesignElementSuccessAction());
  } catch (error: any) {
    yield put(setPatternDataErrorAction(error));
    yield put(displayErrorAction(error?.message));
  }
}

function* updatePasserTilRequest(api: ApiType, action: ReturnType<typeof addPasserTilAction>) {
  try {
    const pattern: Pattern = yield select(getPatternDataSelector);
    yield call(api.updatePattern, { id: pattern.id, passer_til: pattern?.passer_til?.map((id) => ({ passer_til_id: id })) });
    yield put(updatePasserTilSuccessAction());
  } catch (error: any) {
    yield put(updatePasserTilErrorAction(error));
    yield put(displayErrorAction(error?.message));
  }
}

function* updateGaugeRequest(api: ApiType, action: ReturnType<typeof addGaugeAction>) {
  try {
    const pattern: Pattern = yield select(getPatternDataSelector);
    yield call(api.updatePattern, { id: pattern.id, masketett: pattern?.masketett?.map((id) => ({ masketett_id: id })) });
    yield put(updateGaugeSuccessAction());
  } catch (error: any) {
    yield put(setPatternDataErrorAction(error));
    yield put(displayErrorAction(error?.message));
  }
}

function* uploadPatternRequest(api: ApiType, action: ReturnType<typeof uploadPatternAction>) {
  try {
    const payload = action.payload;

    if (!payload.pdfFile) {
      throw new Error('No pdf file');
    }

    yield call(api.uploadPattern, payload.patternId, payload.pdfFile);

    yield put(uploadPatternSuccessAction());
  } catch (error: any) {
    yield put(uploadPatternErrorAction(error));
    yield put(displayErrorAction(error?.message));
  }
}

function* getProcessingStatusRequest(api: ApiType, action: ReturnType<typeof getProcessingStatusAction>) {
    while (true) {
      const status: string =
        yield call(api.pollPdfProcessingStatus, action.payload.patternId);
      if (status !== "processing") {
        break;
      }
    }

    if (action.payload.callback) {
      yield call(action.payload.callback);
    }
}

function* translatePatternRequest(api: ApiType, action: ReturnType<typeof translatePatternAction>) {
  const patternSteps = action.payload;
  try {
    const response: PatternStepWithStage[] = yield call(api.translateSteps, patternSteps);

    if (response) {
      yield put(translatePatternSuccessAction(response));
    } else {
      throw new Error('pattern translate failed');
    }
  } catch (error: any) {
    yield put(translatePatternErrorAction(error));
    yield put(displayErrorAction(error?.message));
  }
}

function* getPatternLanguagesRequest(
  api: ApiType,
  action: ReturnType<typeof getPatternLanguagesAction>,
) {
  try {
    const { data }: { data: LanguageCollectionItem[] } = yield call(
      api.getCollection,
      Collections.Languages,
    );

    if (data) {
      yield put(getPatternLanguagesSuccessAction(data));
    } else {
      throw new Error('get languages failed');
    }
  } catch (error: any) {
    yield put(getPatternLanguagesErrorAction(error));
    yield put(displayErrorAction(error?.message));
  }
}
function* uploadPatternImageRequest(
  api: ApiType,
  action: ReturnType<typeof uploadPatternImageAction>,
) {
  try {
    const { pattern, image } = action.payload;
    const responseFile: FileCollectionItem = yield call(
      api.uploadFile,
      FileFolders.PatternImages,
      image,
    );
    if (responseFile) {
      const collectionResponse: PatternImageCollectionItem = yield call(
        api.createCollectionItem,
        Collections.PatternImage,
        {
          pattern: pattern.id,
          image: responseFile.id,
        },
      );
      yield put(
        setPatternDataAction({
          id: pattern.id,
          images: [...(pattern.images || []), collectionResponse.id],
        }),
      );
    } else {
      throw new Error();
    }
  } catch (error: any) {
    yield put(uploadPatternImageErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* getPatternImagesRequest(api: ApiType, action: ReturnType<typeof getPatternImagesAction>) {
  try {
    const { pattern } = action.payload;
    const response: TransportResponse<PatternImageCollectionItem[]> = pattern.images?.length ? yield call(
      api.getCollectionItemsByIds,
      Collections.PatternImage,
      pattern.images,
    ) : { data: [] };
    if (response.data) {
      yield put(getPatternImagesSuccessAction(response.data));
    } else {
      throw new Error();
    }
  } catch (error: any) {
    yield put(getPatternImagesErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* deletePatternImageRequest(
  api: ApiType,
  action: ReturnType<typeof deletePatternImageAction>,
) {
  const { imageId, callback } = action.payload;
  try {
    yield call(api.deletePatternImage, imageId);

    yield put(deletePatternImageSuccessAction());
    yield put(getPatternImagesAction({ pattern: { id: 1 } }));
    if (callback) {
      yield call(callback);
    }
  } catch (error: any) {
    yield put(deletePatternImageErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* uploadPatternStepDiagramRequest(
  api: ApiType,
  action: ReturnType<typeof uploadPatternStepDiagramAction>,
) {
  const { stepId, file, callback } = action.payload;
  try {
    const responseFile: FileCollectionItem = yield call(api.uploadFile, FileFolders.Diagrams, file);

    if (!responseFile) {
      throw new Error('Diagram upload failed');
    }

    const responseAddDiagramToStep: TransportResponse<any> = yield call(
      api.addDiagramsToStep,
      { step: stepId, image: responseFile.id, name: responseFile.title },
    );
    if (!responseAddDiagramToStep.data) {
      throw new Error('Add diagram to step failed');
    }

    yield put(uploadPatternStepDiagramSuccessAction());
    if (callback) {
      yield call(callback);
    }
  } catch (error: any) {
    yield put(uploadPatternStepDiagramErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* updateStepDiagramRequest(
  api: ApiType,
  action: ReturnType<typeof updateStepDiagramAction>,
) {
  const { diagram, callback } = action.payload;
  try {
    yield call(api.updateDiagram, diagram);

    yield put(updateStepDiagramSuccessAction());
    if (callback) {
      yield call(callback);
    }
  } catch (error: any) {
    yield put(updateStepDiagramErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* deleteStepDiagramRequest(
  api: ApiType,
  action: ReturnType<typeof deleteStepDiagramAction>,
) {
  const { diagramId, callback } = action.payload;
  try {
    yield call(api.deleteDiagram, diagramId);

    yield put(deleteStepDiagramSuccessAction());
    if (callback) {
      yield call(callback);
    }
  } catch (error: any) {
    yield put(deleteStepDiagramErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* createPatternStepsRequest(
  api: ApiType,
  action: ReturnType<typeof createPatternStepsAction>,
) {
  try {
    const { name, instructions, pattern, ressources, sort, relevant_sizes, callback } = action.payload;
    const step: CreatePatternStepsRequest = {
      name: name || '',
      sort: sort ?? 0,
      instructions: instructions || '',
      pattern,
      relevant_sizes: relevant_sizes?.map(id => ({ size_chart_id: id })) ?? [],
      ressources:
        ressources?.map(({ id }) => ({
          resources_id: id!,
        })) || [],
      diagrams: [],
    }

    const responseCreateSteps: TransportResponse<any> = yield call(api.createPatternStep, step);
    if (!responseCreateSteps.data) {
      throw new Error('Step creation failed');
    }

    const newStepId = responseCreateSteps.data.id;

    yield put(createPatternStepsSuccessAction());
    if (callback) {
      yield call(callback, newStepId);
    }
  } catch (error: any) {
    yield put(createPatternStepsErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* addPatternStepSizeRequest(
  api: ApiType,
  action: ReturnType<typeof addPatternStepSize>,
) {
  const stepId = action.payload.stepId;
  const state: number[] = yield select(getAvailableSizesForStep(stepId));
  yield call(api.updateStepSizes, stepId, state);
}

function* removePatternStepSizeRequest(
  api: ApiType,
  action: ReturnType<typeof removePatternStepSize>,
) {
  const stepId = action.payload.stepId;
  const state: number[] = yield select(getAvailableSizesForStep(stepId));
  yield call(api.updateStepSizes, stepId, state);
}

function* updatePatternStepRequest(
  api: ApiType,
  action: ReturnType<typeof updatePatternStepAction>,
) {
  const { stepId, data, callback } = action.payload;
  try {
    //create step
    const responseCreateSteps: TransportResponse<any> = yield call(api.updatePatternStep, {
      stepId,
      data,
    });
    if (!responseCreateSteps.data) {
      throw new Error('Step update failed');
    }

    yield put(updatePatternStepSuccessAction());
    if (callback) {
      yield call(callback);
    }
  } catch (error: any) {
    yield put(updatePatternStepErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* updatePatternStepsOrderRequest(
  api: ApiType,
  action: ReturnType<typeof updatePatternStepsOrderAction>,
) {
  const { steps, callback } = action.payload;
  try {
    //update steps order
    for (let i = 0; i < steps.length; i++) {
      const { id, ...data } = steps[i];
      yield call(api.updatePatternStep, {
        stepId: id,
        data,
      });
    }

    yield put(updatePatternStepsOrderSuccessAction());
    if (callback) {
      yield call(callback);
    }
  } catch (error: any) {
    yield put(updatePatternStepsOrderErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* deletePatternStepRequest(
  api: ApiType,
  action: ReturnType<typeof deletePatternStepAction>,
) {
  const { stepId, callback } = action.payload;
  try {
    const steps = Array.isArray(stepId) ? stepId : [stepId];

    for (let i = 0; i < steps.length; i++) {
      yield call(api.deletePatternStep, steps[i]);
    }

    yield put(deletePatternStepSuccessAction());
    if (callback) {
      yield call(callback);
    }
  } catch (error: any) {
    yield put(deletePatternStepErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* getPatternForEditRequest(
  api: ApiType,
  action: ReturnType<typeof getPatternForEditAction>,
) {
  try {
    const id = action.payload;

    const response: PatternWithCollection = yield call(
      api.getCollectionItemById,
      Collections.Patterns,
      id,
      {
        fields: DirectusFields.Pattern,
      },
    );
    if (!response) throw new Error('Pattern fetch failed');

    const responseWithoutCollections: Pattern = yield call(
      api.getCollectionItemById,
      Collections.Patterns,
      id,
    );

    if (!responseWithoutCollections) throw new Error('Pattern fetch failed');
    const normalizedPattern = normalizePattern(response);
    const result: GetPatternForEditPayload = {
      pattern: {
        ...responseWithoutCollections,
        type_of_project: normalizedPattern.type_of_project?.map(x => x?.id)?.filter(x => x !== null) ?? [],
        design_elements: normalizedPattern.design_elements?.map(x => x.id) ?? [],
        passer_til: normalizedPattern.passer_til?.map(x => x.id) ?? [],
        masketett: normalizedPattern.masketett?.map(x => x.id) ?? [],
        needles: normalizedPattern.needles,
        designer: response.user_created,
      },
      availableSizes: normalizedPattern.available_sizes?.filter((el) => !!el) || [],
    };

    yield put(getPatternForEditSuccessAction(result));
  } catch (error: any) {
    yield put(getPatternForEditErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* setAvailableSizeRequest(api: ApiType, action: ReturnType<typeof setAvailableSizeAction>) {
  try {
    const pattern: Pattern = yield select(getPatternDataSelector);
    const sizeChartId = action.payload;

    yield call(api.createCollectionItem, Collections.PatternsSizeChart, {
      patterns_id: pattern.id,
      size_chart_id: sizeChartId,
    });

  } catch (error) {
    console.log(error);
  }
}

function* unsetAvailableSizeRequest(api: ApiType, action: ReturnType<typeof unsetAvailableSizeAction>) {
  try {
    const pattern: Pattern = yield select(getPatternDataSelector);
    const sizeChartId = action.payload;

    const connectionId: { data: { id: number }[] } = yield call(api.getCollectionByFilter, Collections.PatternsSizeChart, {
      filter: {
        patterns_id: pattern.id,
        size_chart_id: sizeChartId,
      }
    });

    if (connectionId.data.length !== 1) return;

    yield call(api.removeCollectionItem, Collections.PatternsSizeChart, connectionId.data[0].id);

  } catch (error) {
    console.log(error);
  }
}

function* getPatternDesignerRequest(
  api: ApiType,
  action: ReturnType<typeof getPatternDesignerAction>,
) {
  try {
    const userId = action.payload;

    const response: User = yield call(api.getUserById, userId);

    if (!response) throw new Error('Pattern fetch failed');

    yield put(getPatternDesignerSuccessAction(response));
  } catch (error: any) {
    yield put(getPatternDesignerErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* getPatternStepsRequest(api: ApiType, action: ReturnType<typeof getPatternStepsAction>) {
  try {
    const id = action.payload;

    const patternResponse: {
      pattern_steps: PatternStepWithCollection[];
    } = yield call(api.getCollectionItemById, Collections.Patterns, id, {
      fields: DirectusFields.PatternSteps,
    });

    if (!patternResponse) throw new Error('Pattern fetch failed');

    const normalizedPatternSteps = normalizePatternSteps(
      patternResponse.pattern_steps.sort((a, b) => {
        return (a.sort ?? 0) - (b.sort ?? 0);
      }),
    );

    yield put(getPatternStepsSuccessAction(normalizedPatternSteps));
  } catch (error: any) {
    yield put(getPatternStepsErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* getRessourceByNameRequest(api: ApiType, action: ReturnType<typeof getAllRessourcesAction>) {
  try {
    const name = action.payload;
    const patternResponse: {data: ResourceWithStage[]} = yield call(api.getCollection, Collections.Resources, {
      fields: DirectusFields.ResourceAll,
      filter: {name: {'_eq': name}}
    });


    yield put(getAllRessourcesSuccessAction(patternResponse.data));
  } catch (error: any) {
    yield put(getAllRessourcesErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* publishPatternRequest(api: ApiType, action: ReturnType<typeof publishPatternAction>) {
  try {
    const { patternId, callback } = action.payload;

    yield call(api.updatePattern, {
      id: patternId,
      status: ProductStatus.Published,
    });

    yield put(publishPatternSuccessAction());

    yield call(callback, true);
  } catch (error: any) {
    yield put(publishPatternErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* unpublishPatternRequest(api: ApiType, action: ReturnType<typeof unpublishPatternAction>) {
  try {
    const { patternId, callback } = action.payload;

    yield call(api.updatePattern, {
      id: patternId,
      status: ProductStatus.Draft,
    });

    yield put(unpublishPatternSuccessAction());

    yield call(callback, true);
  } catch (error: any) {
    yield put(unpublishPatternErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* archivePatternRequest(api: ApiType, action: ReturnType<typeof archivePatternAction>) {
  try {
    const { patternId, callback } = action.payload;

    yield call(api.updatePattern, {
      id: patternId,
      status: ProductStatus.Archived,
    });

    yield put(archivePatternSuccessAction());

    yield call(callback, true);
  } catch (error: any) {
    yield put(archivePatternErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

export const patternSaga = function* (api: ApiType) {
  yield all([takeLatest(setPatternDataAction.type, setPatternDataRequest, api)]);
  yield all([takeLatest(setCraftAction.type, setCraftRequest, api)]);
  yield all([takeLatest(addProjectAction.type, updateProjectRequest, api)]);
  yield all([takeLatest(removeProjectAction.type, updateProjectRequest, api)]);
  yield all([takeLatest(addPasserTilAction.type, updatePasserTilRequest, api)]);
  yield all([takeLatest(removePasserTilAction.type, updatePasserTilRequest, api)]);
  yield all([takeLatest(addDesignElementAction.type, updateDesignElementRequest, api)]);
  yield all([takeLatest(removeDesignElementAction.type, updateDesignElementRequest, api)]);
  yield all([takeLatest(addGaugeAction.type, updateGaugeRequest, api)]);
  yield all([takeLatest(removeGaugeAction.type, updateGaugeRequest, api)]);
  yield all([takeLatest(getAllRessourcesAction.type, getRessourceByNameRequest, api)]);
  yield all([takeLatest(uploadPatternAction.type, uploadPatternRequest, api)]);
  yield all([takeLatest(getProcessingStatusAction.type, getProcessingStatusRequest, api)]);
  yield all([takeLatest(getPatternLanguagesAction.type, getPatternLanguagesRequest, api)]);
  yield all([takeLatest(translatePatternAction.type, translatePatternRequest, api)]);
  yield all([takeLatest(uploadPatternImageAction.type, uploadPatternImageRequest, api)]);
  yield all([takeLatest(getPatternImagesAction.type, getPatternImagesRequest, api)]);
  yield all([takeLatest(getPatternLanguagesAction.type, getPatternLanguagesRequest, api)]);
  yield all([takeLatest(translatePatternAction.type, translatePatternRequest, api)]);
  yield all([takeLatest(createPatternStepsAction.type, createPatternStepsRequest, api)]);
  yield all([takeLatest(getPatternForEditAction.type, getPatternForEditRequest, api)]);
  yield all([takeLatest(setAvailableSizeAction.type, setAvailableSizeRequest, api)]);
  yield all([takeLatest(unsetAvailableSizeAction.type, unsetAvailableSizeRequest, api)]);
  yield all([takeLatest(getPatternDesignerAction.type, getPatternDesignerRequest, api)]);
  yield all([takeLatest(getPatternStepsAction.type, getPatternStepsRequest, api)]);
  yield all([takeLatest(updatePatternStepAction.type, updatePatternStepRequest, api)]);
  yield all([takeLatest(addPatternStepSize.type, addPatternStepSizeRequest, api)]);
  yield all([takeLatest(removePatternStepSize.type, removePatternStepSizeRequest, api)]);
  yield all([takeLatest(deletePatternStepAction.type, deletePatternStepRequest, api)]);
  yield all([takeLatest(uploadPatternStepDiagramAction.type, uploadPatternStepDiagramRequest, api)]);
  yield all([takeLatest(publishPatternAction.type, publishPatternRequest, api)]);
  yield all([takeLatest(unpublishPatternAction.type, unpublishPatternRequest, api)]);
  yield all([takeLatest(deleteStepDiagramAction.type, deleteStepDiagramRequest, api)]);
  yield all([takeLatest(updateStepDiagramAction.type, updateStepDiagramRequest, api)]);
  yield all([takeLatest(updatePatternStepsOrderAction.type, updatePatternStepsOrderRequest, api)]);
  yield all([takeLatest(deletePatternImageAction.type, deletePatternImageRequest, api)]);
  yield all([takeLatest(archivePatternAction.type, archivePatternRequest, api)]);
};

