import styled from '@emotion/styled';

export const Content = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    width: '100%',
    flexGrow: 1
});

export const RightPanel = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '50px',
    borderLeft: '2px solid black',
});

export const Paragraph = styled('p')({
    marginTop: '0',
    marginBottom: '25px',
    fontSize: '22px',
});

export const Wrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
});

export const MapWrapper = styled('div')({
    flexGrow: 1,
    zIndex: 1,
    height: '100%',
    width: '750px',
});
