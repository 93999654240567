import React, { FC, ChangeEvent, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ResourceDataFields } from '@/store/types/resource';
import { setResourceData, setCurrentStep } from '@/store/reducers/resource.reducer';
import { getResourceDataSelector } from '@/store/selectors/resource.selector';
import { FilesToUpload, ResourceSteps } from '@/store/types/resource';
import { Button } from '@/components/button/button';
import { FileUpload } from './file-upload';
import { PageContainerEl, NameInput, TextArea } from './styles';

export interface ResourceTabProps {}

export const ResourceTab: FC<ResourceTabProps> = ({}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const resourceData = useSelector(getResourceDataSelector);
  const { name, description, files } = resourceData;

  const [_name, setName] = useState(name);
  const [_description, setDescription] = useState(description);

  const name_debouncer = useMemo(() => debounce(500), []);
  const description_debouncer = useMemo(() => debounce(500), []);

  const onInputChangeHandler = ({
    target,
  }: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    if (target.name === "name") {
      setName(target.value);
      name_debouncer(() => dispatch(
        setResourceData({
          ...resourceData,
          [target.name]: target.value,
        }),
      ));
    }
    if (target.name === "description") {
      setDescription(target.value)
      description_debouncer(() => dispatch(
        setResourceData({
          ...resourceData,
          [target.name]: target.value,
        }),
      ));
    }
  };

  const onFileChangeHandler = (files: FilesToUpload) => {
    dispatch(
      setResourceData({
        ...resourceData,
        [ResourceDataFields.FILES]: files,
      }),
    );
  };

  const onNextHandler = () => {
    dispatch(setCurrentStep(ResourceSteps.CATEGORIZATION));
  };

  return (
    <PageContainerEl>
      <NameInput
        type='text'
        placeholder={t('resourceUpload.typeNameResource')}
        name={ResourceDataFields.NAME}
        value={_name}
        onChange={onInputChangeHandler}
      />
      <FileUpload files={files} onFileChange={onFileChangeHandler} />
      <TextArea
        name={ResourceDataFields.DESCRIPTION}
        value={_description}
        onChange={onInputChangeHandler}
        placeholder={t('resourceUpload.typeDescription')}
        rows={10}
      />
      <Button onClick={onNextHandler} colored disabled={Object.values(files).length === 0}>
        {t('common.next')}
      </Button>
    </PageContainerEl>
  );
};

const debounce = (debounce_timer: number) => {
  let t: NodeJS.Timeout | null = null;
  return (f: () => void) => {
    if (t) clearTimeout(t);
    t = setTimeout(() => {
      f();
    }, debounce_timer);
  };
};
