import { getPatternDataSelector } from "@/store/selectors/pattern.selector";
import { styled } from "@mui/material/styles";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";

interface Props {
  descr: string;
  keywords?: string[];
  save: (id: number | undefined, keywords: string[]) => void;
}

export const Keywords = ({ descr, keywords: keywords_initial, save }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useSelector(getPatternDataSelector);
  const [keywordsTextfield, setKeywordsTextfield] = useState("");
  const isSet = useRef(false);

  useEffect(() => {
    if (isSet.current) return;
    if (keywords_initial) {
      setKeywordsTextfield(keywords_initial.join(", "));
      isSet.current = true;
    }
  }, [keywords_initial, isSet])

  const keywords = useMemo(() => keywordsTextfield
    .split(",")
    .map(x => x.trim())
    .filter(x => x.length > 0), [keywordsTextfield]);

  useEffect(() => {
    save(id, keywords);
  }, [save, id, keywords]);

  return <>
    <StyledTextarea
      placeholder={t('patternizerSteps.keywordsPlaceholder')}
      rows={20}
      value={keywordsTextfield}
      onChange={e => setKeywordsTextfield(e.target.value)}
    />
    <StyledText>{descr}</StyledText>
    <Flex>
      <KeywordsPillView keywords={keywords} />
    </Flex>
  </>;
}

export const KeywordsPillView = ({ keywords }: { keywords: string[] }) => {
  return <>
    {keywords.map((keyword, i) => <Pill key={keyword + i}><Text>{keyword}</Text></Pill>)}
  </>;
};

const Flex = styled('div')({
  display: 'flex',
  flexFlow: 'wrap',
});

const StyledTextarea = styled('textarea')(({ theme }) => ({
  ...theme.typography.h4,
  width: '100%',
  padding: '1rem',
  height: '200px',
}));

const StyledText = styled('p')(({ theme }) => ({
  ...theme.typography.body1,
  marginBottom: '3rem',
}));

const Pill = styled('div')({
  paddingRight: '25px',
  paddingLeft: '25px',
  minWidth: '50px',
  // maxWidth: '400px',
  height: '50px',
  borderRadius: '50px',
  border: '1px solid black',
  marginRight: '20px',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const Text = styled('span')(({ theme }) => ({
  ...theme.typography.body2,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
}));
