import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';
import { setPatternDataAction } from '@/store/reducers/pattern.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { getPatternDataSelector } from '@/store/selectors/pattern.selector';
import { InputButtonSelect } from '@/components/input-button-select';

const CheckboxContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
});

const Container = styled('div')({});

export const Units = () => {
  const dispatch = useDispatch();

  const { UnitMeasurement } = useSelector(getCollectionDataSelector);
  const pattern = useSelector(getPatternDataSelector);

  const onChangeUnits = (id: number) => {
    if (id === pattern.unit_measurement) return;
    dispatch(
      setPatternDataAction({
        id: pattern.id,
        unit_measurement: id,
      }),
    );
  };

  const UnitInputButtonSelect = styled(InputButtonSelect)({
    width: 'fit-content',
    paddingRight: '4rem',
    margin: '0 1rem 0 0',
  });

  return (
    <Container>
      <CheckboxContainer>
        {UnitMeasurement
          .slice()
          .sort((a, b) => a.id - b.id)
          .map((el) => (
          <UnitInputButtonSelect
            key={el.id}
            label={el.value}
            value={el}
            checked={pattern.unit_measurement === el.id}
            onChange={() => onChangeUnits(el.id)}
          />
        ))}
      </CheckboxContainer>
    </Container>
  );
};
