import { useTranslation } from 'react-i18next';
import { StoreInfoView } from '../storeInfo';
import { OpeningHoursView } from '../opening-hours';
import {
    exampleOpeningHours,
    exampleStoreInfo,
    exampleDescription
} from '../../mock-data';

import { 
    Column,
    SplitView,
    SplitViewItem,
    DescriptionLabel,
    DescriptionWrapper
} from '../styled-components';

type RightPaneProps = {
    showEdit?: boolean;
};

const RightPane = ({ showEdit }: RightPaneProps) => {
    const { t } = useTranslation();

    return (
        <Column>
            <SplitView>
                <SplitViewItem>
                    <StoreInfoView {...exampleStoreInfo} />
                </SplitViewItem>
                <SplitViewItem>
                    <OpeningHoursView weekdays={exampleOpeningHours} showEdit={showEdit} />
                </SplitViewItem>
            </SplitView>
            <hr />
            <DescriptionLabel>{t('distributor.selectDescriptionLanguage')}</DescriptionLabel>
            <DescriptionWrapper>
                <p>{exampleDescription}</p>
            </DescriptionWrapper>
        </Column>
    );
};

export default RightPane;