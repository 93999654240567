import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: '#fff',
  zIndex: 1000,
  
  [theme.breakpoints.down('laptop')]: {
    height: '100dvh',
    minHeight: '-webkit-fill-available',
  }
}));

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
  position: 'relative',

  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
  }
}));

export const MainContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  overflow: 'hidden',

  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
    height: '100%',
  }
}));

export const MobileNavigation = styled('div')({
  display: 'flex',
  position: 'relative',
  height: '3.5rem',
  backgroundColor: '#fff',
  borderTop: '1px solid #000',
  justifyContent: 'space-around',
  alignItems: 'center',
  zIndex: 1001,
  padding: '0 8px',
});

export const MobileNavItem = styled('button')<{ active?: boolean }>(({ active }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '8px',
  border: 'none',
  background: active ? '#F6F4ED' : 'none',
  cursor: 'pointer',
  fontSize: '0.75rem',
  color: '#000',
  fontWeight: active ? 500 : 400,
  position: 'relative',
  flex: 1,
  margin: '0 4px',
  minHeight: '3.5rem',
  overflow: 'hidden',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '1px',
    background: '#000',
    opacity: active ? 1 : 0,
  },

  '& svg': {
    fontSize: '1.5rem',
    marginBottom: '2px',
  },

  '& span': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  }
}));

export const CategoryInfoRow = styled('div')({
  borderTop: '1px solid #000',
  padding: '1rem',
});

// Add mobile transition styles
export const MobileTransition = styled('div')<{ direction: 'left' | 'right' }>(({ direction }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  transition: 'transform 0.3s ease-in-out',
  transform: `translateX(${direction === 'left' ? '-100%' : '100%'})`,

  '&.active': {
    transform: 'translateX(0)',
  }
}));

export const MobileView = styled('div')<{ visible: boolean }>(({ visible }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  visibility: visible ? 'visible' : 'hidden',
  opacity: visible ? 1 : 0,
  // Ensure hidden views don't interfere with touch events
  pointerEvents: visible ? 'auto' : 'none',
}));
