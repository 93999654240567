import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { H3 } from '../heading';
import { Button } from '@/components/button/button';

const StoreInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
    height: 100%;
`;

const AddressBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2.5rem;
`;

const ContactBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Text = styled.span`
    margin: 2px;
    font-size: 22px;
`;

const ButtonStyled = styled(Button)`
    margin-top: 3rem;
`;

type ButtonView = {
    show: boolean;
    disabled: boolean;
    onClick: () => void;
};

interface StoreInfoViewProps {
    country: string;
    street: string;
    postalCode: string;
    city: string;
    phone: string;
    email: string;
    followButton?: ButtonView;
}

export const StoreInfoView = ({
    country,
    street,
    postalCode,
    city,
    phone,
    email,
    followButton,
}: StoreInfoViewProps) => {
    const { t } = useTranslation();

    return (
        <StoreInfoWrapper>
            <H3>{t('distributor.physicalStore')}</H3>
            <AddressBlock>
                <Text>{country}</Text>
                <Text>{street}</Text>
                <Text>{postalCode + ' ' + city}</Text>
            </AddressBlock>
            <ContactBlock>
                <Text>
                    {t('distributor.phone')}: <a href={`tel:${phone}`}>{phone}</a>
                </Text>
                <Text>
                    {t('distributor.email')}: <a href={`mailto:${email}`}>{email}</a>
                </Text>
            </ContactBlock>

            {followButton && followButton.show && (
                <ButtonStyled disabled={followButton.disabled}>
                    {t('common.follow')}
                </ButtonStyled>
            )}
        </StoreInfoWrapper>
    );
}
