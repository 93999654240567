import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/button/button';

const ButtonRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;
    padding: 0 1rem;
    margin: 2rem 0;
`;

export const ButtonRow = () => {
    const { t } = useTranslation();

    return (
        <ButtonRowWrapper>
            <Button colored>{t('distributor.dashboard')}</Button>
            <Button>{t('distributor.uploadPattern')}</Button>
            <Button>{t('distributor.editAssortment')}</Button>
            <Button>{t('distributor.postInspiration')}</Button>
        </ButtonRowWrapper>
    );
}
