import React, { useMemo } from 'react';
import { Container, Item, PatternName, LogoImage, ZoomContainer, ExitPreviewButton, MobileControls, TopRow } from './styles';
import { ByUser } from '@/components/by-user';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@/common/constants/path';
import Logo from '@/assets/logo.svg';
import { ZoomSlider } from '@/components/zoom-slider';
import { useTranslation } from 'react-i18next';
import { SizeSelect } from '@/components/size-select';

interface KnitmodeHeaderProps {
  username: string;
  userId: string;
  language: string;
  patternSize: string;
  zoom: number;
  onZoomChange: (zoom: number) => void;
  patternName: string;
  availableSizes: Array<{id: number, size_name: string}>;
  selectedSizeId: number | null;
  onSizeChange: (sizeId: number) => void;
  previewMode?: boolean;
  onExitPreview?: () => void;
  isMobile?: boolean;
  onExit?: () => void;
}

export const KnitmodeHeader: React.FC<KnitmodeHeaderProps> = ({
  username,
  userId,
  language,
  patternSize,
  zoom,
  onZoomChange,
  patternName,
  availableSizes,
  selectedSizeId,
  onSizeChange,
  previewMode,
  onExitPreview,
  isMobile,
  onExit
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const shouldHighlightSize = !selectedSizeId && availableSizes.length > 0;

  // Sort sizes by id
  const sortedSizes = useMemo(() => 
    [...availableSizes].sort((a, b) => a.id - b.id),
    [availableSizes]
  );

  return (
    <Container>
      {!isMobile ? (
        // Desktop layout
        <>
          <LogoImage src={Logo} alt='Knitry' onClick={() => navigate(PATH.HOME)} />
          <PatternName>{patternName}</PatternName>
          {!previewMode && <ByUser name={username} userId={userId} />}
          <Item>
            {t('knitmode.patternSize')}: 
            <SizeSelect
              availableSizes={sortedSizes}
              selectedSizeId={selectedSizeId}
              onSizeChange={onSizeChange}
              highlight={shouldHighlightSize}
            />
          </Item>
          <ZoomContainer>
            <Item>{t('knitmode.zoom')}:</Item>
            <ZoomSlider value={zoom} onChange={onZoomChange} />
          </ZoomContainer>
          {previewMode ? (
            <ExitPreviewButton 
              onClick={onExitPreview}
              colored
              remove
            >
              {t('knitmode.exitPreview')}
            </ExitPreviewButton>
          ) : (
            <ExitPreviewButton 
              onClick={onExit}
              colored
            >
              {t('knitmode.exit')}
            </ExitPreviewButton>
          )}
        </>
      ) : (
        // Mobile layout
        <>
          <TopRow>
            <LogoImage src={Logo} alt='Knitry' onClick={() => navigate(PATH.HOME)} mobile />
            {previewMode ? (
              <ExitPreviewButton 
                onClick={onExitPreview}
                colored
                remove
                mobile
              >
                {t('knitmode.exitPreview')}
              </ExitPreviewButton>
            ) : (
              <ExitPreviewButton 
                onClick={onExit}
                colored
                mobile
              >
                {t('knitmode.exit')}
              </ExitPreviewButton>
            )}
          </TopRow>
          <PatternName mobile>{patternName}</PatternName>
          <MobileControls>
            <SizeSelect
              availableSizes={sortedSizes}
              selectedSizeId={selectedSizeId}
              onSizeChange={onSizeChange}
              highlight={shouldHighlightSize}
              compact
            />
            <ZoomSlider value={zoom} onChange={onZoomChange} compact />
          </MobileControls>
        </>
      )}
    </Container>
  );
};
