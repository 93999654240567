import styled from '@emotion/styled';

export const DescriptionLabel = styled.p`
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    color: rgba(0, 0, 0, 0.5);
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;

export const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1rem;
`;

export const EditButton = styled.div`
    position: absolute;
    left: 1rem;
    top: 1rem;
`;

export const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 800px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

export const SplitView = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
`;

export const SplitViewItem = styled.div`
    flex: 1;
`;