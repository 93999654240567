import React from 'react';
import { Container, Title, TitleContainer, InstructionsContainer, NavigationBar, NavigationGroup, StepNavigationText, SwipeHint, SwipeArrow, DiagramViewerContainer } from './styles';
import { DiagramViewer } from '../diagram-viewer';
import { PatternStepWithStage, ResourceWithStage } from '@/store/types/pattern';
import { Step } from '../step';
import { ResourceTooltip } from '@/components/resource-tooltip/resource-tooltip';
import { useTranslation } from 'react-i18next';
import { useSwipeGesture } from '@/hooks/useSwipeGesture';

interface StepInstructionsProps {
  currentStep: PatternStepWithStage;
  fontSize: number;
  selectedSizeId: number | null;
  onPrevStep: () => void;
  onNextStep: () => void;
  isFirstStep: boolean;
  isLastStep: boolean;
  onOpenResource: (resourceId: number, resourceType: 'resource' | 'diagram') => void;
  patternSizeModel: any;
  previewMode?: boolean;
  onExitPreview?: () => void;
  isMobile?: boolean;
  activeDiagram?: ResourceWithStage | null;
}

export const StepInstructions: React.FC<StepInstructionsProps> = ({ 
  currentStep, 
  fontSize,
  selectedSizeId,
  onPrevStep,
  onNextStep,
  isFirstStep,
  isLastStep,
  onOpenResource,
  patternSizeModel,
  previewMode,
  onExitPreview,
  isMobile,
  activeDiagram
}) => {
  const { t } = useTranslation();
  
  const swipeHandlers = useSwipeGesture({
    onSwipeLeft: !isLastStep ? onNextStep : undefined,
    onSwipeRight: !isFirstStep ? onPrevStep : undefined,
  });
  
  return (
    <Container>
      <TitleContainer>
        <Title isMobile={isMobile}>{currentStep.name}</Title>
      </TitleContainer>
      <InstructionsContainer 
        fontSize={fontSize}
        {...(isMobile ? swipeHandlers : {})}
      >
        <ResourceTooltip>
          <Step 
            instructions={currentStep?.instructions || ''}
            fontSize={fontSize}
            stepId={currentStep.id}
            selectedSizeId={selectedSizeId}
            onOpenResource={onOpenResource}
            patternSizeModel={patternSizeModel}
          />
        </ResourceTooltip>
      </InstructionsContainer>

      {activeDiagram && (
        <DiagramViewerContainer>
          <DiagramViewer
            imageUrl={activeDiagram.files?.[0]?.file || ''}
            name={activeDiagram.name || ''}
          />
        </DiagramViewerContainer>
      )}

      <NavigationBar isMobile={isMobile}>
        <NavigationGroup>
          {!isFirstStep && (
            <StepNavigationText onClick={onPrevStep}>
              {t('knitmode.previousStep')}
            </StepNavigationText>
          )}
        </NavigationGroup>
        <NavigationGroup>
          {!isLastStep && (
            <StepNavigationText onClick={onNextStep}>
              {t('knitmode.nextStep')}
            </StepNavigationText>
          )}
        </NavigationGroup>
      </NavigationBar>
    </Container>
  );
};
