import { Drawer } from '@mui/material';
import { styled } from '@mui/material';
import { FC } from 'react';
import { ResourceView } from '@/components/resource-view';
import { CloseBtn } from '@/components/close-btn';

interface ResourceViewDrawerProps {
  resourceId: number | null;
  resourceType: 'resource' | 'diagram';
  onClose: () => void;
  showInPanel?: boolean;
}

const DrawerContainer = styled('div')<{ showInPanel?: boolean }>(({ showInPanel, theme }) => ({
  ...(showInPanel ? {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#fff',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
  } : {
    width: '70vw',
    [theme.breakpoints.down('laptop')]: {
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
}));

const ResourceContainer = styled('div')<{ showInPanel?: boolean }>(({ showInPanel, theme }) => ({
  width: '100%',
  height: '100%',
  ...(showInPanel && {
    overflowY: 'auto',
    overflowX: 'hidden',
  }),
  [theme.breakpoints.down('laptop')]: {
    overflowY: 'auto',
    overflowX: 'hidden',
    '-webkit-overflow-scrolling': 'touch', // Smooth scrolling on iOS
    flex: 1,
  }
}));

const StyledCloseBtn = styled(CloseBtn)<{ showInPanel?: boolean }>(({ showInPanel, theme }) => ({
  ...(showInPanel ? {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    zIndex: 1001,
  } : {
    position: 'fixed',
    top: '1rem',
    right: '1rem',
  }),
  [theme.breakpoints.down('laptop')]: {
    position: 'absolute',
    top: '0.75rem',
    right: '0.75rem',
    // Make touch target larger on mobile
    padding: '0.75rem',
    '& svg': {
      width: '24px',
      height: '24px',
    },
  }
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('laptop')]: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    borderBottom: '1px solid #000',
    minHeight: '3.5rem',
  }
}));

const DrawerTitle = styled('h2')(({ theme }) => ({
  margin: 0,
  fontSize: '1.25rem',
  fontWeight: 600,
  flex: 1,
  textAlign: 'center',
}));

export const ResourceViewDrawer: FC<ResourceViewDrawerProps> = (props) => {
  const { resourceId, resourceType, onClose, showInPanel } = props;

  if (!resourceId) return null;

  const content = (
    <>
      {/* Add header for mobile view */}
      <DrawerHeader>
        <DrawerTitle>
          {resourceType === 'diagram' ? 'Chart/Schematic' : 'Resource'}
        </DrawerTitle>
      </DrawerHeader>
      <ResourceContainer showInPanel={showInPanel}>
        <ResourceView resourceId={resourceId} resourceType={resourceType} />
      </ResourceContainer>
      <StyledCloseBtn onClick={onClose} showInPanel={showInPanel} />
    </>
  );

  if (showInPanel) {
    return <DrawerContainer showInPanel={showInPanel}>{content}</DrawerContainer>;
  }

  return (
    <Drawer 
      anchor="right" 
      onClose={onClose} 
      open={!!resourceId}
      SlideProps={{
        // Add better touch handling
        style: {
          touchAction: 'none',
        }
      }}
    >
      <DrawerContainer showInPanel={showInPanel}>{content}</DrawerContainer>
    </Drawer>
  );
};
