import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { YarnWithStage } from '../types/yarniverse';
import { logoutSuccessAction } from './authorization.reducer';

export interface YarnViewState {
  isLoading: boolean;
  data: YarnWithStage;
}

const initialState: YarnViewState = {
  isLoading: false,
  data: {
    id: undefined,
    sort: null,
    status: undefined,
    user_created: undefined,
    user_updated: undefined,
    name: undefined,
    description: null,
    yarn_manufacturer: undefined,
    amount_weight: undefined,
    amount_lenght: undefined,
    amount_weight_unit: { name: null },
    amount_lenght_unit: { name: null },
    colors: [],
    prices: [],
    fiber: [],
    gauge: [],
    needle_size: [],
    country_of_origin: undefined,
    available_country: [],
    wash_and_care: [],
    isPromoted: null,
    date_created: null,
    date_updated: null,
    texture: [],
    thread_construction: [],
  },
};

export const yarnViewSlice = createSlice({
  name: 'yarn-view',
  initialState,
  reducers: {
    // Get yarn by id
    getYarnByIdAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getYarnByIdSuccessAction: (state, action: PayloadAction<YarnWithStage>) => {
      state.isLoading = false;
      state.data = action.payload;
    },

    // Clear
    clearYarnViewDataAction: () => initialState,
    // Error action
    yarnViewErrorAction: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutSuccessAction, () => initialState);
  },
});

export const {
  getYarnByIdAction,
  getYarnByIdSuccessAction,
  yarnViewErrorAction,
  clearYarnViewDataAction,
} = yarnViewSlice.actions;

export const yarnViewReducer = yarnViewSlice.reducer;
