import { PaymentStatus } from '../constants/payment-status';

/* eslint-disable @typescript-eslint/ban-ts-comment */
export interface CollectionItem {
  id: number;
  date_created?: string | null;
  date_updated?: string | null;
  sort?: null;
  status?: 'published' | 'draft' | 'archived';
  user_created?: string | null;
  user_updated?: string | null;
}

export interface ThreadConstructionCollectionItem extends CollectionItem {
  name: string;
}
export interface TextureCollectionItem extends CollectionItem {
  name: string;
}

export interface CraftCollectionItem extends CollectionItem {
  name: string;
}

export interface ProjectCollectionItem extends CollectionItem {
  name: string;
  top_level?: number[];
}

export interface LanguageCollectionItem extends CollectionItem {
  name: string;
  code: string;
}

export interface SuitableCollectionItem extends CollectionItem {
  name: string;
}

export interface GaugeCollectionItem extends CollectionItem {
  thickness: string;
}

export interface YarnGaugeCollectionItem extends CollectionItem {
  Yarn_id: number;
  masketett_id: GaugeCollectionItem;
}

export interface YarnNeedleSizeCollectionItem extends CollectionItem {
  Yarn_id: number;
  needle_size_id: NeedleSizeCollectionItem;
}

export interface YarnWashAndCareCollectionItem extends CollectionItem {
  Yarn_id: number;
  wash_and_care_id: WashAndCareCollectionItem;
}

export interface NeedleSizeCollectionItem extends CollectionItem {
  needle_size_categories: number;
  size: string;
  size_type: string | null;
  eu_size_in_mm: number;
  us_size: string;
}

export interface NeedleTypeCollectionItem extends CollectionItem {
  name: string;
  description: string;
}

export interface NeedleLengthCollectionItem extends CollectionItem {
  value: string;
  in_cm: string;
  in_inch: string;
}

export interface NeedleMaterialsCollectionItem extends CollectionItem {
  name: string;
  description: string;
}

export interface AvailableSizeCollectionItem extends CollectionItem {
  size_name: string;
  name: string;
  id: number;
}

export interface UnitMeasurementCollectionItem extends CollectionItem {
  value: string;
}

export interface NeedleCollectionItem extends CollectionItem {
  name?: string;
  needle_type?: number | null;
  needle_size?: number | null;
  cable_length?: number | null;
  pattern?: number | null;
  quantity?: number; //TODO: tmp solution
}

export interface NeedlesCollectionItem extends CollectionItem {
  name?: string;
  needle_type?: number | null;
  needle_size?: number | null;
  cable_length?: number | null;
  material?: number | null;
  price?: number;
  images: string[];
  main_image?: string | number;
}

export interface MeasurementCollectionItem extends CollectionItem {
  measurement_values: number[];
  name: string;
  pattern: number;
}

export interface MeasurementAvailableSizesCollectionItem extends CollectionItem {
  measurement?: number;
  size_chart: number;
  value: string;
}

export interface YarnCollectionItem extends CollectionItem {
  name: string;
  description: string;
  yarn_manufacturer: number;
  amount_weight: number; //?
  amount_lenght: number; //?
  amount_weight_unit: number; //?
  amount_lenght_unit: number; //?
  colors: number[];
  prices: number[];
}

export interface YarnPriceCollectionItem extends CollectionItem {
  price: number;
  currency: CurrencyCollectionItem;
}

export interface CurrencyCollectionItem extends CollectionItem {
  name: string;
  code: string;
  flag: null;
  International_Code: string;
  tax: string;
}

export interface YarnAlternativeCollectionItem extends CollectionItem {
  alternativ_items: number[];
  name: string;
  pattern: number;
  color: number;
}

export interface YarnColorCollectionItem extends CollectionItem {
  name?: string | null;
  image?: string | null;
  ean?: string | null;
  eanImage?: string | null;
}

export interface YarnManufacturer extends CollectionItem {
  name: string;
  supplierOfTheWeek: boolean | null;
  isAvailableOnSite: boolean | null;
  country: number | null;
  estimated_delivery_day_to: number | null;
  estimated_delivery_day_from: number | null;
  city: string | null;
  zip_code: string | null;
  address: string | null;
  website_address: string | null;
  contact_address: string | null;
  contact_phone_number: string | null;
  contact_person: string | null;
  status: 'published' | 'draft' | 'archived';
  main_image: string | null;
  available_country: number[];
  shipment_methods: Array<number>;
}

export interface AlternativeItemCollectionItem extends CollectionItem {
  alternative: number;
  yarn: number;
  yarn_measurement: number[];
  color: number | null;
  quantity_with_size: number[];
}

export interface QuantityWithSizeCollectionItem extends CollectionItem {
  quantity: number;
  size_chart: AvailableSizeCollectionItem;
  alternative_item: number;
}

export interface YarnMeasurementCollectionItem extends CollectionItem {
  alternative_items: number;
  size: number;
  value: string;
}

export interface PatternImageCollectionItem extends CollectionItem {
  image: string;
  pattern: number;
}

export interface UserImageCollectionItem extends CollectionItem {
  default_image: string;
  user: number;
}

export interface DiagramCollectionItem extends CollectionItem {
  image: string;
  step: number;
  name: string;
}

export interface FileCollectionItem {
  title: string;
  id: string;
}

export interface PatternStepCollectionItem extends CollectionItem {
  name: string;
  instructions: string;
  resources: number[];
  diagrams: number[];
  size: number;
}

export interface TPACollectionItem extends CollectionItem {
  title: string;
  content: string;
}

export interface PaymentWallCollectionItem extends CollectionItem {
  content: string;
}

export interface DiagramCollectionItem {
  id: number;
  image: string;
  pattern: number;
  name: string;
}

export interface ResourceCollectionItem extends CollectionItem {
  id: number;
  name: string;
  description: string;
  files: any[];
  [Collections.Craft]: number;
  [Collections.ResourceType]: any[];
  [Collections.ResourceContentType]: any[];
}

export interface ResourceContentTypeCollectionItem extends CollectionItem {
  id: number;
  name: string;
}

export interface ResourceTypeCollectionItem extends CollectionItem {
  resource_type_id: {
    id: number;
    name: string;
  };
}

export interface CountryCollectionItem extends CollectionItem {
  name: string;
  country_code: string;
}
export interface OrderCollectionItem extends CollectionItem {
  currency: number;
  delivery_method: number;
  id: number;
  mva_amount: null; //TODO: ???
  orderby: string;
  pattern_ref: number;
  payment_status: PaymentStatus;
  productId: null; //TODO: ???
  product_categories: number;
  product_image: null; //??
  product_name: null; // >
  quantity: number;
  sort: null;
  total_paid_amount: number;
  unit_amount: null; // ?
}

export interface FiberTypeCollectionItem extends CollectionItem {
  name: string;
}

export interface FiberCollectionItem extends CollectionItem {
  type: number;
  pourcent: number;
  country: number;
}

export interface DesignElementCollectionItem extends CollectionItem {
  name: string;
}

export type UnionCollectionItem =
  | CraftCollectionItem
  | ProjectCollectionItem
  | LanguageCollectionItem
  | SuitableCollectionItem
  | GaugeCollectionItem
  | NeedleSizeCollectionItem
  | NeedleTypeCollectionItem
  | AvailableSizeCollectionItem
  | UnitMeasurementCollectionItem
  | NeedleCollectionItem
  | MeasurementCollectionItem
  | PatternImageCollectionItem
  | UserImageCollectionItem
  | TextureCollectionItem
  | ThreadConstructionCollectionItem
  | DesignElementCollectionItem;

export enum Collections {
  Craft = 'Craft',
  Projects = 'Projects',
  Languages = 'Languages',
  Suitable = 'Suitable',
  Gauge = 'Gauge',
  NeedleType = 'NeedleType',
  NeedleSize = 'NeedleSize',
  NeedleLength = 'NeedleLength',
  Needle = 'Needle',
  Needles = 'Needles',
  NeedleSizeCategories = 'NeedleSizeCategories',
  NeedleMaterials = 'NeedleMaterials',
  NeedleImages = 'NeedleImages',
  AvailableSize = 'AvailableSize',
  UnitMeasurement = 'UnitMeasurement',
  Measurements = 'Measurements',
  MeasurementAvailableSizes = 'MeasurementAvailableSizes',
  Yarn = 'Yarn',
  YarnColorAlternative = 'YarnColorAlternative',
  YarnColor = 'YarnColor',
  YarnAlternatives = 'YarnAlternatives',
  YarnMeasurement = 'YarnMeasurement',
  AlternativeItem = 'AlternativeItem',
  PatternImage = 'PatternImage',
  QuantityWithSize = 'QuantityWithSize',
  Patterns = 'Patterns',
  Payments = 'Payments',
  About = 'About',
  PrivacyPolicy = 'PrivacyPolicy',
  TermCondition = 'TermCondition',
  PaymentWallPatternDesigner = 'PaymentWallPatternDesigner',
  PaymentWallYarnSupplier = 'PaymentWallYarnSupplier',
  PaymentWallLocalYarnStore = 'PaymentWallLocalYarnStore',
  ResourceType = 'ResourceType',
  ResourceContentType = 'ResourceContentType',
  ResourcesImages = 'ResourcesImages',
  Resources = 'Resources',
  PatternDiagrams = 'PatternDiagrams',
  Countries = 'Countries',
  Orders = 'Orders',
  Diagrams = 'Diagrams',
  Fiber = 'Fiber',
  FiberType = 'FiberType',
  YarnManufacturer = 'YarnManufacturer',
  YarnGauge = 'YarnGauge',
  YarnNeedleSize = 'YarnNeedleSize',
  YarnWashAndCare = 'YarnWashAndCare',
  WashAndCare = 'WashAndCare',
  Units = 'Units',
  TypeWashAndCare = 'TypeWashAndCare',
  YarnPrice = 'YarnPrice',
  ShipmentMethods = 'ShipmentMethods',
  WebPages = 'WebPages',
  Users = 'Users',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Designers = 'Users',
  Texture = 'Texture',
  ThreadConstruction = 'ThreadConstruction',
  PatternsSizeChart = 'PatternsSizeChart',
  Product = 'Product',
  Stores = 'Stores',
}

export interface ResourceTypeCollectionItem extends CollectionItem {
  id: number;
  name: string;
}

export interface ResourceContentTypeCollectionItem extends CollectionItem {
  id: number;
  name: string;
}

export interface YarnImageCollectionItem extends CollectionItem {
  image: string;
  yarn: number;
}

export interface YarnManufacturerCollectionItem extends CollectionItem {
  address: string | null;
  city: string | null;
  contact_address: string | null;
  contact_person: string | null;
  contact_phone_number: string | null;
  country: number | null;
  name: string | null;
  shipment_methods: number[];
  website_address: string | null;
  zip_code: string | null;
  available_country?: number[];
  main_image: string | null;
}

export interface WashAndCareCollectionItem extends CollectionItem {
  name: string;
  description: string | null;
  image: string;
  type_of_wash_and_care: number;
}

export interface UnitCollectionItem extends CollectionItem {
  name: string;
}

export interface TypeWashAndCareCollectionItem extends CollectionItem {
  name: string;
}

export interface ShipmentMethodCollectionItem extends CollectionItem {
  name: string;
}

export interface NeedleImageCollectionItem extends CollectionItem {
  image: string;
  needle: number;
}

export interface DesignElementItemCollectionItem {
  id: number;
  name: string;
}
