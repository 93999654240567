import { styled } from '@mui/material';

export const Container = styled('div')<{ 
  isHidden: boolean;
  isExpanded: boolean;
}>(({ isHidden, isExpanded, theme }) => ({
  position: isExpanded ? 'fixed' : 'relative',
  width: '100%',
  height: isExpanded ? '100vh' : '23vh',
  minHeight: isExpanded ? '-webkit-fill-available' : 'auto',
  background: '#fff',
  overflow: 'hidden',
  visibility: isHidden ? 'hidden' : 'visible',
  opacity: isHidden ? 0 : 1,
  top: isExpanded ? 0 : 'auto',
  left: isExpanded ? 0 : 'auto',
  right: isExpanded ? 0 : 'auto',
  bottom: isExpanded ? 0 : 'auto',
  zIndex: isExpanded ? 1200 : 1,
  className: isExpanded ? 'expanded' : '',

  [theme.breakpoints.down('laptop')]: {
    height: isExpanded ? '100dvh' : '23vh',
    position: isExpanded ? 'fixed' : 'relative',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    ...(isExpanded && {
      '@media (orientation: portrait)': {
        transform: 'rotate(90deg)',
        transformOrigin: 'left top',
        width: '100vh',
        height: '100vw',
        position: 'absolute',
        top: 0,
        left: '100%',
      }
    })
  },
}));

export const ViewportContainer = styled('div')<{ isExpanded?: boolean }>(({ isExpanded }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
  userSelect: 'none',

  '@media (orientation: portrait)': {
    ...(isExpanded && {
      width: '100%',
      height: '100%',
    })
  }
}));

export const DiagramContainer = styled('div')<{ shouldAnimate: boolean }>(({ shouldAnimate }) => ({
  position: 'relative',
  width: '100%',
  transition: shouldAnimate ? 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)' : 'none',
  willChange: 'transform',
}));

export const DiagramImage = styled('img')({
  width: '100%',
  height: 'auto',
  display: 'block',
});

export const HighlightBar = styled('div')({
  position: 'absolute',
  left: 0,
  width: '100%',
  background: '#ceff1a4d',
  pointerEvents: 'none',
});

export const NavigationButton = styled('button')<{ 
  top?: boolean; 
  bottom?: boolean; 
  disabled?: boolean;
  $isExpanded?: boolean;
}>(({ top, bottom, disabled, $isExpanded, theme }) => ({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: $isExpanded ? '4vh' : '8px',
  width: $isExpanded ? '15vh' : 'auto',
  height: $isExpanded ? '15vh' : 'auto',
  background: 'rgba(255, 255, 255, 0.9)',
  border: '1px solid #000',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  
  ...(top && {
    top: $isExpanded ? '4vh' : '8px',
  }),
  
  ...(bottom && {
    bottom: $isExpanded ? '4vh' : '8px',
  }),

  '& svg': {
    fontSize: $isExpanded ? '8vh' : '1.5rem',
  },

  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? 'default' : 'pointer',
  '&:hover': {
    background: disabled ? 'rgba(255, 255, 255, 0.9)' : '#F6F4ED',
  }
}));

export const FullscreenButton = styled('button')<{ $isExpanded?: boolean }>(({ $isExpanded }) => ({
  position: 'absolute',
  right: $isExpanded ? '4vh' : '8px',
  top: $isExpanded ? '4vh' : '8px',
  padding: $isExpanded ? '4vh' : '8px',
  width: $isExpanded ? '15vh' : 'auto',
  height: $isExpanded ? '15vh' : 'auto',
  background: 'rgba(255, 255, 255, 0.9)',
  border: '1px solid #000',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 1,
  
  '& svg': {
    fontSize: $isExpanded ? '8vh' : '1.5rem',
  },

  '&:hover': {
    background: '#F6F4ED',
  }
}));

export const ExpandButton = styled('button')<{ $isExpanded?: boolean }>(({ $isExpanded }) => ({
  position: 'absolute',
  right: $isExpanded ? '23vh' : '58px',
  top: $isExpanded ? '4vh' : '8px',
  padding: $isExpanded ? '4vh' : '8px',
  width: $isExpanded ? '15vh' : 'auto',
  height: $isExpanded ? '15vh' : 'auto',
  background: 'rgba(255, 255, 255, 0.9)',
  border: '1px solid #000',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 1,
  
  '& svg': {
    fontSize: $isExpanded ? '8vh' : '1.5rem',
  },

  '&:hover': {
    background: '#F6F4ED',
  }
}));