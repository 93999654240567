import React, { useState, useEffect, RefObject } from "react";

export function useIntersection<T extends Element>(ref: RefObject<T>): boolean {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  
  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, {
      rootMargin: '100px', // Margin for trigger earlier
      threshold: 0
    });

    observer.observe(element);

    return () => {
      if (element) {
        observer.unobserve(element);
      }
      observer.disconnect();
    };
  }, [ref.current]);
  
  return isIntersecting;
}
