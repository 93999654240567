import { DirectusFields } from '@/common/constants/directus-fields';
import { Errors } from '@/common/constants/errors';
import { Collections } from '@/common/interfaces/collection.interface';
import { User } from '@/common/interfaces/user.interface';
import { normalizePattern, PatternWithCollection } from '@/utils/normalizePattern';
import { TransportResponse } from '@directus/sdk';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import {
  getDesignersOfTheWeekAction,
  getDesignersOfTheWeekErrorAction,
  getDesignersOfTheWeekSuccessAction,
  getPagesContentAction,
  getPagesContentErrorAction,
  getPagesContentSuccessAction,
  getPromotedPatternsAction,
  getPromotedPatternsErrorAction,
  getPromotedPatternsSuccessAction,
  getRecentPatternUploadsAction,
  getRecentPatternUploadsErrorAction,
  getRecentPatternUploadsSuccessAction,
  getRecentDesignersAction,
  getRecentDesignersErrorAction,
  getRecentDesignersSuccessAction,
  getRecentYarnUploadsSuccessAction,
  getRecentYarnUploadsErrorAction,
  getRecentYarnUploadsAction,
  getYarnItemsListSuccessAction,
  getYarnItemsListErrorAction,
  getYarnItemsListAction,
} from '../reducers/pages-content.reducer';

import { displayErrorAction } from '../reducers/system.reducer';
import { PageContentCollectionItem } from './../../common/interfaces/pages-content.interface';
import { YarnWithStage } from '../types/yarniverse';

function* getPagesContentRequest(api: ApiType) {
  try {
    const response: TransportResponse<PageContentCollectionItem[]> = yield call(
      api.getCollectionWithoutRefresh,
      Collections.WebPages,
      {
        fields: DirectusFields.WebPages,
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }
    yield put(getPagesContentSuccessAction(response.data));
  } catch (error: any) {
    yield put(getPagesContentErrorAction());
    yield put(displayErrorAction(error?.message));
  }
}

function* getRecentPatternUploadsRequest(api: ApiType) {
  try {
    const response: TransportResponse<PatternWithCollection[]> = yield call(
      api.getCollectionWithoutRefresh,
      Collections.Patterns,
      {
        fields: DirectusFields.Pattern,
        limit: 5,
        sort: ['-date_created' as never],
        filter: {
          status: {
            _in: ['Published', 'published'],
          },
          _or: [
            {
              main_image: {
                _nnull: true,
              },
            },
            {
              images: {
                _nnull: true,
              },
            },
          ],
        }
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }
    yield put(getRecentPatternUploadsSuccessAction(response.data.map(normalizePattern)));
  } catch (error: any) {
    yield put(getRecentPatternUploadsErrorAction());
  }
}

function* getPromotedPatternsRequest(api: ApiType) {
  try {
    const response: TransportResponse<PatternWithCollection[]> = yield call(
      api.getCollectionWithoutRefresh,
      Collections.Patterns,
      {
        fields: DirectusFields.Pattern,
        limit: 5,
        filter: {
          status: {
            _in: ['Published', 'published'],
          },
          is_promoted: {
            _eq: true,
          },
        },
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }
    yield put(getPromotedPatternsSuccessAction(response.data.map(normalizePattern)));
  } catch (error: any) {
    yield put(getPromotedPatternsErrorAction());
  }
}

function* getDesignersOfTheWeekRequest(api: ApiType) {
  try {
    const response: TransportResponse<User[]> = yield call(
      api.getCollectionWithoutRefresh,
      Collections.Users,
      {
        limit: 5,
        sort: ['-date_created' as never],
        filter: {
          designers_of_the_week: {
            _eq: true,
          },
        },
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }
    yield put(getDesignersOfTheWeekSuccessAction(response.data));
  } catch (error: any) {
    yield put(getDesignersOfTheWeekErrorAction());
  }
}

function* getRecentDesignersRequest(api: ApiType) {
  try {
    const response: TransportResponse<User[]> = yield call(
      api.getCollectionWithoutRefresh,
      Collections.Users,
      {
        fields: DirectusFields.Users,
        limit: 5,
        sort: ['-date_created' as never],
        filter: {
          role: {
            name: {
              _eq: 'PATTERN DESIGNER',
            },
          },
          status: {
            _eq: 'active',
          },
          plan_active: {
            _eq: true,
          },
          // plan_activated: {
          //   _nnull: true,
          // },
          avatar: {
            _nnull: true,
          },
        }
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }
    yield put(getRecentDesignersSuccessAction(response.data));
  } catch (error: any) {
    yield put(getRecentDesignersErrorAction());
  }
}

function* getRecentYarnUploadsRequest(api: ApiType) {
  try {
    const response: TransportResponse<YarnWithStage[]> = yield call(
      api.getCollectionWithoutRefresh,
      Collections.Yarn,
      {
        fields: DirectusFields.Yarn,
        limit: 5,
        filter: {
          status: {
            _eq: 'published',
          },
          _or: [
            {
              main_color: {
                _nnull: true,
              },
            },
            {
              colors: {
                _nnull: true,
              },
            },
          ],
          yarn_manufacturer: {
            isAvailableOnSite: {
              _eq: true,
            },
          },
        },
        sort: ['-date_created' as never],
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }
    yield put(getRecentYarnUploadsSuccessAction(response.data));
  } catch (error: any) {
    yield put(getRecentYarnUploadsErrorAction());
  }
}

function* getYarnItemsListRequest(api: ApiType) {
  try {
    const response: TransportResponse<YarnWithStage[]> = yield call(
      api.getCollectionWithoutRefresh,
      Collections.Yarn,
      {
        fields: DirectusFields.Yarn,
        filter: {
          status: {
            _eq: 'published',
          },
          _or: [
            {
              main_color: {
                _nnull: true,
              },
            },
            {
              colors: {
                _nnull: true,
              },
            },
          ],
          // yarn_manufacturer: {
          //   isAvailableOnSite: {
          //     _eq: true,
          //   },
          // },
        },
        sort: ['name' as never],
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }
    yield put(getYarnItemsListSuccessAction(response.data));
  } catch (error: any) {
    yield put(getYarnItemsListErrorAction());
  }
}

export const pagesContentSaga = function* (api: ApiType) {
  yield all([takeLatest(getPagesContentAction.type, getPagesContentRequest, api)]);
  yield all([takeLatest(getRecentPatternUploadsAction.type, getRecentPatternUploadsRequest, api)]);
  yield all([takeLatest(getPromotedPatternsAction.type, getPromotedPatternsRequest, api)]);
  yield all([takeLatest(getDesignersOfTheWeekAction.type, getDesignersOfTheWeekRequest, api)]);
  yield all([takeLatest(getRecentDesignersAction.type, getRecentDesignersRequest, api)]);
  yield all([takeLatest(getRecentYarnUploadsAction.type, getRecentYarnUploadsRequest, api)]);
  yield all([takeLatest(getYarnItemsListAction.type, getYarnItemsListRequest, api)]);
};
