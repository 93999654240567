import { Input as OriginalInput } from '@/components/input/input';
import { PageContainer } from '@/components/page-container/page-container';
import { setPatternDataAction } from '@/store/reducers/pattern.reducer';
import { getPatternDataSelector } from '@/store/selectors/pattern.selector';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NextButton } from '../next-button';
import { styled } from '@mui/material';
import { Typography } from '@mui/material';

export interface Props {
  onPressNextButton: () => void;
}

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  margin: '1rem 2.5rem 3rem',
}));

export const PageContainerStyled = styled(PageContainer)(({ theme }) => ({
  padding: 0,
  [theme.breakpoints.down('tablet')]: {
    // padding: '1rem 0',
  },
}));

const StyledInput = styled(OriginalInput)({
  padding: 0,
  width: '40rem',
});

const StyledInputContainer = styled('div')({
  margin: '7.5rem 2.5rem 2.5rem'
});

export const PatternName: FC<Props> = (props) => {
  const { onPressNextButton } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pattern = useSelector(getPatternDataSelector);

  const [name, setName] = useState(pattern?.name);

  const onChangeNameHandler = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setName(target.value);
  };

  const onPressNextButtonHandler = () => {
    dispatch(
      setPatternDataAction({
        id: pattern.id,
        name,
      }),
    );

    onPressNextButton();
  };

  useEffect(() => {
    setName(pattern?.name);
  }, [pattern.name]);

  return (
    <>
      <PageContainerStyled>
        <div>
            <Title>{t('patternizerSteps.patternName')}</Title>
            {/* <p>{t('Patternizer.patternNameText')}</p> */}
            <StyledInputContainer>
              <StyledInput
                  placeholder={t('patternizerSteps.enterPatternName')}
                  value={name}
                  onChange={onChangeNameHandler}
              />
            </StyledInputContainer>
        </div>
      </PageContainerStyled>
      <NextButton colored onPressNext={onPressNextButtonHandler} disabled={!name} />
    </>
  );
};
