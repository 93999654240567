import { ColorPicker } from '@/components/color-picker';
import { useInputWithDelay } from '@/hooks/useInputDelay.hook';
import { setAlternativeItemsColorAction } from '@/store/reducers/collection.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { getPatternAvailableSizesSelector } from '@/store/selectors/pattern.selector';
import { AlternativeItemCollectionItemWithStage } from '@/store/types/collection';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { FC, MouseEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  DeleteIcon,
  EditColor,
  IconButtonWrapper,
  Inner,
  InputStyled,
  NoPhoto,
  Photo,
  PhotoContainer,
  SizeContainer,
  SizeInput,
  Title,
} from './styles';
import { t } from 'i18next';

interface Props {
  first?: boolean;
  item: AlternativeItemCollectionItemWithStage;
  onChangeMeasurement: (id: number, value: string) => void;
  onChangeName: (name: string, alternativeItem: number) => void;
  onDeleteItem: (id: number) => void;
}

export const Item: FC<Props> = (props) => {
  const { first, item, onChangeMeasurement, onChangeName, onDeleteItem } = props;
  const dispatch = useDispatch();

  try {
    const collections = useSelector(getCollectionDataSelector);
    const patternSizes = useSelector(getPatternAvailableSizesSelector);

    const getSizeLabel = (size: number) => {
      try {
        return collections.AvailableSize.find((s) => s.id === size)?.size_name || '-';
      } catch {
        return '-';
      }
    };

    const [colorAnchor, setColorAnchor] = useState<HTMLButtonElement | null>(null);

    const handleChangeName = (value: string) => {
      onChangeName(value, item?.id || 0);
    };

    const [title, setTitle] = useInputWithDelay(item?.name || '', handleChangeName, 500);

    const openPicker = (event: MouseEvent<any>) => {
      setColorAnchor(event.currentTarget);
    };

    const closePicker = () => {
      setColorAnchor(null);
    };

    const onChangeColor = (color: number) => {
      dispatch(setAlternativeItemsColorAction({ color, alternative: item?.id || 0 }));
      setColorAnchor(null);
    };

    // Provide fallbacks for all data
    const manufacturer = collections.YarnManufacturer.find(m => m.id === item?.yarn?.yarn_manufacturer);
    const manufacturerName = manufacturer?.name || 'Unknown Manufacturer';
    const yarnName = item?.yarn?.name || 'Unknown Yarn';
    const colorName = item?.color?.name || '';

    const filteredAndSortedMeasurements = item?.yarn_measurement
      ?.slice()
      ?.filter((measurement) => patternSizes.includes(measurement?.size || 0))
      ?.sort((a, b) => {
        const s_a = collections.AvailableSize.find((s) => s.id === a?.size);
        const s_b = collections.AvailableSize.find((s) => s.id === b?.size);
        return (s_a?.sort ?? 0) - (s_b?.sort ?? 0);
      }) || [];

    return (
      <Container>
        {first && (
          <Title align='left' variant='h5'>
            {t('patternizerSteps.addYarnQuantity')}
          </Title>
        )}
        <InputStyled
          placeholder={t('patternizerSteps.yarnNamePlaceholder')}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Title variant='body2'>{manufacturerName} {yarnName} {colorName}</Title>
        <Inner>
          <Tooltip title={yarnName} placement='bottom'>
            <PhotoContainer>
              {item?.color?.image ? (
                <>
                  <Photo src={getAssetBackendUrl(item.color.image, 200)} />
                  <EditColor onClick={openPicker}>{t('patternizerSteps.editColor')}</EditColor>
                </>
              ) : (
                <>
                  <EditColor onClick={openPicker}>{t('patternizerSteps.editColor')}</EditColor>
                  <NoPhoto></NoPhoto>
                </>
              )}
            </PhotoContainer>
          </Tooltip>
          <Grid container direction='row'>
            {filteredAndSortedMeasurements.map((el) => (
              <SizeContainer key={el?.id || 'unknown'}>
                <div style={{ borderBottom: '1px solid black' }}>
                  <Typography variant='h5'>{getSizeLabel(el?.size || 0)}</Typography>
                </div>
                <div>
                  <SizeInputWrapper
                    init={el?.value || ''}
                    onChange={(value) => onChangeMeasurement(el?.id || 0, value)}
                  />
                </div>
              </SizeContainer>
            ))}
            <IconButtonWrapper>
              <IconButton onClick={() => onDeleteItem(item?.id || 0)}>
                <DeleteIcon />
              </IconButton>
            </IconButtonWrapper>
          </Grid>
        </Inner>
        <ColorPicker
          manufacturerName={manufacturerName}
          yarnName={yarnName}
          colors={item?.yarn?.colors || []}
          anchorEl={colorAnchor}
          onClose={closePicker}
          onSelect={onChangeColor}
        />
      </Container>
    );
  } catch (error) {
    console.error('Error rendering Item:', error);
    return null; // Or some fallback UI
  }
};

interface WrapperProps {
  init: string;
  onChange: (value: string) => void;
}

const SizeInputWrapper: FC<WrapperProps> = (props) => {
  const { init, onChange } = props;

  const handleChange = (value: string) => {
    onChange(value);
  };

  const [value, setValue] = useInputWithDelay(init, handleChange, 1000);

  return (
    <SizeInput
      disableUnderline
      value={value}
      onChange={(event) => setValue(event.target.value)}
      onBlur={() => setValue(value)}
      placeholder='Gram'
      type='number'
    />
  );
};
