import React from 'react';
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet';
import { Coordinates } from './coordinates';
import PopupView from './popup';

import 'leaflet/dist/leaflet.css';

const mockPopupData = {
    storeName: 'Nedralia',
    street: 'Vasagatan 1',
    postalCode: '110 10',
    city: 'Stockholm',
    phone: '22 34 56',
    email: 'john@doe.com',
};

interface MapProps {
    points: Coordinates[];
}

export const Map = ({ points }: MapProps) => {
    return (
        <MapContainer
            bounds={points}
            placeholder={<MapPlaceholder />}
            style={{ height: '100%', width: '100%' }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {points.map((point, index) => (
                <Marker key={index} position={point}>
                    <Popup>
                        <PopupView {...mockPopupData} />
                    </Popup>
                </Marker>
            ))}
        </MapContainer>
    );
}

function MapPlaceholder() {
    return (
      <p>
        Map of London.{' '}
        <noscript>You need to enable JavaScript to see this map.</noscript>
      </p>
    )
  }