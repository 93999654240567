import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: '#fff',
  zIndex: 1200,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem',
  cursor: 'zoom-out',
  
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.1)',
    pointerEvents: 'none',
  },
  
  [theme.breakpoints.down('laptop')]: {
    padding: 0,
  }
}));

export const NavigationOverlay = styled('div')({
  position: 'relative',
  width: 'fit-content',
  height: 'fit-content',
  background: '#fff',
  overflow: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'crosshair',
  zIndex: 1,
});

export const DiagramImage = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
});

export const HighlightBar = styled('div')({
  position: 'absolute',
  left: 0,
  width: '100%',
  background: '#ceff1a4d',
  pointerEvents: 'none',
  transition: 'top 0.3s ease-out',
});

export const CollapseButton = styled('button')(({ theme }) => ({
  position: 'absolute',
  top: '4vh',
  right: '4vh',
  padding: '4vh',
  width: '15vh',
  height: '15vh',
  background: 'rgba(255, 255, 255, 0.9)',
  border: '1px solid #000',
  borderRadius: '50%',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  
  '& svg': {
    fontSize: '8vh',
  },

  '&:hover': {
    background: '#F6F4ED',
  },

  [theme.breakpoints.down('laptop')]: {
    top: '4vh',
    right: '4vh',
  }
}));