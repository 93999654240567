
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@/components/divider';
import { Map } from './components/map';
import {
    Coordinates,
    asker,
    bergen,
    oslo,
    stavanger,
    voksenlia,
    kristiansand
} from './components/map/coordinates';
import { Button } from '@/components/button/button';
import { UpperCaseTitle } from '@/components/upper-case-title';
import { Content, Paragraph, RightPanel, MapWrapper, Wrapper } from './components/styled-elements';

interface StoresPageProps {}

const points: Coordinates[] = [asker, oslo, bergen, voksenlia, kristiansand, stavanger];

export const StoresPage: FC<StoresPageProps> = () => {
    const { t } = useTranslation();

    const onClick = useCallback(() => {
        // TODO: Implement navigation
        console.log('Navigate to find a store');
    }, []);

    return (
        <Wrapper>
            <UpperCaseTitle>{t('stores.title')}</UpperCaseTitle>
            <Divider variant={"fullWidth"} />
            <Content>
                <MapWrapper>
                    <Map points={points} />
                </MapWrapper>
                <RightPanel>
                    <Paragraph>{t('stores.rightPanel.text')}</Paragraph>
                    <Button colored onClick={onClick}>{t('stores.rightPanel.button')}</Button>
                </RightPanel>
            </Content>
        </Wrapper>
    )
}

export default StoresPage;