import { Dropdown } from '@/components/dropdown';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import {
  getYarniverseDataSelector,
  getYarniverseNeedleSizesSelector,
} from '@/store/selectors/yarniverse.selector';
import { ChangeEvent, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RadioGroup, styled } from '@mui/material';
import {
  createYarnNeedleSizeAction,
  removeYarnNeedleSizeAction,
} from '@/store/reducers/yarniverse.reducer';
import { RadioInput } from '@/components/radio-checkbox-input/radio-checkbox-input';
import { InputButtonSelect } from '@/components/input-button-select';

export const DropdownStyled = styled(Dropdown)({
  border: 'none',
  fontSize: '1.375rem',
});
export const NeedleSize = () => {
  const dispatch = useDispatch();

  const [systemOfMeasurement, setSystemOfMeasurement] = useState<'EU' | 'US'>('EU');

  const { NeedleSize } = useSelector(getCollectionDataSelector);
  const yarnNeedleSizes = useSelector(getYarniverseNeedleSizesSelector);
  const { id: yarnId } = useSelector(getYarniverseDataSelector);
  const [selectedNeedlesState, setSelectedNeedlesState] = useState<Array<number>>(
    yarnNeedleSizes.map((needleSize) => needleSize.id),
  );
  const NEEDLE_SIZES = useMemo(() => {
    return NeedleSize.map(({ id, eu_size_in_mm, us_size }) => ({
      label:
        systemOfMeasurement === 'EU'
          ? `${Number.parseFloat(Number(eu_size_in_mm).toFixed(4))} mm`
          : us_size,
      value: id,
    }));
  }, [NeedleSize, systemOfMeasurement]);

  const onChangeSystemOfMeasurement = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSystemOfMeasurement(value as 'EU' | 'US');
  };

  const handleChange = (value: number) => {
    if (!yarnId) return;

    const yarnHasNeedleSize = selectedNeedlesState?.find((x) => x === value) !== undefined;

    const updatedNeedleSizes = yarnHasNeedleSize
      ? selectedNeedlesState.filter((x) => x !== value)
      : [...selectedNeedlesState, value];

    setSelectedNeedlesState(updatedNeedleSizes);

    if (yarnHasNeedleSize) {
      const junctionId = yarnNeedleSizes.find((x) => x.id === value)?.junctionId;
      if (!junctionId) return;

      dispatch(
        removeYarnNeedleSizeAction({
          yarnId: yarnId,
          junctionId,
        }),
      );
    } else {
      dispatch(
        createYarnNeedleSizeAction({
          yarnId: yarnId,
          needleSizeId: value,
        }),
      );
    }
  };

  return (
    <>
      <RadioGroup
        onChange={onChangeSystemOfMeasurement}
        name={'test'}
        value={systemOfMeasurement}
        style={{ display: 'block', paddingLeft: '10px' }}
      >
        <RadioInput label='EU' value='EU' />
        <RadioInput label='US' value='US' />
      </RadioGroup>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '36px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {NEEDLE_SIZES.map((needleSize) => (
            <InputButtonSelect
              key={needleSize.value + needleSize.label}
              label={needleSize.label}
              value={needleSize.value}
              checked={selectedNeedlesState.includes(needleSize.value)}
              onChange={() => handleChange(needleSize.value)}
            />
          ))}
        </div>
      </div>
    </>
  );
};
