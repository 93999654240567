import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonRow } from './components/buttonRow';
import { SmallButton } from '@/components/button/small-button';
import { Marketplace } from './components/marketplace';
import RightPane from './components/right-pane';
import { 
    EditButton,
    ImageContainer,
    SplitView,
    SplitViewItem,
    Wrapper
} from './components/styled-components';

const DistributorPage = () => {
    const { t } = useTranslation();
    const [uploadedImage, setUploadedImage] = useState<string | ArrayBuffer>('https://via.placeholder.com/1000');
    const [showEdit, setShowEdit] = useState(true);

    const onEditImage = () => {
        // Open a file upload dialog
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.addEventListener('change', (event) => {
            const file = (event.target as HTMLInputElement).files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const image: any = e
                    setUploadedImage(image.target?.result);
                };
                reader.readAsDataURL(file);
            }
        });
        fileInput.click();
    }

    return (
        <Wrapper>
            <h4>{t('distributor.settings')}</h4>
            <SplitView>
                <SplitViewItem>
                    <ImageContainer>
                        <EditButton onClick={onEditImage}>
                            <SmallButton>{t('distributor.editImage')}</SmallButton>
                        </EditButton>
                        <img src={uploadedImage as string} alt="uploaded" />
                    </ImageContainer>
                </SplitViewItem>
                <SplitViewItem>
                    <RightPane showEdit={showEdit} />
                </SplitViewItem>
            </SplitView>
            <ButtonRow />
            <hr />
            <Marketplace />
        </Wrapper>
    );
}

export default DistributorPage;