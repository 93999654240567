export enum PATH {
  HOME = '/',
  PRELAUNCH = '/prelaunch',
  KNITSHOP = '/knitshop',
  YARNIVERSE_START = '/yarniverse/start',
  YARNIVERSE = '/yarniverse',
  LEARKNIT = '/learknit',
  PATTERNS = '/patterns',
  PATTERNS_START = '/patterns/start',
  SIGNIN = '/signin',
  SIGNUP = '/signup',
  FORGOT_PASSWORD = '/forgot-password',
  SIGNUP_SUCCESS = '/signup-success',
  BASKET = '/basket',
  DISTRIBUTOR = '/distributor',
  NOT_FOUND = '*',
  SUCCESS = '/success',
  PATTERN = '/pattern',
  PROFILE = '/profile',
  PROFILE_SETTING = '/profile-setting',
  TERMS_PRIVACY_ABOUT = '/terms-privacy-about',
  STEPS_VIEWER = '/steps-viewer',
  RESOURCE_VIEWER = '/resource-viewer',
  RESOURCE_UPLOAD = '/resource-upload',
  PAYMENT_PAGE = '/payment',
  PAYMENT_SUCCESS = '/payment-success',
  PAYMENT_ERROR = '/payment-error',
  CHECKOUT_SUCCESS = '/checkout-success',
  SEARCH = '/search',
  STORES = '/physical-stores',
  YARN = '/yarn/:id',
  NEEDLES = '/needles',
  NEEDLE_START = '/needle/start',
  NEEDLE_CREATE = '/needle/create',
  NEEDLE_EDIT = '/needle/edit',
  NEEDLE = '/needle',
  NEW_PAYMENT_WALL = '/new-payment-wall-old',
  PROMOTED_PATTERNS = '/promoted-patterns',
  PROMOTED_YARN = '/promoted-yarn',
  PROMOTED_DESIGNERS = '/promoted-designers',
  PAYMENT_WALL = '/payment-wall',
  PAYMENT_WALL_STRIPE = '/payment-wall-stripe',
  PAYMENT_WALL_STRIPE_SUCCESS = '/payment-wall-stripe-success',
  YARNS = '/yarns',
  ALLPATTERNS = '/allpatterns',
  PRODUCT_PATTERNIZER = '/product-patternizer',
  PRODUCT_YARNIVERSE = '/product-yarniverse',
  LOCAL_YARN_STORES = '/local-yarn-stores',
  ABOUT_US = '/about-us',
  OUR_TEAM = '/our-team',
  PRIVACY_POLICY = '/privacy-policy',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  DESIGNERS = '/designers',
  RETAILERS = '/retailers',
  INVENTORY = '/store/:storeId/inventory/:id',
  STORE = '/store/:id',
}
