import React, { useRef, useEffect, useState } from 'react';
import { 
  Container, 
  ViewportContainer, 
  DiagramContainer, 
  DiagramImage,
  NavigationButton,
  HighlightBar,
  FullscreenButton,
  ExpandButton
} from './styles';
import { ExpandMore, ExpandLess, Fullscreen, Close, Expand } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface FocusedViewProps {
  imageUrl: string;
  currentRow: number;
  onRowChange: (row: number) => void;
  onExpand: () => void;
  onFocusedExpand: () => void;
  totalRows: number;
  isHidden: boolean;
  isExpanded: boolean;
  isFocusedExpanded: boolean;
}

export const FocusedView: React.FC<FocusedViewProps> = ({
  imageUrl,
  currentRow,
  onRowChange,
  onExpand,
  onFocusedExpand,
  totalRows,
  isHidden,
  isExpanded,
  isFocusedExpanded
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [diagramTransform, setDiagramTransform] = useState(0);
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const { t } = useTranslation();

  const handleImageLoad = () => {
    if (imageRef.current) {
      calculateDiagramPosition();
    }
  };

  const calculateDiagramPosition = () => {
    if (containerRef.current && imageRef.current) {
      // Get current image height based on current width
      const currentImageHeight = imageRef.current.getBoundingClientRect().height;
      const rowHeight = currentImageHeight / totalRows;
      const containerHeight = containerRef.current.clientHeight;
      
      // Calculate position from bottom
      const bottomOffset = currentImageHeight - (rowHeight * (currentRow + 1));
      
      // Calculate position to center the highlight
      const targetPosition = bottomOffset - (containerHeight / 2) + (rowHeight / 2);
      
      // Always center on the highlight
      setDiagramTransform(-targetPosition);
    }
  };

  // Use ResizeObserver to detect container size changes
  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (!isHidden) {
        calculateDiagramPosition();
      }
    });

    resizeObserver.observe(containerRef.current);
    resizeObserver.observe(imageRef.current!);  // Also observe image size changes

    return () => {
      resizeObserver.disconnect();
    };
  }, [currentRow, totalRows, isExpanded, isFocusedExpanded]);

  // Delay calculation of diagram position when it becomes visible
  useEffect(() => {
    if (!isHidden) {      
      // Calculate proper position after a short delay
      const timer = setTimeout(() => {
        calculateDiagramPosition();
      }, 200); // Delay in ms - should be similar to opacity transition for container to look smooth

      return () => clearTimeout(timer);
    }
  }, [isHidden]);

  // Handle other updates when visible
  useEffect(() => {
    if (!isHidden) {
      calculateDiagramPosition();
    }
  }, [currentRow, totalRows, isExpanded, isFocusedExpanded]);

  useEffect(() => {
    setShouldAnimate(false);
    const timer = setTimeout(() => setShouldAnimate(true), 50);
    return () => clearTimeout(timer);
  }, [isExpanded]);

  // Handle keyboard events - control the diagram with the keyboard and avoid interfering with other elements
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Force capture all keyboard events except text input
      if (isHidden) return;
      
      const target = event.target as HTMLElement;
      const isTextInput = target.matches('input[type="text"], textarea, [contenteditable]');
      
      if (isTextInput) return;

      // Capture and handle all other events
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          event.stopPropagation(); // Stop event from bubbling up
          if (currentRow < totalRows - 1) {
            onRowChange(currentRow + 1);
          }
          break;
        case 'ArrowDown':
          event.preventDefault();
          event.stopPropagation();
          if (currentRow > 0) {
            onRowChange(currentRow - 1);
          }
          break;
      }
    };

    // Add event listener with capture phase to intercept events before they reach other handlers
    if (!isHidden) {
      window.addEventListener('keydown', handleKeyDown, true);
    }

    // Cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [isHidden, currentRow, totalRows, onRowChange]);

  const handleDoubleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    
    if (!imageRef.current) return;
    
    // Get the click position relative to the image
    const rect = imageRef.current.getBoundingClientRect();
    const y = event.clientY - rect.top;
    const height = rect.height;
    
    // Calculate which row was clicked
    const clickedRow = totalRows - 1 - Math.floor((y / height) * totalRows);
    onRowChange(clickedRow);
  };

  return (
    <Container isHidden={isHidden} isExpanded={isExpanded}>
      <ViewportContainer 
        ref={containerRef}
        isExpanded={isExpanded}
      >
        {isFocusedExpanded ? (
          <ExpandButton onClick={onFocusedExpand} $isExpanded={isExpanded}>
            <Close />
          </ExpandButton>
        ) : (
          <Tooltip title={t('knitmode.expandView')}>
            <ExpandButton onClick={onFocusedExpand} $isExpanded={isExpanded}>
              <Expand />
            </ExpandButton>
          </Tooltip>
        )}
        
        <Tooltip title={t('knitmode.fullscreenView')}>
          <FullscreenButton onClick={onExpand} $isExpanded={isExpanded}>
            <Fullscreen />
          </FullscreenButton>
        </Tooltip>
        <DiagramContainer
          shouldAnimate={shouldAnimate}
          style={{
            transform: `translateY(${diagramTransform}px)`,
          }}
          onDoubleClick={handleDoubleClick}
        >
          <DiagramImage 
            ref={imageRef}
            src={imageUrl} 
            alt="Knitting diagram"
            onLoad={handleImageLoad}
          />
          <HighlightBar 
            style={{ 
              top: `${((totalRows - 1 - currentRow) * 100) / totalRows}%`,
              height: `${100 / totalRows}%` 
            }} 
          />
        </DiagramContainer>
      </ViewportContainer>
      
      <NavigationButton 
        top 
        onClick={() => onRowChange(currentRow + 1)}
        disabled={currentRow === totalRows - 1}
        $isExpanded={isExpanded}
      >
        <ExpandLess />
      </NavigationButton>
      <NavigationButton 
        bottom 
        onClick={() => onRowChange(currentRow - 1)}
        disabled={currentRow === 0}
        $isExpanded={isExpanded}
      >
        <ExpandMore />
      </NavigationButton>
    </Container>
  );
};
