export enum API_ROUTES {
  USERS = '/users',
  ROLES = '/roles',
  ITEMS = '/items',
}

export enum GPT_API_ROUTES {
  UPLOAD_PATTERN = '/process-pdf',
  TRANSLATE_STEPS = '/translate-steps',
}

export enum REQUEST_METHOD {
  POST = 'POST',
  GET = 'GET',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

//TODO:
export enum API_COLLECTION {
  Craft = 'Craft',
  Projects = 'type_of_project',
  Languages = 'language',
  Suitable = 'passer_til',
  Gauge = 'masketett',
  YarnGauge = 'Yarn_masketett',
  YarnNeedleSize = 'Yarn_needle_size',
  YarnWashAndCare = 'Yarn_wash_and_care',
  Technics = 'technique',
  Needles = 'needles',
  NeedleMaterials = 'needle_materials',
  NeedleType = 'needle_type',
  NeedleSize = 'needle_size',
  NeedleSizeCategories = 'needle_size_categories',
  NeedleLength = 'cable_length',
  NeedleImages = 'needle_images',
  AvailableSize = 'size_chart',
  UnitMeasurement = 'unit_measurement',
  Needle = 'Needle',
  Measurements = 'Measurements',
  MeasurementAvailableSizes = 'measurement_size_chart',
  Yarn = 'Yarn',
  YarnColorAlternative = 'yarn_color_alternativ',
  YarnColor = 'yarn_color',
  YarnAlternatives = 'yarn_alternative',
  YarnMeasurement = 'yarn_measurements',
  AlternativeItem = 'alternativ_items',
  PatternImage = 'pattern_images',
  QuantityWithSize = 'quantity_with_size',
  Patterns = 'patterns',
  Payments = 'payments',
  About = 'about',
  PrivacyPolicy = 'privacy_policy',
  TermCondition = 'terms_conditions',
  PaymentWallPatternDesigner = 'payment_wall_pattern_designer',
  PaymentWallYarnSupplier = 'payment_wall_yarn_supplier',
  PaymentWallLocalYarnStore = 'payment_wall_local_yarn_store',
  Stores = 'stores',
  Resources = 'resources',
  ResourceType = 'resource_type',
  ResourceContentType = 'resource_content_type',
  ResourcesImages = 'resources_images',
  PatternDiagrams = 'pattern_diagrams',
  Countries = 'countries',
  Orders = 'orders',
  Diagrams = 'step_diagrams',
  Fiber = 'fiber',
  FiberType = 'fiber_type',
  YarnManufacturer = 'Yarn_manufacturer',
  WashAndCare = 'wash_and_care',
  TypeWashAndCare = 'type_of_wash_and_care',
  YarnPrice = 'yarn_price',
  Units = 'units',
  ShipmentMethods = 'shipment_method',
  WebPages = 'web_pages',
  Users = 'directus_users',
  Texture = 'texture',
  ThreadConstruction = 'thread_construction',
  PatternsSizeChart = 'patterns_size_chart',
  Product = 'products',
}
