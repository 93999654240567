import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { styled } from '@mui/material';
import { FC, useState } from 'react';
import { ImgVideo } from '@/components/img-video/img-video';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

interface Props {
  files: {
    file: string;
  }[];
  filesTitle: {
    [key: string]: string;
  };
  onChangeFile: (id: string) => void;
}

const Container = styled('div')({
  position: 'relative',
  margin: '1rem 0 1rem',
  width: '100%',
});

const CarouselWindow = styled('div')({
  overflow: 'hidden',
  position: 'relative',
  margin: '0 3rem', // Space for navigation buttons
});

const CarouselTrack = styled('div')<{ offset: number }>(({ offset }) => ({
  display: 'flex',
  transition: 'transform 0.3s ease-out',
  transform: `translateX(${offset}%)`,
}));

const CarouselPage = styled('div')({
  display: 'flex',
  gap: '1rem',
  flex: '0 0 100%', // Each page takes exactly 100% width
});

const Wrapper = styled('div')({
  flex: '1 0 calc((100% - 2rem) / 3)', // Evenly distribute space between 3 items
  aspectRatio: '3/2',
  cursor: 'pointer',
  border: '1px solid #000',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 'calc((100% - 2rem) / 3)', // Prevent items from growing too large
});

const ImageContainer = styled('div')({
  flex: 1,
  overflow: 'hidden',
  position: 'relative',
});

const Title = styled('div')({
  fontSize: '1.125rem',
  padding: '0.5rem',
  borderTop: '1px solid #000',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const NavigationButton = styled('button')<{ direction: 'left' | 'right' }>(({ direction }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  [direction]: 0,
  width: '2.5rem',
  height: '2.5rem',
  background: '#fff',
  border: '1px solid #000',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 1,
  '&:disabled': {
    opacity: 0.5,
    cursor: 'default',
  },
  '&:hover:not(:disabled)': {
    background: '#F6F4ED',
  },
}));

export const Preview: FC<Props> = ({ files, filesTitle, onChangeFile }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerView = 3;
  
  // Calculate total number of full pages (groups of 3)
  const totalPages = Math.ceil(files.length / itemsPerView) - 1;
  
  const handlePrev = () => {
    setCurrentIndex(prev => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex(prev => Math.min(totalPages, prev + 1));
  };

  // Calculate offset in percentage (each page is 100%)
  const offset = -(currentIndex * 100);

  return (
    <Container>
      <NavigationButton 
        direction="left"
        onClick={handlePrev}
        disabled={currentIndex === 0}
      >
        <ChevronLeft />
      </NavigationButton>

      <CarouselWindow>
        <CarouselTrack offset={offset}>
          {/* Wrap items in groups of 3 */}
          {Array(Math.ceil(files.length / itemsPerView))
            .fill(0)
            .map((_, pageIndex) => (
              <CarouselPage key={pageIndex}>
                {files
                  .slice(pageIndex * itemsPerView, (pageIndex + 1) * itemsPerView)
                  .map((el) => (
                    <Wrapper key={el?.file} onClick={() => onChangeFile(el?.file)}>
                      <ImageContainer>
                        <ImgVideo 
                          src={getAssetBackendUrl(el?.file, 900)} 
                          naturalAspectRatio 
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            position: 'absolute',
                          }}
                        />
                      </ImageContainer>
                      <Title>
                        {filesTitle[el?.file]}
                      </Title>
                    </Wrapper>
                  ))}
              </CarouselPage>
            ))}
        </CarouselTrack>
      </CarouselWindow>

      <NavigationButton 
        direction="right"
        onClick={handleNext}
        disabled={currentIndex >= totalPages}
      >
        <ChevronRight />
      </NavigationButton>
    </Container>
  );
};
