import React, { useEffect, useState } from 'react';
import useDirectus from '@/hooks/directus/useDirectus';

const Posts = () => {
  const { list, loading, error } = useDirectus();
  const [posts, setPosts] = useState<{ id: number; name: string }[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const data = await list('Yarn', {
          fields: ['name'],
          filter: {
            status: 'published',
          },
        });
        setPosts(data.data); // Directus returns data in a nested 'data' field
      } catch (err) {
        console.error('Failed to fetch posts:', err);
      }
    })().catch((err) => console.error('Unhandled promise rejection:', err));
  }, [list]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h1>Posts</h1>
      <ul>
        {posts.map((post) => (
          <li key={post?.id}>{post?.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default Posts;