import React from 'react';
import { 
  Container, 
  DiagramImage, 
  CollapseButton,
  HighlightBar,
  NavigationOverlay
} from './styles';
import { Close } from '@mui/icons-material';

interface FullViewProps {
  imageUrl: string;
  currentRow: number;
  onRowChange: (row: number) => void;
  onCollapse: () => void;
  totalRows: number;
}

export const FullView: React.FC<FullViewProps> = ({
  imageUrl,
  currentRow,
  onRowChange,
  onCollapse,
  totalRows
}) => {
  const handleNavigationClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // Prevent container click
    
    const rect = event.currentTarget.getBoundingClientRect();
    const y = event.clientY - rect.top;
    const height = rect.height;
    const clickedRow = totalRows - 1 - Math.floor((y / height) * totalRows);
    onRowChange(clickedRow);
  };

  const handleDoubleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onCollapse();
  };

  return (
    <Container onClick={onCollapse}>
      <CollapseButton onClick={onCollapse}>
        <Close />
      </CollapseButton>
      
      <NavigationOverlay 
        onClick={handleNavigationClick}
        onDoubleClick={handleDoubleClick}
      >
        <DiagramImage 
          src={imageUrl} 
          alt="Knitting diagram full view"
        />
        <HighlightBar 
          style={{ 
            top: `${((totalRows - 1 - currentRow) * 100) / totalRows}%`,
            height: `${100 / totalRows}%` 
          }} 
        />
      </NavigationOverlay>
    </Container>
  );
};