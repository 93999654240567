import React, { FC, useState, ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import RadioGroup from '@mui/material/RadioGroup';
import { Collections } from '@/common/interfaces/collection.interface';
import { PageContainer } from '@/components/page-container/page-container';
import Tabs from '@mui/material/Tabs';
import { Button } from '@/components/button/button';
import { RadioInput, CheckboxInput } from '@/components/radio-checkbox-input/radio-checkbox-input';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { getResourceCategorizationDataSelector } from '@/store/selectors/resource.selector';
import { setResourceCategorizationData, setCurrentStep } from '@/store/reducers/resource.reducer';
import { getSelectedLabels } from '../../utils';
import { ResourceSteps } from '@/store/types/resource';
import { Tab, TabContent } from './components/tab';
import { Keywords } from '@/pages/patternizer-page/components/categorization/components/keywords';
import { debounce } from '@/utils/debounce';
import { setKeywordsAction } from '@/store/reducers/resource.reducer';

export interface CategorizationTabProps {}

const PageContainerEl = styled(PageContainer)({
  paddingTop: 0,
});

const TabsContainer = styled('div')({
  position: 'relative',
  borderBottom: '1px solid #000',
  margin: '0 -2rem 2rem',
});

const TabPanelContainer = styled('div')({
  overflow: 'scroll',
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: '50%',
});

const TabPanel = styled('div')({
  padding: '.5rem 0 0 .5rem',
});

type ResourceCollection = keyof Pick<
  typeof Collections,
  Collections.ResourceContentType | Collections.ResourceType
>;

const tabsList = [
  Collections.Craft,
  Collections.ResourceContentType,
  Collections.ResourceType,
];

const collectionItemsList: ResourceCollection[] = [
  Collections.ResourceContentType,
  Collections.ResourceType,
];

export const CategorizationTab: FC<CategorizationTabProps> = ({}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const collectionData = useSelector(getCollectionDataSelector);
  const categorizationData = useSelector(getResourceCategorizationDataSelector);
  const [currentTab, setCurrentTab] = useState<Collections | "Keywords">(Collections.Craft);
  const { Craft } = collectionData;

  const onChangeHandler = ({
    target: { type, value, name, checked },
  }: ChangeEvent<HTMLInputElement>) => {
    const val = +value;
    let valueToSet: number | number[] = val;
    if (type === 'checkbox') {
      const collectionData: number[] = categorizationData[name as ResourceCollection];
      valueToSet = checked ? [...collectionData, val] : collectionData.filter((id) => id !== val);
    }

    dispatch(
      setResourceCategorizationData({
        collection: name as Collections,
        value: valueToSet,
      }),
    );
  };

  const saveKeywords = useMemo(() => debounce({ delay: 500 }, (id, keywords) => {
    dispatch(setKeywordsAction(keywords));
  }), []);

  const onNextHandler = () => {
    dispatch(setCurrentStep(ResourceSteps.COMPLETE));
  };

  return (
    <PageContainerEl>
      <TabsContainer>
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={currentTab}
          onChange={(e, value) => setCurrentTab(value)}
          sx={{ borderRight: 1, borderColor: '#000', width: '50%' }}
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          {tabsList.map((collection) => (
            <Tab
              label={
                <TabContent
                  value={t(`resourceUpload.${collection}`)}
                  descr={getSelectedLabels(
                    // @ts-ignore
                    collectionData[collection],
                    // @ts-ignore
                    categorizationData[collection],
                  )}
                />
              }
              value={collection}
              key={collection}
            />
          ))}
          <Tab
            label={
              <TabContent
                value={t('resourceUpload.keywords')}
                descr={''}
              />
            }
            value={"Keywords"}
            key={"Keywords"}
          />
        </Tabs>
        <TabPanelContainer>
          <TabPanel hidden={Collections.Craft !== currentTab}>
            <RadioGroup
              onChange={onChangeHandler}
              name={Collections.Craft}
              value={categorizationData[Collections.Craft]}
              style={{ display: 'block' }}
            >
              {Craft.map(({ name, id }) => (
                <RadioInput label={name} key={id} value={id} />
              ))}
            </RadioGroup>
          </TabPanel>
          {collectionItemsList.map((collection) => (
            <TabPanel key={collection} hidden={collection !== currentTab}>
              {collectionData[collection].map(({ name, id }) => {
                const checked = categorizationData[collection].includes(id);
                return (
                  <CheckboxInput
                    label={name}
                    key={id}
                    checked={checked}
                    inputProps={{
                      value: id,
                      onChange: onChangeHandler,
                      checked,
                      name: collection,
                    }}
                  />
                );
              })}
            </TabPanel>
          ))}
          <TabPanel hidden={"Keywords" !== currentTab}>
            <Keywords descr={t('resourceUpload.keywordsHelptext')} save={saveKeywords} />
          </TabPanel>
        </TabPanelContainer>
      </TabsContainer>
      <Button onClick={onNextHandler} colored>
        {t('common.next')}
      </Button>
    </PageContainerEl>
  );
};
