import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import {
  getYarniverseDataSelector,
  getYarniverseLoadingSelector,
} from '@/store/selectors/yarniverse.selector';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setYarnDataAction } from '@/store/reducers/yarniverse.reducer';

import { InputButtonSelect } from '@/components/input-button-select';
import { Grid } from '@mui/material';

export const Textures = () => {
  const dispatch = useDispatch();

  const yarn = useSelector(getYarniverseDataSelector);
  const isLoading = useSelector(getYarniverseLoadingSelector);
  const yarnTextures = yarn?.texture;

  const { Texture } = useSelector(getCollectionDataSelector);
  const TEXTURE = useMemo(() => {
    return Texture.map((el) => ({ label: el.name, value: el.id }));
  }, [Texture]);

  const [selectedTexturesState, setSelectedTexturesState] = useState<Array<number>>(
    yarnTextures?.map((texture) => texture) || [],
  );

  const handleChange = (value: number) => {
    if (!yarn.id || isLoading) return;
    const yarnHasTexture = selectedTexturesState.find((x) => x === value) !== undefined;
    const updatedTextures = yarnHasTexture
      ? selectedTexturesState.filter((x) => x !== value)
      : [...selectedTexturesState, value];

    setSelectedTexturesState(updatedTextures);
  };

  useEffect(() => {
    dispatch(setYarnDataAction({ ...yarn, texture: selectedTexturesState }));
  }, [selectedTexturesState, dispatch]);
  return (
    <Grid container>
      {TEXTURE.map((texture) => (
        <InputButtonSelect
          key={texture.value + texture.label}
          label={texture.label}
          value={texture.value}
          checked={selectedTexturesState.includes(texture.value)}
          onChange={handleChange}
        />
      ))}
    </Grid>
  );
};
