import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

const ButtonEl = styled(MuiButton)({
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '0.25rem 0',
    borderRadius: '10px',
    background: '#eee',
    color: '#211E21',
    textTransform: 'none',
    flexShrink: 0,
    '&:hover': {
      backgroundColor: '#CEFF1A',
    },
    '&.button--selected': {
      backgroundColor: '#F6F4ED',
    },
});

interface SmallButtonProps extends MuiButtonProps {
    children: React.ReactNode;
}

export const SmallButton: FC<SmallButtonProps> = ({
    children,
}) => {
    return (
        <ButtonEl>
            {children}
        </ButtonEl>
    );
};
