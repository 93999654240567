import React from 'react';
import styled from '@emotion/styled';
import { H2 } from '../../components/heading';
import { Card } from '../../components/card';

const Section = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 2.5rem;
`;

const Tab = styled.p`
    font-size: 22px;
    line-height: 27px;
    font-weight: 600;
    text-decoration: underline;
    margin: 1rem 0;
    cursor: pointer;
`;

const FlexStart = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
`;

const SplitView = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
`;

export const Marketplace = () => {
    return (
        <Section>
            <H2>Marketplace</H2>
            <SplitView>
                <Tab>Yarn</Tab>
                <Tab>Patterns</Tab>
                <Tab>Inspiration</Tab>
            </SplitView>
            <FlexStart>
                <Card text='Rauma garn' quantity={36} url={'https://via.placeholder.com/336x336'} />
                <Card text='Sadnes Garn' quantity={60} url={'https://via.placeholder.com/336x336'} />
                <Card text='Permin' quantity={60} url={'https://via.placeholder.com/336x336'} />
            </FlexStart>
        </Section>
    );
}