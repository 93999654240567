import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import {
  getYarniverseDataSelector,
  getYarniverseGaugesSelector,
} from '@/store/selectors/yarniverse.selector';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createYarnGaugeAction, removeYarnGaugeAction } from '@/store/reducers/yarniverse.reducer';

import { InputButtonSelect } from '@/components/input-button-select';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Gauge = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { Gauge } = useSelector(getCollectionDataSelector);
  const { id: yarnId } = useSelector(getYarniverseDataSelector);
  const yarnGauges = useSelector(getYarniverseGaugesSelector);
  const GAUGES = Gauge.map((x) => {
    return { label: x.thickness, value: x.id };
  });

  const [selectedGaugesState, setSelectedGaugesState] = useState<Array<number>>(
    yarnGauges.map((gauge) => gauge.id) || [],
  );

  const handleChange = (value: number) => {
    if (!yarnId) return;
    const yarnHasGauge = selectedGaugesState.find((x) => x === value) !== undefined;
    const updatedGauges = yarnHasGauge
      ? selectedGaugesState.filter((x) => x !== value)
      : [...selectedGaugesState, value];

    setSelectedGaugesState(updatedGauges);
    if (yarnHasGauge) {
      const junctionId = yarnGauges.find((x) => x.id === value)?.junctionId;
      if (!junctionId) return;
      dispatch(
        removeYarnGaugeAction({
          yarnId: yarnId,
          junctionId,
        }),
      );
    } else {
      dispatch(
        createYarnGaugeAction({
          yarnId: yarnId,
          gaugeId: value,
        }),
      );
    }
  };
  return (
    <>
      <div style={{ paddingLeft: '10px' }}>{t('patternizerSteps.gaugeDescription')}</div>
      <Grid container>
        {GAUGES.map((gauge) => (
          <InputButtonSelect
            key={gauge.value + gauge.label}
            label={gauge.label}
            value={gauge.value}
            checked={selectedGaugesState.includes(gauge.value)}
            onChange={handleChange}
          />
        ))}
      </Grid>
    </>
  );
};
