import { useEffect, useState } from 'react';

/**
 * A custom React hook that provides delayed input handling.
 * @param {string} initialValue - The initial input value.
 * @param {function} handlerAfterDelay - A function to be executed after the input value has remained unchanged for a specified delay.
 * @param {number} delay - The delay in milliseconds (default is 1000ms).
 * @returns {[string, function]} A tuple containing the current input value and a function to update the input value.
 */
export const useInputWithDelay = (
  initialValue: string,
  handlerAfterDelay: (value: string) => void,
  delay = 1000,
): [string, (value: string) => void] => {
  const [value, setValue] = useState(initialValue);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [isInit, setIsInit] = useState(false);

  /**
   * Handles changes to the input value, triggering the provided handler after the specified delay.
   * @param {string} value - The new input value.
   */
  const handleSearchValue = (value: string) => {
    setValue(value);
  };

  useEffect(() => {
    if (value === undefined) return;
    if (value === initialValue || !isInit) {
      setIsInit(true);
    } else {
      clearTimeout(timer as NodeJS.Timeout);
      setTimeout(() =>
        setTimer(
          setTimeout(() => {
            handlerAfterDelay(value);
          }, delay),
        ),
      );
    }
  }, [value]);

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return [value, handleSearchValue];
};
