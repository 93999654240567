import React from "react";
import styled from "@emotion/styled";

const PopupContainer = styled.div`
    width: 210px;
    padding: 10px;
`;

const PopupHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const PopupTitle = styled.h3`
    margin: 0 !important;
    font-size: 18px;
    font-weight: 600;
`;

const PopupContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const StoreAddress = styled.p`
    margin: 0 !important;
    font-size: 18px;
    font-weight: 400;
`;

const Phone = styled.p`
    margin: 15px 0 0 !important;
    font-size: 18px;
    font-weight: 400;
`;

const Email = styled.p`
    margin: 0 !important;
    font-size: 18px;
    font-weight: 400;
`;

const Street = styled.span`
    display: block;
`;

const PostalCode = styled.span`
    display: inline-block;
`;

const City = styled.span`
    margin-left: 5px;
    display: inline-block;
`;

type PopupViewProps = {
    storeName: string;
    street: string;
    postalCode: string;
    city: string;
    phone: string;
    email: string;
}

const PopupView = ({
    storeName,
    street,
    postalCode,
    city,
    phone,
    email
}: PopupViewProps) => {
    return (
        <PopupContainer>
            <PopupHeader>
                <PopupTitle>{storeName}</PopupTitle>
            </PopupHeader>
            <PopupContent>
                <StoreAddress>
                    <Street>{street}</Street>
                    <PostalCode>{postalCode}</PostalCode>
                    <City>{city}</City>
                </StoreAddress>
                <Phone>
                    Phone:
                    <a href="tel:" target="_blank" rel="noreferrer">{phone}</a>
                </Phone>
                <Email>
                    Email:
                    <a href="mailto:" target="_blank" rel="noreferrer">{email}</a>
                </Email>
            </PopupContent>
        </PopupContainer>
    );
}

export default PopupView;