import React from 'react';
import { MobileNavigation, MobileNavItem } from '../../styles';
import { Menu, Description, Collections, Settings } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface Props {
  activeView: 'steps' | 'instructions' | 'resources' | 'settings';
  onViewChange: (view: 'steps' | 'instructions' | 'resources' | 'settings') => void;
}

export const MobileNav: React.FC<Props> = ({ activeView, onViewChange }) => {
  const { t } = useTranslation();

  return (
    <MobileNavigation>
      <MobileNavItem 
        onClick={() => onViewChange('steps')}
        active={activeView === 'steps'}
      >
        <Menu />
        {t('knitmode.steps')}
      </MobileNavItem>
      <MobileNavItem 
        onClick={() => onViewChange('instructions')}
        active={activeView === 'instructions'}
      >
        <Description />
        {t('knitmode.instructions')}
      </MobileNavItem>
      <MobileNavItem 
        onClick={() => onViewChange('resources')}
        active={activeView === 'resources'}
      >
        <Collections />
        {t('knitmode.resources')}
      </MobileNavItem>
      <MobileNavItem 
        onClick={() => onViewChange('settings')}
        active={activeView === 'settings'}
      >
        <Settings />
        {t('knitmode.settings')}
      </MobileNavItem>
    </MobileNavigation>
  );
};
