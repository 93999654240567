import { RootState } from '..';

export const getYarniverseCurrentStepSelector = (state: RootState) => state.yarniverse.currentStep;

export const getYarniverseDataSelector = (state: RootState) => state.yarniverse.data;

export const getYarniverseGaugesSelector = (state: RootState) => state.yarniverse.gauges;

export const getYarniverseNeedleSizesSelector = (state: RootState) => state.yarniverse.needleSizes;

export const getYarniverseWashAndCareSelector = (state: RootState) => state.yarniverse.washAndCare;

export const getYarniverseColorsSelector = (state: RootState) => state.yarniverse.colors;

export const getYarniverseLoadingSelector = (state: RootState) => state.yarniverse.isLoading;

export const getYarnPricesSelector = (state: RootState) => state.yarniverse.prices;
