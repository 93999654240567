import React, { lazy, Suspense } from 'react';
import { LazyFallback } from '@/components/lazy-fallback/lazy-fallback';

const StorePage = lazy(() => import('./store-page'));

export const StorePageLazy = () => (
  <Suspense fallback={<LazyFallback />}>
    <StorePage />
  </Suspense>
);
