import React, { useState } from 'react';
import { Container } from './styles';
import { FocusedView } from './components/focused-view';
import { FullView } from './components/full-view';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';

interface DiagramViewerProps {
  imageUrl: string;
  name: string;
  initialRow?: number;
}

export const DiagramViewer: React.FC<DiagramViewerProps> = ({
  imageUrl,
  name,
  initialRow = 0
}) => {
  const [isFocusedExpanded, setIsFocusedExpanded] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentRow, setCurrentRow] = useState(initialRow);
  const [totalRows, setTotalRows] = useState(53); // Temporary: will come from diagram detection
  
  const handleRowChange = (newRow: number) => {
    const boundedRow = Math.max(0, Math.min(newRow, totalRows - 1));
    setCurrentRow(boundedRow);
  };

  const toggleView = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleFocusedExpansion = () => {
    setIsFocusedExpanded(!isFocusedExpanded);
  };

  const processedImageUrl = getAssetBackendUrl(imageUrl) || '';

  return (
    <Container>
      <FocusedView
        imageUrl={processedImageUrl}
        currentRow={currentRow}
        onRowChange={handleRowChange}
        onExpand={toggleView}
        onFocusedExpand={toggleFocusedExpansion}
        isExpanded={isFocusedExpanded}
        totalRows={totalRows}
        isHidden={isExpanded}
        isFocusedExpanded={isFocusedExpanded}
      />
      {/* Only render FullView after first expansion */}
      {isExpanded && (
        <FullView
          imageUrl={processedImageUrl}
          currentRow={currentRow}
          onRowChange={handleRowChange}
          onCollapse={toggleView}
          totalRows={totalRows}
        />
      )}
    </Container>
  );
};
