import React, { lazy, Suspense } from 'react';
import { LazyFallback } from '@/components/lazy-fallback/lazy-fallback';

const InventoryPage = lazy(() => import('./inventory-page'));

export const InventoryPageLazy = () => (
  <Suspense fallback={<LazyFallback />}>
    <InventoryPage />
  </Suspense>
);
