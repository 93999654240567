import { Pattern } from '@/common/interfaces/pattern.interface';
import { PatternImageCollectionItem } from '@/common/interfaces/collection.interface';

export interface PublishCondition {
  isValid: boolean;
  message: string;
}

export const getPatternPublishConditions = (
  pattern: Pattern | {
    id: number;
    img: string;
    label: string;
    status: string;
    price?: number;
    designerName: string;
    designerId: string;
  }, 
  images: PatternImageCollectionItem[] | string,
  t: (key: string) => string
): PublishCondition[] => {

  const hasImages = Array.isArray(images) ? images.length > 0 : !!images;
  
  return [
    {
      isValid: hasImages,
      message: `• ${t('patternizerSteps.advertisement.noImage')}`
    },
    {
      isValid: !!pattern.price && pattern.price > 0,
      message: `• ${t('patternizerSteps.advertisement.priceRequired')}`
    }
    // Add more conditions here
  ];
};

export const canPublishPattern = (conditions: PublishCondition[]): boolean => {
  return conditions.every(condition => condition.isValid);
};

export const getPublishBlockerMessage = (conditions: PublishCondition[]): string => {
  return conditions
    .filter(condition => !condition.isValid)
    .map(condition => condition.message)
    .join('\n');
};