import { FC } from 'react';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledButton } from '../../../next-button';

interface PublishButtonProps {
  isPublished: boolean;
  canPublish: boolean;
  publishErrorMessage: string;
  onPublish: () => void;
  onUnpublish: () => void;
}

export const PublishButton: FC<PublishButtonProps> = ({
  isPublished,
  canPublish,
  publishErrorMessage,
  onPublish,
  onUnpublish,
}) => {
  const { t } = useTranslation();

  if (isPublished) {
    return (
      <StyledButton 
        colored 
        onClick={onUnpublish}
      >
        {t('patternizerSteps.advertisement.unpublish')}
      </StyledButton>
    );
  }

  return (
    <Tooltip 
      title={publishErrorMessage}
      componentsProps={{
        tooltip: {
          sx: {
            whiteSpace: 'pre-line'
          }
        }
      }}
    >
      <span>
        <StyledButton 
          disabled={!canPublish} 
          colored 
          onClick={onPublish}
        >
          {t('patternizerSteps.advertisement.publish')}
        </StyledButton>
      </span>
    </Tooltip>
  );
};