import { FC, memo, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { Box, Tabs } from '@mui/material';
import {
  createPatternStepsAction,
  getPatternStepsAction,
  updatePatternStepsOrderAction,
} from '@/store/reducers/pattern.reducer';
import {
  getPatternAvailableSizesSelector, 
  getPatternStepsSelector, 
} from '@/store/selectors/pattern.selector';
import { PatternStepWithStage } from '@/store/types/pattern';
import { AddStep } from './components/add-step/add-step';
import { DraggableTab } from './components/draggable-tab/draggable-tab';
import { RelevantSizes } from './components/relevant-sizes/relevant-sizes';
import { TabsEl, StepsContainer, PatternStepsTab, OutterBox } from './styles';
import { t } from 'i18next';

export interface PatternStepsProps {
  patternId: number;
  onStepChange: (stepId: number) => void;
  currentStepId: number | null;
  setCurrentStepId: (stepId: number | null) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>);
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const PatternSteps: FC<PatternStepsProps> = memo(({ onStepChange, patternId, currentStepId, setCurrentStepId }) => {
  const dispatch = useDispatch();
  const [dndSteps, setDndSteps] = useState<PatternStepWithStage[]>([]);
  const steps = useSelector(getPatternStepsSelector);
  const sizes = useSelector(getPatternAvailableSizesSelector);
  const [value, setValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!steps?.length) {
      return;
    }
    setDndSteps(steps);
  }, [steps]);

    // set initial step id
    useEffect(() => {
      if (!steps?.length) {
        return;
      }
      const stepExists = !!steps.find(({ id }) => currentStepId === id);
      setCurrentStepId(stepExists ? currentStepId : steps[0].id);
    }, [steps]);
  
    useEffect(() => {
      if (currentStepId) {
        onStepChange(currentStepId);
      }
    }, [currentStepId]);

  const handleStepChange = (_: SyntheticEvent, value: string) => {
    onStepChange(+value);
  };

  const getUpdatedPatternSteps = () => {
    dispatch(getPatternStepsAction(patternId));
  };

  const addStepHandler = () => {
    dispatch(
      createPatternStepsAction({
        pattern: { id: patternId },
        sort: steps?.length,
        relevant_sizes: sizes,
        callback: (newStepId) => {
          getUpdatedPatternSteps();
          onStepChange(newStepId);
        }
      }),
    );
  };

  const onStepOrderChangeHandler = (steps: PatternStepWithStage[]) => {
    const stepsPayload = steps.map(({ id }, sort) => ({ id, sort }));
    dispatch(
      updatePatternStepsOrderAction({ steps: stepsPayload, callback: getUpdatedPatternSteps }),
    );
  };

  const onStepDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;

    const updatedDndSteps = [...dndSteps];
    const draggedTab = updatedDndSteps.splice(source.index, 1)[0];
    updatedDndSteps.splice(destination.index, 0, draggedTab);
    setDndSteps(updatedDndSteps);
    onStepOrderChangeHandler(updatedDndSteps);
  };

  return (
    <OutterBox>
      <Box>
        <Tabs 
          value={value} 
          onChange={handleChange} 
          aria-label={t('patternView.stepLabel')} 
          TabIndicatorProps={{ style: { backgroundColor: '#000' } }}>
          <PatternStepsTab label={t('patternView.steps')} {...a11yProps(0)}></PatternStepsTab>
          <PatternStepsTab label={t('patternView.edit')} {...a11yProps(1)}></PatternStepsTab>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <StepsContainer>
          {steps?.length !== 0 && (        
            <DragDropContext onDragEnd={onStepDragEnd}>
              <div style={{ display: 'flex', overflow: 'auto', flexDirection: 'column' }}>
                <Droppable droppableId='patternSteps' direction='vertical'>
                  {({ droppableProps, innerRef, placeholder }) => (
                    <div ref={innerRef} {...droppableProps}>
                      <TabsEl
                        onChange={handleStepChange}
                        variant='scrollable'
                        scrollButtons={false}
                        value={currentStepId}
                      >
                        {dndSteps.map(({ id, name }, index) => {
                          return (
                            <DraggableTab
                              value={id}
                              index={index}
                              key={index}
                              label={name ? name : t('patternView.untitled')}
                            />
                          );
                        })}
                        {placeholder}
                      </TabsEl>
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
          )}
          <AddStep onAdd={addStepHandler} />
        </StepsContainer>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {currentStepId !== null && <RelevantSizes currentStepId={currentStepId} />}
      </CustomTabPanel>
    </OutterBox>
  );
});
