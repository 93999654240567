import { DirectusFields } from '@/common/constants/directus-fields';
import { Errors } from '@/common/constants/errors';
import { ProductStatus } from '@/common/constants/product-status';
import { Collections } from '@/common/interfaces/collection.interface';
import { ItemSearchType } from '@/common/interfaces/search.interface';
import { TransportResponse } from '@directus/sdk';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import {
  searchItemsAction,
  searchItemsErrorAction,
  searchItemsSuccessAction,
} from '../reducers/search.reducer';
import { displayErrorAction } from '../reducers/system.reducer';
import {
  DesignerSearchItem,
  PatternSearchItem,
  ResourceSearchItem,
  YarnSearchItem,
} from '../types/search';

function* searchItemsRequest(api: ApiType, action: ReturnType<typeof searchItemsAction>) {
  try {
    const { itemType, value, additional } = action.payload;

    // temporary handling of input from search field, to not break the
    // existing filter behavior below
    if (itemType === null && value && additional && additional.Craft === undefined) {
      let patterns: PatternSearchItem[] = [];
      let yarns: YarnSearchItem[] = [];
      const resources: ResourceSearchItem[] = [];
      let designers: DesignerSearchItem[] = [];

      const response: TransportResponse<PatternSearchItem[]> = yield call(
        api.getCollection,
        Collections.Patterns,
        {
          fields: DirectusFields.PatternSearch,
          search: value,
        },
      );
      if (!response.data) throw new Error(Errors.Default);
      patterns = response.data;

      const yarnResponse: TransportResponse<YarnSearchItem[]> = yield call(
        api.getCollection,
        Collections.Yarn,
        {
          fields: DirectusFields.YarnSearch,
          search: value,
        },
      );

      if (!yarnResponse.data) throw new Error(Errors.Default);
      yarns = yarnResponse.data;

      const designersResponse: TransportResponse<DesignerSearchItem[]> = yield call(
        api.getCollection,
        Collections.Users,
        {
          filter: {
            role: {
              _eq: '276058da-5b62-4719-9877-328a41361778',
            },
          },
          search: value,
        },
      );
      if (!designersResponse.data) throw new Error(Errors.Default);
      designers = designersResponse.data;

      yield put(searchItemsSuccessAction({ patterns, yarns, resources, designers }));
      return;
    }

    const filter: {
      [key: string]: {
        [key: string]: number | number[] | string;
      };
    } = {};

    if (value) {
      filter['name'] = {
        _contains: value,
      };
    }

    if (additional?.Craft) {
      switch (itemType) {
        case ItemSearchType.Pattern:
          filter['craft'] = {
            _eq: additional.Craft,
          };
          break;
        case ItemSearchType.Resource:
          filter['Craft'] = {
            _eq: additional.Craft,
          };
          break;
      }
    }
    if (additional?.Projects?.length) {
      filter['type_of_project'] = {
        _in: additional.Projects,
      };
    }
    if (additional?.Gauge?.length) {
      switch (itemType) {
        case ItemSearchType.Pattern:
          filter['masketett'] = {
            _in: additional.Gauge,
          };
          break;
        case ItemSearchType.Yarn:
          filter['gauge'] = {
            _in: additional.Gauge,
          };
      }
    }

    if (additional?.Suitable?.length) {
      filter['passer_til'] = {
        _in: additional.Suitable,
      };
    }

    if (additional?.FiberType?.length) {
      const r: TransportResponse<{ id: number }[]> = yield call(
        api.getCollection,
        Collections.Fiber,
        {
          filter: {
            type: {
              _in: additional.FiberType,
            },
          },
          fields: ['id'],
        },
      );

      if (r.data?.length) {
        filter['fiber'] = {
          _in: r?.data.map((el) => el.id),
        };
      }
    }

    if (additional?.YarnManufacture?.length) {
      filter['yarn_manufacturer'] = {
        _in: additional?.YarnManufacture,
      };
    }

    if (additional?.NeedleSize?.length) {
      filter['needle_size'] = {
        _in: additional?.NeedleSize,
      };
    }

    if (additional?.AvailableCountry?.length) {
      filter['available_country'] = {
        _in: additional?.AvailableCountry,
      };
    }

    if (additional?.CountryOfOrigin?.length) {
      filter['country_of_origin'] = {
        _in: additional?.CountryOfOrigin,
      };
    }

    if (additional?.ResourceType?.length) {
      filter['ResourceType'] = {
        _in: additional?.ResourceType,
      };
    }

    if (additional?.ResourceContentType?.length) {
      filter['ResourceContentType'] = {
        _in: additional?.ResourceContentType,
      };
    }

    filter['status'] = {
      _in: ProductStatus.Published,
    };

    let patterns: PatternSearchItem[] = [];
    let yarns: YarnSearchItem[] = [];
    let resources: ResourceSearchItem[] = [];
    let designers: DesignerSearchItem[] = [];

    if (itemType === ItemSearchType.Pattern) {
      const response: TransportResponse<PatternSearchItem[]> = yield call(
        api.getCollection,
        Collections.Patterns,
        {
          filter,
          fields: DirectusFields.PatternSearch,
        },
      );

      if (!response.data) throw new Error(Errors.Default);
      patterns = response.data;
    }

    if (itemType === ItemSearchType.Yarn) {
      const response: TransportResponse<YarnSearchItem[]> = yield call(
        api.getCollection,
        Collections.Yarn,
        {
          filter,
          fields: DirectusFields.YarnSearch,
        },
      );

      if (!response.data) throw new Error(Errors.Default);
      yarns = response.data;
    }

    if (itemType === ItemSearchType.Resource) {
      const response: TransportResponse<ResourceSearchItem[]> = yield call(
        api.getCollection,
        Collections.Resources,
        {
          filter,
          fields: DirectusFields.ResourceSearch,
        },
      );

      if (!response.data) throw new Error(Errors.Default);
      resources = response.data;
    }

    if (itemType === ItemSearchType.Designer) {
      console.log('Search for Designer');
      //Filter to serach users by role
      const response: TransportResponse<DesignerSearchItem[]> = yield call(
        api.getCollection,
        Collections.Users,
        {
          filter: {
            role: {
              _eq: '276058da-5b62-4719-9877-328a41361778',
            },
          },
        },
      );
      console.log('response.data', response.data);
      if (!response.data) throw new Error(Errors.Default);
      designers = response.data;
    }

    yield put(searchItemsSuccessAction({ patterns, yarns, resources, designers }));
  } catch (err: any) {
    yield put(searchItemsErrorAction());
    yield put(displayErrorAction(err?.message as string));
  }
}

export const searchSaga = function* (api: ApiType) {
  yield all([takeLatest(searchItemsAction.type, searchItemsRequest, api)]);
};
