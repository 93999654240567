import { YarnColorCollectionItem } from '@/common/interfaces/collection.interface';
import {
  addYarnAlternativeAction,
  addYarnAlternativeItemAction,
  addYarnAlternativeWithItemAction,
  getYarnAlternativeByPatternAction,
  removeAlternativeItemsAction,
  removeYarnAlternativeAction,
  updateAlternativeItemsAction,
  updateYarnMeasurementAction,
  updateYarnMeasurementSizeAction,
} from '@/store/reducers/collection.reducer';
import { displayErrorAction } from '@/store/reducers/system.reducer';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import {
  getPatternAvailableSizesSelector,
  getPatternDataSelector,
} from '@/store/selectors/pattern.selector';
import { getPatternizerSelector } from '@/store/selectors/patternizer.selector';
import { YarnCollectionItemWithStage } from '@/store/types/collection';
import { FC, useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NextButton } from '../next-button';
import { PatternizerStepsKeys } from '../patternizer-steps';
import { Yarnivers } from './components/yarnivers';
import { PageContainer } from '@/components/page-container/page-container';
import { t } from 'i18next';
import { styled, Typography } from '@mui/material';

interface Props {
  onPressNextButton: () => void;
}

export const YarnAlternatives: FC<Props> = (props) => {
  const { onPressNextButton } = props;
  const dispatch = useDispatch();


  const pattern = useSelector(getPatternDataSelector);

  const { YarnAlternative } = useSelector(getCollectionDataSelector);
  const AvailableSizes = useSelector(getPatternAvailableSizesSelector);

  const [selectedAlternative, setSelectedAlternative] = useState<number>();
  const isNewAlternativeAdded = useRef(false);
  const isAlternativeDeleted = useRef(false);

  const { currentStep } = useSelector(getPatternizerSelector);
  
  useEffect(() => {
    if (currentStep === PatternizerStepsKeys.YARN_ALTERNATIVES) {
      dispatch(getYarnAlternativeByPatternAction());
    }
  }, [pattern, currentStep]);

  useEffect(() => {
    if (!YarnAlternative.length) return;
    dispatch(updateYarnMeasurementSizeAction());
    setSelectedAlternative(YarnAlternative[0].id);
  }, [AvailableSizes]);

  const handleChangeName = (name: string, alternativeItem: number) => {
    dispatch(
      updateAlternativeItemsAction({
        name,
        alternativeItem,
      }),
    );
  };

  const handleChangeMeasurement = (id: number, value: string) => {
    dispatch(
      updateYarnMeasurementAction({
        id,
        value,
      }),
    );
  };

  const handleAddAlternative = () => {
    isNewAlternativeAdded.current = true;
    dispatch(addYarnAlternativeAction());
  };

  const handleAddFirstYarn = (
    yarn: YarnCollectionItemWithStage,
    color: YarnColorCollectionItem | null,
  ) => {
    isNewAlternativeAdded.current = true;
    if (!yarn.id) {
      dispatch(displayErrorAction('No alternative added'));
      return;
    }
    dispatch(
      addYarnAlternativeWithItemAction({
        yarnId: yarn.id,
        colorId: color?.id || null,
      }),
    );
  };

  const handleAddYarn = (
    yarn: YarnCollectionItemWithStage,
    color: YarnColorCollectionItem | null,
  ) => {
    if (!yarn.id || !selectedAlternative) {
      dispatch(displayErrorAction('No alternative added'));
      return;
    }
    dispatch(
      addYarnAlternativeItemAction({
        yarnId: yarn.id,
        colorId: color?.id || null,
        alternativeId: YarnAlternative.find((el) => el.id === selectedAlternative)?.id as number,
      }),
    );
  };

  const handleDeleteAlternative = (id: number) => {
    isAlternativeDeleted.current = true;
    dispatch(removeYarnAlternativeAction(id));
  };

  const handleDeleteAlternativeItems = (id: number) => {
    dispatch(removeAlternativeItemsAction(id));
  };

  useEffect(() => {
    if (isNewAlternativeAdded.current && YarnAlternative.length > 0) {
      setSelectedAlternative(YarnAlternative[YarnAlternative.length - 1].id);
      isNewAlternativeAdded.current = false;
    }
  }, [YarnAlternative]);

  useEffect(() => {
    if (isAlternativeDeleted.current && YarnAlternative.length > 0) {
      setSelectedAlternative(YarnAlternative[0].id);
    }
    isAlternativeDeleted.current = false;
  }, [YarnAlternative]);

  const selectedAlternativeMemo = useMemo(() => {
    if (!YarnAlternative || !selectedAlternative) return null;
    return YarnAlternative.find((alternative) => alternative?.id === selectedAlternative);
  }, [selectedAlternative, YarnAlternative]);

  return (
    <>
      <PageContainer borderBottom>
        <Title variant='h2'>{t('patternizerSteps.yarnAlternatives')}</Title>
        <Yarnivers 
          onAddYarn={handleAddYarn}
          onAddFirstYarn={handleAddFirstYarn}
          alternatives={YarnAlternative}
          onAdd={handleAddAlternative}
          setSelectedAlternative={setSelectedAlternative}
          selectedAlternative={selectedAlternative}
          onChangeMeasurement={handleChangeMeasurement}
          onChangeName={handleChangeName}
          onDeleteItem={handleDeleteAlternativeItems}
          onDeleteAlternative={handleDeleteAlternative}
        />
      </PageContainer>
      <NextButton align='right' onPressNext={onPressNextButton} />
    </>
  );
};

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  marginBottom:'4rem',
}));