import { styled } from '@mui/material';
import { Button } from '@/components/button/button';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0rem 1.5rem',
  borderBottom: '1px solid #000',
  gap: '3rem',
  height: '3.5rem',

  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
    height: 'auto',
    padding: '0.5rem 1rem',
    gap: '0.5rem'
  }
}));

export const Item = styled('span')(({ theme }) => ({
  fontSize: '1.125rem',
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('laptop')]: {
    fontSize: '1rem'
  }
}));

export const PatternName = styled('span')<{ mobile?: boolean }>(({ theme, mobile }) => ({
  textTransform: 'uppercase',
  fontSize: mobile ? '1.5rem' : '1.9rem',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  flex: '0 1 auto',

  [theme.breakpoints.down('laptop')]: {
    width: '100%',
    textAlign: 'center'
  }
}));

export const LogoImage = styled('img')<{ mobile?: boolean }>(({ mobile }) => ({
  width: mobile ? '6rem' : '10.5rem',
  cursor: 'pointer',
  flexShrink: 0,
  '&:hover': {
    opacity: 0.8,
  },
}));

export const ZoomContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  fontSize: '1.125rem',
  flexShrink: 0,
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('laptop')]: {
    width: '100%',
    justifyContent: 'center'
  }
}));

export const MobileControls = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1rem',
  padding: '0.5rem 0'
});

export const ExitPreviewButton = styled(Button)<{ mobile?: boolean }>(({ theme, mobile }) => ({
  marginLeft: 'auto',
  padding: '0.5rem 1.5rem',
  fontSize: '1.125rem',
  flexShrink: 0,

  ...(mobile && {
    padding: '0.25rem 1rem',
    fontSize: '1rem',
    height: '2rem',
  }),

  [theme.breakpoints.down('laptop')]: {
    width: mobile ? 'auto' : '100%',
    marginTop: mobile ? 0 : '0.5rem'
  }
}));

export const TopRow = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
});

