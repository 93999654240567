import { LeftMenu } from '@/components/horizontal-tabs/components/left-menu';
import { FC, ReactNode, useEffect, useRef } from 'react';
import { Container, ContentCol, MenuCol, TopContainer } from './styles';

interface TabsProps {
  title?: ReactNode;
  description?: ReactNode;
  menuItems: { id: string; label: string; description?: string }[];
  selectedId: string;
  onSelect: (item: { id: string; label: string }) => void;
  children: ReactNode;
}

export const CategorizationTabs: FC<TabsProps> = ({
  menuItems,
  selectedId,
  onSelect,
  description,
  title,
  children,
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null);

  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const adjustHeights = () => {
      if (!menuRef.current?.offsetHeight || !contentRef.current?.offsetHeight) return;

      // Only apply for desktop viewport (1024px and up)
      if (window.innerWidth >= 1024) {
        const maxHeight = Math.min(menuRef.current.offsetHeight, contentRef.current.offsetHeight);
        menuRef.current.style.maxHeight = `${maxHeight}px`;
        contentRef.current.style.maxHeight = `${maxHeight - 0.5}px`;
      } else {
        // Reset styles for mobile
        menuRef.current.style.maxHeight = '';
        contentRef.current.style.maxHeight = '';
      }
    };

    // Initial adjustment
    adjustHeights();

    // Add resize listener
    window.addEventListener('resize', adjustHeights);

    // Cleanup
    return () => window.removeEventListener('resize', adjustHeights);
  }, []);

  return (
    <Container container>
      <MenuCol item mobile={12} laptop={6} ref={menuRef}>
        {(title || description) && (
          <TopContainer>
            {title}
            {description}
          </TopContainer>
        )}
        <LeftMenu items={menuItems} selectedId={selectedId} onSelect={onSelect} />
      </MenuCol>
      <ContentCol item mobile={12} laptop={6} ref={contentRef}>
        {children}
      </ContentCol>
    </Container>
  );
};
