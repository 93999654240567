import { FC, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SignedOut } from '@clerk/clerk-react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, IconButton, Drawer, Box } from '@mui/material';

import { getAuthorizationSelector } from '@/store/selectors/authorization.selector';
import { PATH } from '@/common/constants/path';
import Logo from '@/assets/logo.svg';
import { Search } from '../search';
import { HeaderMobile } from './components/header-mobile';
import { UserMenu } from './components/user-menu';
import { SlideInMenu } from './components/slide-in-menu';
import { LanguagePicker } from './components/language-picker';
import { HeaderEl } from './styles';
import { StoreSelector } from '../store-selector';

export interface HeaderProps {}

const StyledToolbar = styled(Toolbar)({
  display: 'grid',
  gridTemplateColumns: '1fr auto 1fr',
  alignItems: 'center',
  width: '100%',
});

const MenuLeft = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  columnGap: '2rem',
  [theme.breakpoints.down('desktop')]: {
    columnGap: '1rem',
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  ...theme.typography.body1,
  margin: 0,
  padding: '1rem',
  marginLeft: '-1rem',
  '&:hover': {
    backgroundColor: 'inherit',
  },
}));

const MenuCenter = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const MenuRight = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  columnGap: '2rem',
  [theme.breakpoints.down('desktop')]: {
    columnGap: '1rem',
  },
}));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  ...theme.typography.body1,
  textDecoration: 'none',
  color: 'inherit',
}));

const StyledNavLinkHiddenOnTablet = styled(StyledNavLink)(({ theme }) => ({
  [theme.breakpoints.down('laptop')]: {
    display: 'none',
  },
}));

export const Header: FC<HeaderProps> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('tablet'));
  const { isLoggedIn } = useSelector(getAuthorizationSelector);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const transparentPaths = [PATH.PATTERNS_START, PATH.YARNIVERSE_START, PATH.NEEDLE_START];

  const [isTransparent, setIsTransparent] = useState(transparentPaths.includes(location.pathname as PATH));
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsTransparent(transparentPaths.includes(location.pathname as PATH));
  }, [location.pathname]);
  
  const handleClose = () => {
    setOpen(false);
  }
  const handleOpen = () => {
    setOpen(true);
  };
  const scrollToTop = () => {
    window.scrollTo({
        top: 0        
    });
}
  const desktopView = (
    <HeaderEl isTransparent={isTransparent && !hasScrolled}>
      <AppBar position="fixed" style={{ backgroundColor: 'transparent', boxShadow: 'none'}}>
        <StyledToolbar>
          <MenuLeft>
            <StyledIconButton 
              edge="start" 
              color="inherit" 
              aria-label={t('header.nav.menu')}
              onClick={handleOpen}
              >
              {t('header.nav.menu')}
            </StyledIconButton>
          </MenuLeft>
          <Drawer open={open} onClose={handleClose} style={{zIndex: 15000}}> 
            <SlideInMenu toggleDrawer={handleClose}/>
          </Drawer>
          <MenuCenter>
            <NavLink to={PATH.HOME}>
              <img src={Logo} alt={t('main.title')}/> 
            </NavLink>
          </MenuCenter>
          
          <MenuRight>
            <StoreSelector />
            <LanguagePicker />
            {!isLoggedIn ? <Authorization /> : <UserMenu />}
          </MenuRight>
        </StyledToolbar>
      </AppBar>
    </HeaderEl>
  );

  return (
    <>
      {isMobileView ? <HeaderMobile /> : desktopView}
      <Search  />
    </>
  );
};

const Authorization = () => {
  const { t } = useTranslation();
  return (
    <>          
      <SignedOut>
        <StyledNavLink to={PATH.SIGNIN}>
          {t('header.nav.signin')}
        </StyledNavLink>     
        <StyledNavLinkHiddenOnTablet to={PATH.SIGNUP}>
          {t('header.nav.createacc')}
        </StyledNavLinkHiddenOnTablet>    
      </SignedOut>
    </>
  );
};
