import { Button } from '@/components/button/button';
import { YarnAlternativeWithStage } from '@/store/types/collection';
import { styled, Typography } from '@mui/material';
import { t } from 'i18next';
import { FC } from 'react';

interface Props {
  yarnAlternative: YarnAlternativeWithStage[];
  selectedYarnAlternative: number | undefined;
  setSelectedYarnAlternative: (id: number) => void;
}

const Container = styled('div')(({ theme }) => ({
  // marginTop: '4rem',
  [theme.breakpoints.down('laptop')]: {
    marginTop: '2rem',
    display: 'flex',
    overflow: 'scroll',
  },
}));

const AlternativeButton = styled(Button)({
  borderRadius: '0',
  minWidth: '11rem',
  fontSize: '1.125rem',
  marginRight: '1rem',
  whiteSpace: 'nowrap',
});

export const Alternative: FC<Props> = ({
  yarnAlternative,
  selectedYarnAlternative,
  setSelectedYarnAlternative
}) => {
  if (!yarnAlternative || !Array.isArray(yarnAlternative) || yarnAlternative.length === 0) {
    return null;
  }

  return (
    <Container>
      <Typography mt='3rem' mb='1rem' variant='h3'>
      {t('patternizerSteps.advertisement.yarnAlternatives')}
      </Typography>
      {yarnAlternative.map((el, i) => (
        <AlternativeButton
          key={el.id}
          style={{ backgroundColor: selectedYarnAlternative === el.id ? '#F6F4ED' : 'transparent' }}
          onClick={() => setSelectedYarnAlternative(el.id)}
        >
          {el.name || `${t('patternizerSteps.alternative')} ${i + 1}`}
        </AlternativeButton>
      ))}
    </Container>
  );
};
