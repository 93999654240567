import { Errors } from '@/common/constants/errors';
import { FileFolders } from '@/common/constants/fileFolders';
import { ProductStatus } from '@/common/constants/product-status';
import {
  Collections,
  FileCollectionItem,
  YarnColorCollectionItem,
  YarnGaugeCollectionItem,
  YarnManufacturerCollectionItem,
  YarnNeedleSizeCollectionItem,
  YarnPriceCollectionItem,
  YarnWashAndCareCollectionItem,
} from '@/common/interfaces/collection.interface';
import { User } from '@/common/interfaces/user.interface';
import { ApiType } from '@/services/api';
import {
  createMultipleYarnColorsAction,
  createMultipleYarnColorsSuccessAction,
  createYarnColorAction,
  createYarnColorSuccessAction,
  getYarnColorsAction,
  getYarnColorsSuccessAction,
  getYarniverseDataAction,
  getYarniverseDataSuccessAction,
  getYarnPricesAction,
  getYarnPricesSuccessAction,
  publishYarnAction,
  publishYarnSuccessAction,
  removeYarnColorAction,
  removeYarnColorImageAction,
  setYarnPriceAction,
  archiveYarnAction,
  archiveYarnErrorAction,
  archiveYarnSuccessAction,
  createYarnGaugeAction,
  removeYarnGaugeAction,
  createYarnGaugeSuccessAction,
  getYarnGaugesAction,
  getYarnGaugesSuccessAction,
  removeYarnGaugeSuccessAction,
  createYarnNeedleSizeAction,
  createYarnNeedleSizeSuccessAction,
  getYarnNeedleSizesAction,
  getYarnNeedleSizesSuccessAction,
  removeYarnNeedleSizeAction,
  removeYarnNeedleSizeSuccessAction,
  setYarnMainColorAction,
  setYarnMainColorSuccessAction,
  createYarnWashAndCareSuccessAction,
  getYarnWashAndCareSuccessAction,
  createYarnWashAndCareAction,
  getYarnWashAndCareAction,
  removeYarnWashAndCareSuccessAction,
  removeYarnWashAndCareAction,
} from '@/store/reducers/yarniverse.reducer';
import { TransportResponse } from '@directus/sdk';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { displayErrorAction } from '../reducers/system.reducer';
import {
  setYarnColorAction,
  setYarnColorSuccessAction,
  setYarnDataAction,
  setYarnDataSuccessAction,
  yarniverseErrorAction,
} from '../reducers/yarniverse.reducer';
import { getCurrentUserSelector } from '../selectors/user.selector';
import { Yarn } from '../types/yarniverse';

function* setYarnDataRequest(api: ApiType, action: ReturnType<typeof setYarnDataAction>) {
  try {
    const data = action.payload;
    const response: Yarn = yield call(
      data.id ? api.updateCollectionItem : api.createCollectionItem,
      Collections.Yarn,
      data,
    );

    if (!response) {
      throw new Error('Something went wrong');
    }

    if (!response.yarn_manufacturer) {
      const user: User = yield select(getCurrentUserSelector);

      const responseManufacturer: TransportResponse<YarnManufacturerCollectionItem[]> = yield call(
        api.getCollection,
        Collections.YarnManufacturer,
        {
          filter: {
            user_created: {
              _eq: user.id,
            },
          },
        },
      );

      let manufactureId = null;

      if (!responseManufacturer.data?.length) {
        const responseNewManufacturer: YarnManufacturerCollectionItem = yield call(
          api.createCollectionItem,
          Collections.YarnManufacturer,
          {
            name: user.username || 'Unknown',
            status: ProductStatus.Published,
          },
        );

        manufactureId = responseNewManufacturer.id;
      } else {
        manufactureId = responseManufacturer.data[0].id;
      }

      yield call(api.updateCollectionItem, Collections.Yarn, {
        id: response.id,
        yarn_manufacturer: manufactureId,
      });
    }

    yield put(setYarnDataSuccessAction(response));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* getYarnColorsRequest(api: ApiType, action: ReturnType<typeof getYarnColorsAction>) {
  try {
    const yarnId = action.payload;

    const response: TransportResponse<YarnColorCollectionItem[]> = yield call(
      // @ts-expect-error - TS is not able to infer the correct type
      api.getCollection,
      Collections.YarnColor,
      {
        filter: {
          yarn: yarnId,
        },
        sort: 'id',
      },
    );
    if (!response.data) {
      throw new Error('Something went wrong');
    }
    yield put(getYarnColorsSuccessAction(response.data));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* getYarnGaugesRequest(api: ApiType, action: ReturnType<typeof getYarnGaugesAction>) {
  try {
    const yarnId = action.payload;
    const response: TransportResponse<YarnGaugeCollectionItem[]> = yield call(
      api.getCollection,
      Collections.YarnGauge,
      {
        filter: {
          Yarn_id: yarnId,
        },
        fields: ['*', 'masketett_id.*'],
      },
    );

    if (!response.data) {
      throw new Error('Something went wrong');
    }
    const result = response.data.map((item) => ({
      ...item.masketett_id,
      junctionId: item.id,
    }));
    yield put(getYarnGaugesSuccessAction(result));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* createYarnGaugeRequest(api: ApiType, action: ReturnType<typeof createYarnGaugeAction>) {
  try {
    const { yarnId, gaugeId } = action.payload;

    const response: Yarn = yield call(api.updateCollectionItem, Collections.Yarn, {
      id: yarnId,
      gauge: { create: [{ Yarn_id: yarnId, masketett_id: { id: gaugeId } }] },
    });

    if (!response) {
      throw new Error('Failed to create yarn gauge');
    }
    yield put(createYarnGaugeSuccessAction(response));
    yield put(getYarnGaugesAction(yarnId));
  } catch (error: any) {
    console.error('Error in saga:', error.message);
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* removeYarnGaugeRequest(api: ApiType, action: ReturnType<typeof removeYarnGaugeAction>) {
  try {
    const { yarnId, junctionId } = action.payload;
    yield call(api.removeCollectionItem, Collections.YarnGauge, junctionId);
    yield put(removeYarnGaugeSuccessAction());
    yield put(getYarnGaugesAction(yarnId));
  } catch (error: any) {
    console.error('Error in saga:', error.message);
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* getYarnWashAndCareRequest(
  api: ApiType,
  action: ReturnType<typeof getYarnWashAndCareAction>,
) {
  try {
    const yarnId = action.payload;
    const response: TransportResponse<YarnWashAndCareCollectionItem[]> = yield call(
      api.getCollection,
      Collections.YarnWashAndCare,
      {
        filter: {
          Yarn_id: yarnId,
        },
        fields: ['*', 'wash_and_care_id.*'],
      },
    );
    if (!response.data) {
      throw new Error('Something went wrong');
    }
    const result = response.data.map((item) => ({
      ...item.wash_and_care_id,
      junctionId: item.id,
    }));
    yield put(getYarnWashAndCareSuccessAction(result));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* createYarnWashAndCareRequest(
  api: ApiType,
  action: ReturnType<typeof createYarnWashAndCareAction>,
) {
  try {
    const { yarnId, washAndCareId } = action.payload;

    const response: Yarn = yield call(api.updateCollectionItem, Collections.Yarn, {
      id: yarnId,
      wash_and_care: { create: [{ Yarn_id: yarnId, wash_and_care_id: { id: washAndCareId } }] },
    });

    if (!response) {
      throw new Error('Failed to create yarn wash and care');
    }
    yield put(createYarnWashAndCareSuccessAction(response));
    yield put(getYarnWashAndCareAction(yarnId));
  } catch (error: any) {
    console.error('Error in saga:', error.message);
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* removeYarnWashAndCareRequest(
  api: ApiType,
  action: ReturnType<typeof removeYarnWashAndCareAction>,
) {
  try {
    const { yarnId, junctionId } = action.payload;
    yield call(api.removeCollectionItem, Collections.YarnWashAndCare, junctionId);
    yield put(removeYarnWashAndCareSuccessAction());
    yield put(getYarnWashAndCareAction(yarnId));
  } catch (error: any) {
    console.error('Error in saga:', error.message);
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* getYarnNeedleSizesRequest(
  api: ApiType,
  action: ReturnType<typeof getYarnNeedleSizesAction>,
) {
  try {
    const yarnId = action.payload;
    const response: TransportResponse<YarnNeedleSizeCollectionItem[]> = yield call(
      api.getCollection,
      Collections.YarnNeedleSize,
      {
        filter: {
          Yarn_id: yarnId,
        },
        fields: ['*', 'needle_size_id.*'],
      },
    );
    if (!response.data) {
      throw new Error('Something went wrong');
    }
    const result = response.data.map((item) => ({
      ...item.needle_size_id,
      junctionId: item.id,
    }));
    yield put(getYarnNeedleSizesSuccessAction(result));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* createYarnNeedleSizeRequest(
  api: ApiType,
  action: ReturnType<typeof createYarnNeedleSizeAction>,
) {
  try {
    const { yarnId, needleSizeId } = action.payload;

    const response: Yarn = yield call(api.updateCollectionItem, Collections.Yarn, {
      id: yarnId,
      needle_size: { create: [{ Yarn_id: yarnId, needle_size_id: { id: needleSizeId } }] },
    });

    if (!response) {
      throw new Error('Failed to create yarn needleSize');
    }
    yield put(createYarnNeedleSizeSuccessAction(response));
    yield put(getYarnNeedleSizesAction(yarnId));
  } catch (error: any) {
    console.error('Error in saga:', error.message);
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* removeYarnNeedleSizeRequest(
  api: ApiType,
  action: ReturnType<typeof removeYarnNeedleSizeAction>,
) {
  try {
    const { yarnId, junctionId } = action.payload;
    yield call(api.removeCollectionItem, Collections.YarnNeedleSize, junctionId);
    yield put(removeYarnNeedleSizeSuccessAction());
    yield put(getYarnNeedleSizesAction(yarnId));
  } catch (error: any) {
    console.error('Error in saga:', error.message);
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* createMultipleYarnColorsRequest(
  api: ApiType,
  action: ReturnType<typeof createMultipleYarnColorsAction>,
) {
  try {
    const { yarnId, yarnColors } = action.payload;

    for (const yarnColor of yarnColors) {
      const { name, image } = yarnColor;

      const responseFile: FileCollectionItem = yield call(api.uploadFile, FileFolders.Yarns, image);

      if (!responseFile) {
        throw new Error('Failed to upload image');
      }

      const response: YarnColorCollectionItem = yield call(
        api.createCollectionItem,
        Collections.YarnColor,
        {
          yarn: yarnId,
          name,
          image: responseFile.id,
        },
      );

      if (!response) {
        throw new Error('Failed to create yarn color');
      }
    }

    yield put(createMultipleYarnColorsSuccessAction());
    yield put(getYarnColorsAction(yarnId));
  } catch (error: any) {
    console.error('Error in saga:', error.message);
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* createYarnColorRequest(api: ApiType, action: ReturnType<typeof createYarnColorAction>) {
  try {
    const yarnId = action.payload;

    yield delay(500);

    if (!yarnId) {
      throw new Error('Yarn ID is missing');
    }

    const response: YarnColorCollectionItem = yield call(
      api.createCollectionItem,
      Collections.YarnColor,
      {
        yarn: yarnId,
      },
    );

    if (!response) {
      throw new Error('Failed to create yarn color');
    }

    yield put(getYarnColorsAction(yarnId));
    yield put(createYarnColorSuccessAction(response));
  } catch (error: any) {
    console.error('Error in saga:', error.message);
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* setYarnColorRequest(api: ApiType, action: ReturnType<typeof setYarnColorAction>) {
  try {
    const { id, yarnId, name, ean, image, eanImage } = action.payload;
    let reponseColorImageFile: FileCollectionItem | undefined = undefined;
    let reponseEanImageFile: FileCollectionItem | undefined = undefined;
    if (image) {
      reponseColorImageFile = yield call(api.uploadFile, FileFolders.Yarns, image);
    }

    if (eanImage) {
      reponseEanImageFile = yield call(api.uploadFile, FileFolders.Yarns, eanImage);
    }
    const response: YarnColorCollectionItem = yield call(
      id ? api.updateCollectionItem : api.createCollectionItem,
      Collections.YarnColor,
      {
        id,
        yarn: yarnId,
        name,
        ean,
        image: reponseColorImageFile?.id,
        eanImage: reponseEanImageFile?.id,
      },
    );
    if (!response) {
      throw new Error('Something went wrong');
    }
    yield put(setYarnColorSuccessAction());
    yield put(getYarnColorsAction(yarnId));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* removeYarnColorRequest(api: ApiType, action: ReturnType<typeof removeYarnColorAction>) {
  try {
    const { yarnId, id } = action.payload;

    const response: Yarn = yield call(api.getCollectionItemById, Collections.Yarn, yarnId);
    if (!response) {
      throw new Error('Something went wrong');
    }
    if (response.main_color === id) {
      yield call(api.updateCollectionItem, Collections.Yarn, {
        id: yarnId,
        main_color: null,
      });
    }

    yield call(api.removeCollectionItem, Collections.YarnColor, id);
    yield put(setYarnColorSuccessAction());

    yield put(getYarnColorsAction(yarnId));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* setYarnMainColorRequest(api: ApiType, action: ReturnType<typeof setYarnMainColorAction>) {
  try {
    const { yarnId, colorId } = action.payload;
    const response: Yarn = yield call(api.updateCollectionItem, Collections.Yarn, {
      id: yarnId,
      main_color: colorId,
    });
    yield put(setYarnMainColorSuccessAction(response));
    yield put(getYarnColorsAction(yarnId));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* removeYarnColorImageRequest(
  api: ApiType,
  action: ReturnType<typeof removeYarnColorImageAction>,
) {
  try {
    const { yarnId, fileId } = action.payload;

    yield call(api.deleteFile, fileId);

    yield put(setYarnColorSuccessAction());

    yield put(getYarnColorsAction(yarnId));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* getYarniverseDataRequest(
  api: ApiType,
  action: ReturnType<typeof getYarniverseDataAction>,
) {
  try {
    const yarnId = action.payload;
    const response: Yarn = yield call(api.getCollectionItemById, Collections.Yarn, yarnId);

    if (!response) {
      throw new Error('Something went wrong');
    }

    yield put(getYarnColorsAction(response.id as number));
    yield put(getYarnGaugesAction(response.id as number));
    yield put(getYarnNeedleSizesAction(response.id as number));
    yield put(getYarnWashAndCareAction(response.id as number));

    yield put(getYarniverseDataSuccessAction(response));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* setYarnPriceRequest(api: ApiType, action: ReturnType<typeof setYarnPriceAction>) {
  try {
    const { yarnId, priceId, value } = action.payload;

    const response: YarnPriceCollectionItem = yield call(
      priceId ? api.updateCollectionItem : api.createCollectionItem,
      Collections.YarnPrice,
      {
        id: priceId,
        price: value,
        yarn: yarnId,
        currency: 1,
      },
    );

    if (!response) {
      throw new Error(Errors.Default);
    }

    yield put(setYarnDataAction({ id: yarnId, prices: [response.id] }));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* getYarnPricesRequest(api: ApiType, action: ReturnType<typeof getYarnPricesAction>) {
  try {
    const yarnId = action.payload;

    const response: TransportResponse<YarnPriceCollectionItem[]> = yield call(
      api.getCollection,
      Collections.YarnPrice,
      {
        filter: {
          yarn: yarnId,
        },
      },
    );
    if (!response.data) {
      throw new Error(Errors.Default);
    }

    yield put(getYarnPricesSuccessAction(response.data));
  } catch (error: any) {
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* publishYarnRequest(api: ApiType, action: ReturnType<typeof publishYarnAction>) {
  try {
    const { yarnId, callback } = action.payload;

    const response: Yarn = yield call(api.updateCollectionItem, Collections.Yarn, {
      id: yarnId,
      status: ProductStatus.Published,
    });

    console.log(response);

    if (!response) {
      throw new Error(Errors.Default);
    }

    yield call(callback, true);
    yield put(publishYarnSuccessAction());
  } catch (error: any) {
    console.log(error);
    yield put(displayErrorAction(error?.message as string));
    yield put(yarniverseErrorAction());
  }
}

function* archiveYarnRequest(api: ApiType, action: ReturnType<typeof archiveYarnAction>) {
  try {
    const { yarnId, callback } = action.payload;

    yield call(api.updateCollectionItem, Collections.Yarn, {
      id: yarnId,
      status: ProductStatus.Archived,
    });

    yield put(archiveYarnSuccessAction());

    yield call(callback, true);
  } catch (error: any) {
    yield put(archiveYarnErrorAction());
    yield put(displayErrorAction(error?.message as string));
  }
}

export const yarniverseSaga = function* (api: ApiType) {
  yield all([takeLatest(setYarnDataAction.type, setYarnDataRequest, api)]);
  yield all([takeLatest(setYarnColorAction.type, setYarnColorRequest, api)]);
  yield all([takeLatest(createYarnColorAction.type, createYarnColorRequest, api)]);
  yield all([takeLatest(getYarnColorsAction.type, getYarnColorsRequest, api)]);
  yield all([takeLatest(removeYarnColorImageAction.type, removeYarnColorImageRequest, api)]);
  yield all([takeLatest(removeYarnColorAction.type, removeYarnColorRequest, api)]);
  yield all([takeLatest(getYarniverseDataAction.type, getYarniverseDataRequest, api)]);
  yield all([takeLatest(setYarnPriceAction.type, setYarnPriceRequest, api)]);
  yield all([takeLatest(getYarnPricesAction.type, getYarnPricesRequest, api)]);
  yield all([takeLatest(createYarnGaugeAction.type, createYarnGaugeRequest, api)]);
  yield all([takeLatest(removeYarnGaugeAction.type, removeYarnGaugeRequest, api)]);
  yield all([takeLatest(getYarnGaugesAction.type, getYarnGaugesRequest, api)]);
  yield all([takeLatest(createYarnNeedleSizeAction.type, createYarnNeedleSizeRequest, api)]);
  yield all([takeLatest(removeYarnNeedleSizeAction.type, removeYarnNeedleSizeRequest, api)]);
  yield all([takeLatest(getYarnNeedleSizesAction.type, getYarnNeedleSizesRequest, api)]);
  yield all([takeLatest(createYarnWashAndCareAction.type, createYarnWashAndCareRequest, api)]);
  yield all([takeLatest(removeYarnWashAndCareAction.type, removeYarnWashAndCareRequest, api)]);
  yield all([takeLatest(getYarnWashAndCareAction.type, getYarnWashAndCareRequest, api)]);
  yield all([takeLatest(setYarnMainColorAction.type, setYarnMainColorRequest, api)]);
  yield all([takeLatest(publishYarnAction.type, publishYarnRequest, api)]);
  yield all([
    takeLatest(createMultipleYarnColorsAction.type, createMultipleYarnColorsRequest, api),
  ]);
  yield all([takeLatest(archiveYarnAction.type, archiveYarnRequest, api)]);
};
