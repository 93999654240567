import { PATH } from '@/common/constants/path';
import { DesignerSearchItem, PatternSearchItem, ResourceSearchItem, YarnSearchItem } from '@/store/types/search';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Tooltip } from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Clear,
  Container,
  FoundText,
  Header,
  Image,
  ImageContainer,
  Inner,
  Name,
  SubName,
  TopName,
  TextContainer,
  Title,
  BarResultTitle,
  BarResultSubTitle,
  BarResultShowAll,
  BarResultSeparator,
  BarResultHeader,
  EmptyResultContainer,
} from './styles';
import { User } from '@/common/interfaces/user.interface';
import { useTranslation } from 'react-i18next';
import s from './styles.module.css';

interface Props {
  patterns: PatternSearchItem[];
  yarns: YarnSearchItem[];
  recourses: ResourceSearchItem[];
  designers: DesignerSearchItem[];
  close: () => void;
  closeAndNavigate: (path:string, id?: number|string) => void;
  clear: () => void;
  searchBarResult?: boolean
  searchCompleted?: boolean
}

export const Result: FC<Props> = (props) => {
  const { patterns, yarns, recourses, close, closeAndNavigate, clear, designers, searchBarResult=false, searchCompleted=false } = props;
  const SUBSET_TO_SHOW = 4;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showAllPatternResults, setShowAllPatternResults] = useState(false);
  const [showAllYarnResults, setShowAllYarnResults] = useState(false);
  const [showAllDesignersResults, setShowAllDesignersResults] = useState(false);

  const navigateToPattern = (id: number) => {
    closeAndNavigate(PATH.PATTERN, id);
  };

  const navigateToYarn = (id: number) => {
    closeAndNavigate(PATH.YARN, id);
  };

  const navigateToDesigner = (id: string) => {
    closeAndNavigate(PATH.PROFILE, id);
  };

  const navigateToDesigners = () => {
    close();
    navigate(PATH.DESIGNERS);
  };

  const navigateToYarns = () => {
    close();
    navigate(PATH.YARNS);
  };


  const renderTitle = (title:String, count:number, showAllResults: boolean, toggleShowAllResults: () => void) => {
    const enabled = (title === 'Designers' || title === 'Yarns');
    let navigate = (title === 'Designers') ? navigateToDesigners : navigateToYarns;
    if (searchBarResult) return (
        <BarResultHeader>
            <div>
                <BarResultTitle>{title}</BarResultTitle>
                <BarResultSubTitle>{count} {t('search.resultsFound')}
                    {
                        count > SUBSET_TO_SHOW &&
                        <>
                            <BarResultSeparator />
                            <BarResultShowAll onClick={toggleShowAllResults}>{showAllResults ? 'Show Less' : 'Show All'}</BarResultShowAll>
                        </>
                    }


                </BarResultSubTitle>

            </div>
            <Tooltip title={enabled ? `View the search results in the ${title} page` : `Coming soon` } placement='top'>
            <div className={`${s.navButton} ${!enabled ? s.disabled : ''}` } onClick={enabled ? navigate:()=>{}}>
                        {`${title} page`}
                        <ExpandLessIcon />
            </div>
            </Tooltip>
        </BarResultHeader>
    )
    else return (
        <Header>
        <TextContainer>
          <Title>{title}</Title>
        </TextContainer>
      </Header>
    )
  }

  const renderEmptyResult = () => {
    return (
        <EmptyResultContainer><div style={{padding:'2rem'}}>No match for your search</div></EmptyResultContainer>
    )
  }

  const renderResult = () => {
    const resultCount = patterns?.length + yarns?.length + designers?.length;
  return (
    <>
      <Header>
        <div>
          <Title>{t('search.results')}</Title>
          {!!resultCount && <FoundText>{resultCount} {t('search.resultsFound')}</FoundText>}
        </div>
        {!designers && <Clear variant='text' onClick={()=>{}}>
          {t('search.clear')}
        </Clear>}
      </Header>

     {patterns.length > 0 && <>
        {renderTitle('Patterns', patterns.length, showAllPatternResults, () => setShowAllPatternResults(!showAllPatternResults))}
     <Inner>
        {patterns.slice(0, showAllPatternResults ? patterns.length : SUBSET_TO_SHOW).map((el) => (
          <Item
            key={'pattern_' + el.id}
            id={el.id}
            name={el.name}
            img={el.main_image?.image}
            designer_name= {el.user_created?.first_name + ' ' + el.user_created?.last_name}
            price={el.pattern_price?.length > 0 ? el.pattern_price[0].price : 'None'}
            onClick={navigateToPattern}
          />
        ))}
    </Inner></>}

    {yarns.length > 0 && <>
        {renderTitle('Yarns', yarns.length, showAllYarnResults, () => setShowAllYarnResults(!showAllYarnResults))}
    <Inner>
        {yarns.slice(0, showAllYarnResults ? yarns.length : SUBSET_TO_SHOW).map((el) => (
          <Item
            key={'yarn_' + el.id}
            id={el.id}
            name={el.name}
            img={el.main_image?.image}
            designer_name= {el.user_created?.first_name + ' ' + el.user_created?.last_name}
            price={el.yarn_price?.length > 0 ? el.yarn_price[0].price : 'None'}
            onClick={navigateToYarn}
          />
        ))}
    </Inner></>}

      {designers.length > 0 &&  <>
        {renderTitle('Designers', designers.length, showAllDesignersResults, () => setShowAllDesignersResults(!showAllDesignersResults))}
      <Inner>
       {designers.slice(0, showAllDesignersResults ? designers.length : SUBSET_TO_SHOW).map((el: User) => (
          <Item
            key={'resource_' + el.id}
            name={el.first_name}
            img={el.avatar || 'https://via.placeholder.com/150'}
            showAvatarOnly={true}
            price={'0'}
            onClick={()=>navigateToDesigner(el.id)}
          />
        ))}
      </Inner></>}

    </>
  );
}


return (
    <div className={s.leftSideScroll}>
    <div className={s.resultPanel}>
        {
            searchBarResult && (
            patterns.length ||
            yarns.length ||
            recourses.length ||
            designers.length) ?
            renderResult() :
            <>
                {
                    searchCompleted ?
                    renderEmptyResult() :
                    null
                }
            </>

        }
    </div>
    </div>
)
};

interface ItemProps {
  id?: number;
  name?: string;
  img?: string;
  designer_name?: string;
  price?: string;
  onClick: (id: number) => void;
  showAvatarOnly?: boolean;
}

const Item: FC<ItemProps> = (props) => {
  const { id, name, img, designer_name, price, onClick, showAvatarOnly } = props;
  const { t } = useTranslation();
  return (
    <ImageContainer onClick={() => onClick(id as number)}>
      <Tooltip title={name} placement='bottom'>
        <>
          {!showAvatarOnly && <TopName>{t('common.by')} {designer_name}</TopName>}
          <Image placeholder={name} src={getAssetBackendUrl(img || '', 400)}></Image>
          {!showAvatarOnly && <Name>{name}</Name>}
          {!showAvatarOnly && price != '0' && (<SubName>{price},- NOK</SubName>)}
        </>
      </Tooltip>
    </ImageContainer>
  );
};
