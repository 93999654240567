import * as React from 'react';
import { styled, alpha, Box } from '@mui/system';
import { Slider as BaseSlider, sliderClasses } from '@mui/base/Slider';

interface ZoomSliderProps {
  value: number;
  onChange: (value: number) => void;
  compact?: boolean;
}

export const ZoomSlider: React.FC<ZoomSliderProps> = ({ value, onChange, compact }) => {
  const handleChange = (_: Event, newValue: number | number[]) => {
    onChange(newValue as number);
  };

  return (
    <Box sx={{ 
      width: compact ? 120 : 200, 
      display: 'flex', 
      alignItems: 'center', 
      gap: compact ? '0.5rem' : '1rem' 
    }}>
      <Slider
        aria-label="Zoom"
        value={value}
        onChange={handleChange}
        step={50}
        marks={!compact}
        min={100}
        max={300}
        sx={{ 
          width: compact ? '80px' : '100%',
          padding: compact ? '8px 0' : '16px 0',
        }}
      />
      <span style={{ 
        fontSize: compact ? '0.875rem' : '1rem',
        minWidth: compact ? '3rem' : 'auto',
        textAlign: 'right'
      }}>
        {value}%
      </span>
    </Box>
  );
};

const Slider = styled(BaseSlider)(
  ({ theme }) => `
  color: #000;
  height: 6px;
  width: 100%;
  padding: 16px 0;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  &.${sliderClasses.disabled} {
    pointer-events: none;
    cursor: default;
    opacity: 0.4;
  }

  & .${sliderClasses.rail} {
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 6px;
    background-color: #E5EAF2;
  }

  & .${sliderClasses.track} {
    display: block;
    position: absolute;
    height: 4px;
    border-radius: 6px;
    background-color: #000;
  }

  & .${sliderClasses.thumb} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: -6px;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 50%;
    outline: 0;
    background-color: #000;
    transition-property: box-shadow, transform;
    transition-timing-function: ease;
    transition-duration: 120ms;
    transform-origin: center;

    &:hover {
      box-shadow: 0 0 0 6px ${alpha('#000', 0.1)};
    }

    &.${sliderClasses.focusVisible} {
      box-shadow: 0 0 0 8px ${alpha('#000', 0.2)};
      outline: none;
    }

    &.${sliderClasses.active} {
      box-shadow: 0 0 0 8px ${alpha('#000', 0.2)};
      outline: none;
      transform: scale(1.2);
    }
  }

  & .${sliderClasses.mark} {
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 99%;
    background-color: #ccc;
    transform: translateX(-50%);
  }

  & .${sliderClasses.markActive} {
    background-color: #000;
  }
`); 