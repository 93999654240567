import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import { MeasurementWithStage } from '@/store/types/collection';
import { Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Description, Item, Sizes, Title } from './styles';
import { t } from 'i18next';
import { Pattern } from '@/common/interfaces/pattern.interface';
import { UnitMeasurementCollectionItem } from '@/common/interfaces/collection.interface';
import { theme } from '@/common/mui-theme';

interface Props {
  availableSizes: number[];
  setSelectedSize: (id: number) => void;
  selectedSize: number | undefined;
  measurements: MeasurementWithStage[];
  measurement_units: UnitMeasurementCollectionItem[];
  pattern: Pattern;
}

export const MeasurementSize: FC<Props> = (props) => {
  const { availableSizes: availableSizesIds, selectedSize, setSelectedSize, measurements, measurement_units, pattern } = props;
  const { AvailableSize } = useSelector(getCollectionDataSelector);

  try {
    const availableSizes = (AvailableSize || [])
      .filter(x => x && x.id && availableSizesIds?.includes(x.id));

    const measurementsMemo = useMemo(() => {
      try {
        const unit = measurement_units?.find(unit => unit?.id === pattern?.unit_measurement)?.value || 'cm';
        return (measurements || []).map((el) => ({
          name: el?.name || '',
          value: (el?.measurement_values?.find((el) => el?.size_chart?.id === selectedSize)?.value || '') + ' ' + unit,
        }));
      } catch (error) {
        console.error('Error in measurementsMemo:', error);
        return [];
      }
    }, [measurements, selectedSize, measurement_units, pattern?.unit_measurement]);

    return (
      <>
        <Title>{t('patternizerSteps.advertisement.measurementsAndYarns')}</Title>
        <Description>{t('patternizerSteps.advertisement.selectSize')}</Description>
        <Sizes>
          {availableSizes.map((el) => (
            <Item
              key={el?.id || 'unknown'}
              variant='outlined'
              style={{ backgroundColor: selectedSize === el?.id ? theme.palette.secondary.main : 'transparent' }}
              onClick={() => setSelectedSize(el?.id || 0)}
            >
              {el?.size_name || ''}
            </Item>
          ))}
        </Sizes>

        <Typography mt='3rem' mb='1rem' variant='h3'>
          {t('patternizerSteps.advertisement.measurements')}
        </Typography>

        {measurementsMemo?.map((el, index) => (
          <div key={index}>
            <Typography variant='body1'>
              {el?.name}: {el?.value}
            </Typography>
          </div>
        ))}
      </>
    );
  } catch (error) {
    console.error('Error in MeasurementSize:', error);
    return (
      <Typography variant='body1' color="error">
        {t('common.errorLoading')}
      </Typography>
    );
  }
};
