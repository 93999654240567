import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button } from '@/components/button/button';
import { useTranslation } from 'react-i18next';
import { H3 } from '../heading';
import { TimeEditRowView } from '../time-row';

const SmallParagraph = styled.p`
    margin: 0;
    font-size: 22px;
    height: 25px;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;
`;

const OpeningHoursWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
    gap: 0rem;
    max-width: 450px;
`;

const SplitView = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 1rem;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

type OpeningHours = {
    text: string;
    closed: boolean;
    opening: string;
    closing: string;
}

interface EditOpeningHoursViewProps {
    weekdays: OpeningHours[];
    onSave: (days: OpeningHours[]) => void;
    onCancel: () => void;
}

const EditOpeningHoursView = ({ weekdays, onSave, onCancel }: EditOpeningHoursViewProps) => {
    const { t } = useTranslation();

    const [days, setDays] = useState<OpeningHours[]>(weekdays);


    const save = () => {
        onSave(days);
    }

    // Update the edited row's values
    const onEdit = (id: number, open: string, close: string, closed: boolean) => {
        const newDays = [...days];
        newDays[id] = {
            text: days[id].text,
            closed: closed,
            opening: open,
            closing: close
        }

        setDays(newDays);
    }

    return (
        <Wrapper>
            <SplitView>
                <Column>
                    {days.map((weekday, index) => (
                        <SmallParagraph key={index}>{weekday.text}</SmallParagraph>
                    ))}
                </Column>
                <Column>
                    {days.map((weekday, index) => (
                        <TimeEditRowView key={index} edit id={index} model={weekday} onChange={onEdit} />
                    ))}
                </Column>
            </SplitView>
            <SplitView>
                <Button onClick={save}>{t('common.save')}</Button>
                <Button onClick={onCancel}>{t('common.cancel')}</Button>
            </SplitView>
        </Wrapper>
    );
}

interface SavedOpeningHoursViewProps {
    weekdays: OpeningHours[];
    onEdit: () => void;
    showEdit?: boolean;
}

const SavedOpeningHoursView = ({ weekdays, onEdit, showEdit }: SavedOpeningHoursViewProps) => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <SplitView>
                <Column>
                    {weekdays.map((weekday, index) => (
                        <SmallParagraph key={index}>{weekday.text}</SmallParagraph>
                    ))}
                </Column>
                <Column>
                    {weekdays.map((weekday, index) => (
                        <>
                            {weekday.closed && <SmallParagraph>{t('distributor.closed')}</SmallParagraph>}
                            {!weekday.closed && <TimeEditRowView key={index} edit={false} id={index} model={weekday} />}
                        </>
                    ))}
                </Column>
            </SplitView>
            {showEdit && <Button onClick={onEdit}>{t('distributor.editOpeningHours')}</Button>}
        </Wrapper>
    );
}

interface OpeningHoursViewProps {
    weekdays: OpeningHours[];
    showEdit?: boolean;
}

export const OpeningHoursView = ({ weekdays, showEdit }: OpeningHoursViewProps) => {
    const { t } = useTranslation();
    const [edit, setEdit] = useState<boolean>(false);
    const [days, setDays] = useState<OpeningHours[]>(weekdays);

    const onEdit = () => {
        setEdit(true);
    }

    // Save the edited values and make a call to the backend
    const save = (days: OpeningHours[]) => {
        setDays(days);

        setEdit(false);

        // TODO: Make a call to the backend to save the edited values
    }

    // Cancel the edit and revert to the saved values
    const cancel = () => {
        setEdit(false);
    }

    return (
        <OpeningHoursWrapper>
            <H3>{t('distributor.openingHours')}</H3>

            {edit
                ? <EditOpeningHoursView weekdays={days} onSave={save} onCancel={cancel} />
                : <SavedOpeningHoursView weekdays={days} onEdit={onEdit} showEdit={showEdit} />}
        </OpeningHoursWrapper>
    );
}