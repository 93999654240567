import { Button } from '@/components/button/button';
import { getCollectionDataSelector } from '@/store/selectors/collection.selector';
import {
  getYarniverseDataSelector,
  getYarniverseWashAndCareSelector,
} from '@/store/selectors/yarniverse.selector';
import { Grid, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Item } from './components/item';
import { PageContainer, Row } from './styles';
import { useTranslation } from 'react-i18next';
import {
  createYarnWashAndCareAction,
  removeYarnWashAndCareAction,
} from '@/store/reducers/yarniverse.reducer';

interface Props {
  onPressNext: () => void;
}

export const WashAndCare: FC<Props> = (props) => {
  const { onPressNext } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { WashAndCare, TypeWashAndCare } = useSelector(getCollectionDataSelector);
  const { id: yarnId } = useSelector(getYarniverseDataSelector);

  const yarnWashAndCare = useSelector(getYarniverseWashAndCareSelector);
  const [selectedWashAndCareState, setSelectedWashAndCareState] = useState<Array<number>>(
    yarnWashAndCare.map((washAndCare) => washAndCare.id),
  );

  const getWashAndCareByType = (type: number) => {
    return WashAndCare.filter((item) => item.type_of_wash_and_care === type);
  };

  useEffect(() => {
    setSelectedWashAndCareState(yarnWashAndCare.map((washAndCare) => washAndCare.id));
  }, [yarnWashAndCare.length]);
  const handleSelect = (value: number) => {
    if (!yarnId) return;
    const yarnHasWashAndCare = selectedWashAndCareState?.find((x) => x === value) !== undefined;

    const updatedWashAndCare = yarnHasWashAndCare
      ? selectedWashAndCareState.filter((x) => x !== value)
      : [...selectedWashAndCareState, value];

    setSelectedWashAndCareState(updatedWashAndCare);

    if (yarnHasWashAndCare) {
      const junctionId = yarnWashAndCare.find((x) => x.id === value)?.junctionId;
      if (!junctionId) return;

      dispatch(
        removeYarnWashAndCareAction({
          yarnId: yarnId,
          junctionId,
        }),
      );
    } else {
      dispatch(
        createYarnWashAndCareAction({
          yarnId: yarnId,
          washAndCareId: value,
        }),
      );
    }
  };

  return (
    <PageContainer>
      {TypeWashAndCare.map((item) => (
        <div
          key={item.id + '_type_wash'}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            marginBottom: '2rem',
          }}
        >
          <Typography variant='h5' fontWeight='bold'>
            {t(`Yarniverse.washAndCareType.${item.name}`)}
          </Typography>
          <Row>
            {getWashAndCareByType(item.id)
              .sort((a, b) => (a?.sort ?? 0) - (b?.sort ?? 0))
              .map((item) => (
                <Item
                  key={item.id + '_item'}
                  isSelected={yarnWashAndCare.map((x) => x.id).includes(item.id as number) || false}
                  handleSelect={handleSelect}
                  item={item}
                />
              ))}
          </Row>
        </div>
      ))}
      <Grid m='2rem' width='100%'>
        <Button colored onClick={onPressNext}>
          {t('common.next')}
        </Button>
      </Grid>
    </PageContainer>
  );
};
