import { DirectusFields } from '@/common/constants/directus-fields';
import { Collections } from '@/common/interfaces/collection.interface';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ApiType } from '../../services/api';
import {
  loadItemFromBasketErrorAction,
  loadItemFromBasketSuccessAction,
  loadItemsFromBasketAction,
  incrementItemInBasketAction,
  incrementItemInBasketSuccessAction,
  incrementItemInBasketErrorAction,
  decrementItemInBasketAction,
  addItemToBasketAction,
} from '../reducers/basket.reducer';
import { getBasketDataSelector } from '../selectors/basket.selector';
import { BasketItem, BasketPatternResponse } from '../types/basket';

const EXCLUDED_FIELDS_BY_TYPE = {
  Pattern: ['prices'],
  Yarn: ['price'],
};

function* getBasketItemData(
  api: ApiType,
  item: BasketItem,
): Generator<any, BasketPatternResponse, any> {
  let response: BasketPatternResponse;

  switch (item.type) {
    case 'Pattern':
      response = yield call(api.getCollectionItemById, Collections.Patterns, item.id, {
        fields: DirectusFields.PatternBasket.filter(
          (field) => !EXCLUDED_FIELDS_BY_TYPE[item.type].includes(field),
        ),
      });
      break;
    case 'Yarn':
      // eslint-disable-next-line no-case-declarations
      const { data: product } = yield call(
        api.getCollectionItemByIdFromApi,
        Collections.Product,
        item.id,
        {
          fields: DirectusFields.YarnBasket.filter(
            (field) => !EXCLUDED_FIELDS_BY_TYPE[item.type].includes(field),
          ),
        },
      );

      // eslint-disable-next-line no-case-declarations
      const yarnColor = product.yarn_color;
      // eslint-disable-next-line no-case-declarations
      const yarn = product.yarn_color.yarn;

      response = yarn;
      response.price = product.price;
      response.id = yarnColor.id;
      response.main_image = {
        id: yarnColor.image.id,
        image: yarnColor.image.id,
      };
      response.variantName = yarnColor.name;

      break;
    default:
      throw new Error('Unknown item type');
  }

  return response;
}

function* loadItemsFromBasketRequest(api: ApiType) {
  try {
    const items: BasketItem[] = yield select(getBasketDataSelector);

    const result: BasketItem[] = [];

    let quantity;

    for (const item of items) {
      const response: BasketPatternResponse = yield getBasketItemData(api, item);
      if (item.data?.quantity) {
        quantity = item.data?.quantity;
      } else {
        quantity = 1;
      }

      result.push({
        type: item.type,
        id: item.id,
        data: {
          name: response.name,
          variantName: response.variantName,
          photo: response.main_image?.image,
          quantity: quantity,
          price: response.price,
          id: response.id,
          username: response.user_created.username || '',
          vendor:
            (item.type === 'Pattern' ? undefined : item?.vendor || item?.data?.vendor) ?? undefined,
        },
      });
    }

    yield put(loadItemFromBasketSuccessAction(result));
  } catch (error) {
    yield put(loadItemFromBasketErrorAction());
  }
}

function* incrementItemInBasketRequest(
  api: ApiType,
  action: ReturnType<typeof incrementItemInBasketAction>,
) {
  const { data } = action.payload;

  let maxValue;

  if (data?.quantity) {
    maxValue = data?.quantity + 1;
  }

  try {
    const items: BasketItem[] = yield select(getBasketDataSelector);

    const result: BasketItem[] = [];

    for (const item of items) {
      const response: BasketPatternResponse = yield getBasketItemData(api, item);

      if (item.id == data?.id) {
        result.push({
          type: item.type,
          id: item.id,
          data: {
            name: response.name,
            variantName: response.variantName,
            photo: response.main_image.image,
            quantity: maxValue,
            price: response.price ?? 0,
            id: response.id,
            username: response.user_created.username || '',
            vendor:
              (item.type === 'Pattern' ? undefined : item?.vendor || item?.data?.vendor) ??
              undefined,
          },
        });
      } else {
        result.push({
          type: item.type,
          id: item.id,
          data: {
            name: response.name,
            variantName: response.variantName,
            photo: response.main_image.image,
            quantity: item.data?.quantity,
            //quantity: maxValue,
            price: response.price ?? 0,
            id: response.id,
            username: response.user_created.username || '',
            vendor:
              (item.type === 'Pattern' ? undefined : item?.vendor || item?.data?.vendor) ??
              undefined,
          },
        });
      }
    }

    yield put(incrementItemInBasketSuccessAction(result));
  } catch (error) {
    yield put(incrementItemInBasketErrorAction());
  }
}

function* decrementItemInBasketRequest(
  api: ApiType,
  action: ReturnType<typeof decrementItemInBasketAction>,
) {
  const { data } = action.payload;

  let maxValue;

  if (data?.quantity) {
    maxValue = data?.quantity - 1;
  }

  try {
    const items: BasketItem[] = yield select(getBasketDataSelector);

    const result: BasketItem[] = [];

    for (const item of items) {
      const response: BasketPatternResponse = yield getBasketItemData(api, item);

      if (item.id == data?.id) {
        result.push({
          type: item.type,
          id: item.id,
          data: {
            name: response.name,
            variantName: response.variantName,
            photo: response.main_image.image,
            quantity: maxValue,
            price: response.price ?? 0,
            id: response.id,
            username: response.user_created.username || '',
            vendor:
              (item.type === 'Pattern' ? undefined : item?.vendor || item?.data?.vendor) ??
              undefined,
          },
        });
      } else {
        result.push({
          type: item.type,
          id: item.id,
          data: {
            name: response.name,
            variantName: response.variantName,
            photo: response.main_image.image,
            quantity: item.data?.quantity,
            price: response.price ?? 0,
            id: response.id,
            username: response.user_created.username || '',
            vendor:
              (item.type === 'Pattern' ? undefined : item?.vendor || item?.data?.vendor) ??
              undefined,
          },
        });
      }
    }

    yield put(incrementItemInBasketSuccessAction(result));
  } catch (error) {
    yield put(incrementItemInBasketErrorAction());
  }
}

function* addItemToBasketRequest(api: ApiType, action: ReturnType<typeof addItemToBasketAction>) {
  const { id, type, quantity = 1, vendor } = action.payload;

  try {
    const items: BasketItem[] = yield select(getBasketDataSelector);

    // Make sure the item already exists in the basket
    const existingItem = items.find((item) => item.id === id && item.type === type);
    if (!existingItem) {
      return;
    }

    let updatedBasket: BasketItem[] = [...items];

    if (existingItem.data && existingItem.data.id) {
      // If the item data exists, update its quantity
      updatedBasket = items.map((item) =>
        item.id === id && item.type === type && item.data && item.type !== 'Pattern'
          ? {
              ...item,
              data: {
                ...item.data,
                quantity: (existingItem.data?.quantity || 0) + quantity,
              },
            }
          : item,
      );
    } else {
      // If the item data does not exist, fetch its data
      const response: BasketPatternResponse = yield getBasketItemData(api, { id, type });
      updatedBasket = items.map((item) =>
        item.id === id && item.type === type && item.type !== 'Pattern'
          ? {
              ...item,
              data: {
                name: response.name,
                variantName: response.variantName,
                photo: response.main_image?.image,
                quantity,
                price: response.price,
                id: response.id,
                username: response.user_created.username || '',
                vendor: type === 'Pattern' ? undefined : vendor,
              },
            }
          : item,
      );
    }

    yield put(loadItemFromBasketSuccessAction(updatedBasket));
  } catch (error) {
    console.error('Failed to fetch basket item data:', error);
    yield put(loadItemFromBasketErrorAction());
  }
}

export const basketSaga = function* (api: ApiType) {
  yield all([takeLatest(loadItemsFromBasketAction.type, loadItemsFromBasketRequest, api)]);
  yield all([takeLatest(incrementItemInBasketAction.type, incrementItemInBasketRequest, api)]);
  yield all([takeLatest(decrementItemInBasketAction.type, decrementItemInBasketRequest, api)]);
  yield all([takeLatest(addItemToBasketAction.type, addItemToBasketRequest, api)]);
};
