import { useState, useCallback } from 'react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const API_TOKEN = localStorage.getItem('auth_token'); // Use environment variables in production

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${API_TOKEN}`,
    'Content-Type': 'application/json',
  },
});

const useDirectus = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const apiCall = useCallback(async (method: any, url: any, data = {}, params = {}) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiClient({ method, url, data, params });
      return response.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setError(typeof err.response?.data === 'string' ? err.response.data : err.message);
      } else {
        setError((err as Error).message);
      }
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const list = useCallback((collection: any, params: {} | undefined) => {
    return apiCall('get', `/items/${collection}`, {}, params);
  }, [apiCall]);

  const get = useCallback((collection: any, id: any) => {
    return apiCall('get', `/items/${collection}/${id}`);
  }, [apiCall]);

  const create = useCallback((collection: any, data: {} | undefined) => {
    return apiCall('post', `/items/${collection}`, data);
  }, [apiCall]);

  const update = useCallback((collection: any, id: any, data: {} | undefined) => {
    return apiCall('patch', `/items/${collection}/${id}`, data);
  }, [apiCall]);

  const remove = useCallback((collection: any, id: any) => {
    return apiCall('delete', `/items/${collection}/${id}`);
  }, [apiCall]);

  const relate = useCallback((junctionCollection: any, data: {} | undefined) => {
    return apiCall('post', `/items/${junctionCollection}`, data);
  }, [apiCall]);

  return { list, get, create, update, remove, relate, loading, error };
};

export default useDirectus;