import { RootState } from '..';

export const getPagesContentLoadingSelector = (state: RootState) => state.pagesContent.isLoading;

export const getHomePageContentSelector = (state: RootState) => state.pagesContent.content[0];

export const getRecentPatternUploadsLoadingSelector = (state: RootState) =>
  state.pagesContent.isRecentPatternUploadsLoading;

export const getRecentPatternUploadsSelector = (state: RootState) => state.pagesContent.recentPatternUploads;

export const getPromotedPatternsLoadingSelector = (state: RootState) =>
  state.pagesContent.isPromotedPatternsLoading;

export const getPromotedPatternsSelector = (state: RootState) =>
  state.pagesContent.promotedPatterns;

export const getDesignersOfTheWeekLoadingSelector = (state: RootState) =>
  state.pagesContent.isDesignersOfTheWeekLoading;

export const getDesignersOfTheWeekSelector = (state: RootState) =>
  state.pagesContent.designersOfTheWeek;

export const getRecentDesignersLoadingSelector = (state: RootState) =>
  state.pagesContent.isRecentDesignersLoading;

export const getRecentDesignersSelector = (state: RootState) =>
  state.pagesContent.recentDesigners;

export const getRecentYarnUploadsLoadingSelector = (state: RootState) =>
  state.pagesContent.isRecentYarnUploadsLoading;

export const getRecentYarnUploadsSelector = (state: RootState) => state.pagesContent.recentYarnUploads;

export const getYarnItemsListLoadingSelector = (state: RootState) =>
  state.pagesContent.isYarnItemsListLoading;

export const getYarnItemsListSelector = (state: RootState) => state.pagesContent.yarnItemsList;