import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  background: '#fff',
  borderRadius: '4px',
  overflow: 'hidden',
  
  [theme.breakpoints.down('laptop')]: {
    borderRadius: 0,
  }
}));

export const DiagramImage = styled('img')({
  width: '100%',
  height: 'auto',
  display: 'block',
});

export const ViewToggle = styled('button')({
  position: 'absolute',
  right: '8px',
  top: '8px',
  padding: '8px',
  background: 'rgba(255, 255, 255, 0.9)',
  border: '1px solid #000',
  borderRadius: '4px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  
  '&:hover': {
    background: '#F6F4ED',
  }
});
