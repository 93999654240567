import { styled } from '@mui/material';

export const Spacer = styled('div')<{ isVisible: boolean }>(({ isVisible }) => ({
  transition: 'width 0.3s ease-in-out',
  width: isVisible ? '13rem' : '0',
}));

export const Container = styled('div')<{ isVisible: boolean }>(({ isVisible, theme }) => ({
  position: 'absolute',
  width: '13rem',
  height: '100%',
  borderRight: '1px solid #000',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out',
  transform: isVisible ? 'translateX(0)' : 'translateX(-100%)',
  background: '#fff',

  [theme.breakpoints.down('laptop')]: {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRight: 'none',
  }
}));

export const TitleContainer = styled('div')({
  borderBottom: '1px solid #000',
});

export const Title = styled('h2')(({ theme }) => ({
  padding: '1rem 0rem 0rem 1.5rem',
  fontSize: '1.375rem', // 22px
  margin: 0,
  height: '3.5rem',

  [theme.breakpoints.down('laptop')]: {
    padding: '1rem 1.5rem',
  }
}));

export const StepList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  padding: '2rem 0.5rem 2rem 1.5rem',
  margin: 0,
  overflowY: 'auto',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '2rem',

  [theme.breakpoints.down('laptop')]: {
    padding: '1rem 1.5rem',
    gap: '1.5rem',
    paddingBottom: '2rem',
  }
}));

export const StepItem = styled('li')<{ isActive: boolean }>(({ isActive, theme }) => ({
  fontSize: '1.125rem', // 18px in rem
  cursor: 'pointer',
  fontWeight: isActive ? 'bold' : 'normal',
  textDecoration: isActive ? 'none' : 'underline',
  display: 'inline-block',

  [theme.breakpoints.down('laptop')]: {
    padding: '0.5rem 0', // Larger touch target
    borderBottom: '1px solid #eee',
    width: '100%',
  }
}));

export const KnitSettingsContainer = styled('div')(({ theme }) => ({
  borderTop: '1px solid #000',
  height: '3.5rem',
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('laptop')]: {
    position: 'sticky',
    bottom: 0,
    background: '#fff',
    boxShadow: '0 -2px 4px rgba(0,0,0,0.1)',
  }
}));

export const KnitSettingsButton = styled('div')<{ isDisabled: boolean }>(({ isDisabled }) => ({
  padding: '0rem 1.5rem',
  cursor: 'default',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textDecoration: 'underline',
  fontSize: '1.125rem', // 18px
  color: '#999', // Always grey
  userSelect: 'none', // Prevent text selection
}));

export const ToggleButton = styled('button')({
  position: 'absolute',
  right: '-30px',
  top: '50%',
  transform: 'translateY(-50%)',
  background: '#fff',
  border: '1px solid #000',
  borderLeft: 'none',
  padding: '10px 5px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
