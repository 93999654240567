import styled from '@emotion/styled';

export const H2 = styled.h2`
    font-size: 48px;
    line-height: 56px;
    font-weight: 400;
`;

export const H3 = styled.h3`
    font-size: 40px;
    line-height: 48px;
    font-weight: 400;
`;