export const exampleDescription = 'Strikkelilly er den garnbutikken i Oslo som har holdt til lengst på samme adresse, og er derfor Oslo`s eldste garnbutikk. Butikken ble startet opp i 1969, og het da Bentes Boutique. Lilly har siden 1986 vært ansatt i Bentes Boutique, og jobbet da i alle avdelingene i Oslo, Bjølsen (Gjøvikgt. 1A), Grunerløkka (Markveien 52) og Carl Berners plass (Chr. Michelsensgt. 1) og også etter hvert noen perioder i avdelingen på Østerås (Østerås kjøpesenter). Lilly ble etter hvert butikksjef i avdelingen på Bjølsen, og drev butikken i samarbeid med Bente til hun tok over driften i 2015 og skiftet navn til Strikkelilly.';

export const exampleStoreInfo = {
    country: 'Norway',
    street: 'Strikkelillyveien 1',
    postalCode: '1234',
    city: 'Oslo',
    phone: '+46 12 345 67 89',
    email: 'john.doe@email.com',
    followButton: {
        show: true,
        disabled: true,
        onClick: () => {},
    },
}

export const exampleOpeningHours = [
    {
        text: 'Mandag',
        closed: false,
        opening: '09:00',
        closing: '17:00',
    },
    {
        text: 'Tirsdag',
        closed: false,
        opening: '09:00',
        closing: '17:00',
    },
    {
        text: 'Onsdag',
        closed: false,
        opening: '09:00',
        closing: '17:00',
    },
    {
        text: 'Torsdag',
        closed: false,
        opening: '09:00',
        closing: '17:00',
    },
    {
        text: 'Fredag',
        closed: false,
        opening: '09:00',
        closing: '17:00',
    },
    {
        text: 'Lørdag',
        closed: false,
        opening: '09:00',
        closing: '15:00',
    },
    {
        text: 'Søndag',
        closed: true,
        opening: '09:00',
        closing: '15:00',
    },
];