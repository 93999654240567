import { YarnColorCollectionItem } from '@/common/interfaces/collection.interface';
import { YarnAlternativeWithStage, YarnCollectionItemWithStage } from '@/store/types/collection';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { Button } from '@/components/button/button';
import { FC } from 'react';
import { Item, WithoutColorButton, Wrapper } from './styles';
import { ButtonContainer } from '@/components/pattern-view/components/resources-sidebar/resources-sidebar';
import { t } from 'i18next';

interface Props {
  yarn?: YarnCollectionItemWithStage;
  title?: string;
  onAddYarn: (yarn: YarnCollectionItemWithStage, color: YarnColorCollectionItem | null) => void;
  onAddFirstYarn: (yarn: YarnCollectionItemWithStage, color: YarnColorCollectionItem | null) => void;
  alternatives?: YarnAlternativeWithStage[];
}

export const Colors: FC<Props> = (props) => {
  const { yarn, title, onAddYarn, onAddFirstYarn, alternatives } = props;

  return (
    <Wrapper>
      {/* <Title variant='h4'>{title}</Title> */}
      {alternatives?.length === 0 && yarn && yarn.colors.length > 0 && (
        <>
          <ButtonContainer>
            <Button onClick={() => onAddFirstYarn(yarn, yarn.colors[0])}>{t('patternizerSteps.addYarnType')}</Button>
          </ButtonContainer>
          <Item onClick={() => onAddYarn(yarn, yarn.colors[0])}>
            <img
              src={getAssetBackendUrl(yarn.colors[0].image, 200)}
              alt={yarn.colors[0].name || ''}
            />
          </Item>
        </>
      )}
      {alternatives?.length === 0 && yarn && yarn.colors.length === 0 && (
        <WithoutColorButton onClick={() => yarn && onAddFirstYarn(yarn, null)}>
          {t('patternizerSteps.addYarnType')}
        </WithoutColorButton>
      )}

      {alternatives && alternatives.length > 0 && yarn && yarn.colors.length > 0 && (
        <>
          <ButtonContainer>
            <Button onClick={() => onAddYarn(yarn, yarn.colors[0])}>{t('patternizerSteps.addYarnType')}</Button>
          </ButtonContainer>
          <Item onClick={() => onAddYarn(yarn, yarn.colors[0])}>
            <img src={getAssetBackendUrl(yarn.colors[0].image, 200)} alt={yarn.colors[0].name || ""} />
          </Item>
        </>
      )}
      {alternatives && alternatives.length > 0 && yarn && yarn.colors.length === 0 && (

          <WithoutColorButton onClick={() => yarn && onAddYarn(yarn, null)}>
            {t('patternizerSteps.addYarnType')}
          </WithoutColorButton>
      )}
    </Wrapper>
  );
};
