import React, { ChangeEventHandler } from 'react';
import styled from '@emotion/styled';

const CheckboxWrapper = styled.div`
    --size: 1rem;
    --background: #fff;
    font-size: var(--size);

    * {
        box-sizing: border-box;
    }

    input[type="checkbox"] {
        visibility: hidden;
        display: none;
    }

    .checkbox__label {
        width: var(--size);
    }

    .checkbox__label:before {
        content: ' ';
        display: block;
        height: var(--size);
        width: var(--size);
        position: absolute;
        top: calc(var(--size) * 0.125);
        left: 0;
        background: var(--background);  
    }

    .checkbox__label:after {
        content: ' ';
        display: block;
        height: var(--size);
        width: var(--size);
        border: calc(var(--size) * .14) solid #000;
        transition: 200ms;
        position: absolute;
        top: calc(var(--size) * 0.125);
        left: 0;
        background: var(--background);  
    }

    .checkbox__label:after {
        transition: 100ms ease-in-out;
    }

    .checkbox__input:checked ~ .checkbox__label:after {
        border-top-style: none; 
        border-right-style: none;
        -ms-transform: rotate(-45deg); /* IE9 */
        transform: rotate(-45deg);
        height: calc(var(--size) * .5);
        border-color: green;
    }

    .checkbox {
        position: relative;
        display: flex;
        cursor: pointer;
        /* Mobile Safari: */
        -webkit-tap-highlight-color: rgba(0,0,0,0);   
    }

    .checkbox__label:after:hover,
    .checkbox__label:after:active {
         border-color: green; 
    }

    .checkbox__label {
        margin-right: calc(var(--size) * 0.45);
    }
`;

interface AnimatedCheckboxProps {
    checked?: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

const AnimatedCheckbox = ({ checked, onChange }: AnimatedCheckboxProps) => {
    return (
        <CheckboxWrapper>
            <label className="checkbox">
                <input 
                    type="checkbox"
                    className="checkbox__input"
                    checked={checked}
                    onChange={onChange}
                />  
                <span className="checkbox__label"></span>
                Closed
            </label>
        </CheckboxWrapper>
    );
};

export default AnimatedCheckbox;
