import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Spacer, Container, TitleContainer, Title, StepList, StepItem, KnitSettingsContainer, KnitSettingsButton, ToggleButton } from './styles';
import { PatternStepWithStage } from '@/store/types/pattern';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { PATH } from '@/common/constants/path';
import { useTranslation } from 'react-i18next';

interface StepMenuProps {
  steps: PatternStepWithStage[];
  currentStep: PatternStepWithStage;
  onStepChange: (step: PatternStepWithStage) => void;
  isMenuVisible: boolean;
  onToggleMenu: () => void;
  patternId?: number;
  previewMode?: boolean;
  isMobile?: boolean;
  onMobileStepSelect?: () => void;
}

export const StepMenu: React.FC<StepMenuProps> = ({ 
  steps, 
  currentStep, 
  onStepChange, 
  isMenuVisible, 
  onToggleMenu,
  patternId,
  isMobile,
  onMobileStepSelect
}) => {
  const { t } = useTranslation();

  const handleStepClick = (step: PatternStepWithStage) => {
    onStepChange(step);
    if (isMobile && onMobileStepSelect) {
      onMobileStepSelect();
    }
  };

  return (
    <>
      <Spacer isVisible={isMenuVisible} />
      <Container isVisible={isMenuVisible}>
        <TitleContainer>
          <Title>{t('knitmode.steps')}</Title>
        </TitleContainer>
        <StepList>
          {steps.map((step) => (
            <StepItem 
              key={step.id} 
              onClick={() => handleStepClick(step)}
              isActive={step.id === currentStep.id}
            >
              {step.name}
            </StepItem>
          ))}
        </StepList>
        {!isMobile && (
          <KnitSettingsContainer>
            <KnitSettingsButton isDisabled>
              {t('knitmode.knitSettings')}
            </KnitSettingsButton>
          </KnitSettingsContainer>
        )}
        {!isMobile && (
          <ToggleButton onClick={onToggleMenu}>
            {isMenuVisible ? <ChevronLeft /> : <ChevronRight />}
          </ToggleButton>
        )}
      </Container>
    </>
  );
};
