import { closeSnackbarAction } from '@/store/reducers/system.reducer';
import { getSnackbarDataSelector } from '@/store/selectors/system.selector';
import { Alert as MuiAlert, AlertProps } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const Snackbar = () => {
  const { open, duration, message, severity } = useSelector(getSnackbarDataSelector);
  const dispatch = useDispatch();

  const handleClose = (_: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeSnackbarAction());
  };

  const disabled_because_demo = true;
  if (disabled_because_demo) return null;

  return (
    <MuiSnackbar open={open} autoHideDuration={duration || 3000} onClose={handleClose}>
      <Alert severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
};

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
