import { Button } from '@/components/button/button';
import {
  createMultipleYarnColorsAction,
  removeYarnColorAction,
  removeYarnColorImageAction,
  setYarnColorAction,
} from '@/store/reducers/yarniverse.reducer';
import {
  getYarniverseColorsSelector,
  getYarniverseDataSelector,
  getYarniverseLoadingSelector,
} from '@/store/selectors/yarniverse.selector';
import { Grid, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonContainer,
  ButtonUploadStyled,
  ColorContainer,
  PageContainer,
  TextContainer,
} from './styles';
import { useTranslation } from 'react-i18next';
import { YarnColorAndEanComponent } from '@/components/yarn-color-and-ean-component';
import { EmptyYarnColorPlaceholder } from '@/components/yarn-color-and-ean-component/placeholder';
import { YarnColorAndEanLoadingSkeleton } from '@/components/yarn-color-and-ean-component/loading';
import Resizer from 'react-image-file-resizer';
import { stripFileExtension } from '@/utils/stripFileExtension';

interface Props {
  onPressNext: () => void;
}

const resizeFile = (file: Blob): Promise<File> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      4000,
      4000,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri as File);
      },
      'file',
    );
  });

const MAX_IMAGE_DIMENSION = parseInt(process.env.REACT_APP_MAX_IMAGE_DIMENSION || '6000', 10);

export const Colors: FC<Props> = (props) => {
  const { onPressNext } = props;
  const yarn = useSelector(getYarniverseDataSelector);
  const yarnColors = useSelector(getYarniverseColorsSelector);
  const [numberOfLoadingColors, setNumberOfLoadingColors] = useState(1);
  const isLoading = useSelector(getYarniverseLoadingSelector);
  const [isAddingColors, setIsAddingColors] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setNumberOfLoadingColors(1);
      setIsAddingColors(false);
    }
  }, [isLoading]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const addYarnColor = () => {
    setIsAddingColors(true);
    dispatch(setYarnColorAction({ yarnId: yarn.id as number }));
  };

  const handleSave = (color: {
    id: number;
    image?: File;
    eanImage?: File;
    ean?: string;
    name?: string;
  }) => {
    console.info('DEBUGPRINT[7]: index.tsx:75: color=', color);
    dispatch(setYarnColorAction({ yarnId: yarn.id as number, ...color }));
  };

  const handleRemoveImage = (fileId?: string | null) => {
    if (fileId) dispatch(removeYarnColorImageAction({ yarnId: yarn.id as number, fileId }));
  };

  const handleRemove = (id: number) => {
    dispatch(removeYarnColorAction({ yarnId: yarn.id as number, id }));
  };

  const handleMultiColorUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      setIsAddingColors(true);
      setNumberOfLoadingColors(files.length);

      try {
        const processedFiles = await Promise.all(
          files.map(async (file) => {
            // Create an image element to check dimensions
            const img = new Image();
            const objectUrl = URL.createObjectURL(file);

            await new Promise((resolve, reject) => {
              img.onload = resolve;
              img.onerror = reject;
              img.src = objectUrl;
            });

            let finalFile = file;
            if (img.width > MAX_IMAGE_DIMENSION || img.height > MAX_IMAGE_DIMENSION) {
              finalFile = await resizeFile(file);
            }

            URL.revokeObjectURL(objectUrl);

            return {
              name: stripFileExtension(file.name),
              image: finalFile,
            };
          }),
        );

        dispatch(
          createMultipleYarnColorsAction({
            yarnId: yarn.id as number,
            yarnColors: processedFiles,
          }),
        );
      } catch (error) {
        console.error('Error processing images:', error);
        setIsAddingColors(false);
      }
    }
  };

  return (
    <PageContainer>
      <Grid>
        <Typography variant='h2'>{yarn.name}</Typography>
        <ColorContainer>
          {yarnColors.map((color) => (
            <YarnColorAndEanComponent
              key={color.id}
              name={color.name || undefined}
              ean={color.ean || undefined}
              colorImage={color.image}
              eanImage={color.eanImage}
              isLoading={isLoading}
              onSave={(c) =>
                handleSave({
                  id: color.id,
                  ean: c.ean,
                  name: c.name,
                  image: c.image,
                  eanImage: c.eanImage,
                })
              }
              onRemoveColorImage={() => handleRemoveImage(color.image)}
              onRemoveEanImage={() => handleRemoveImage(color.eanImage)}
              onRemove={() => handleRemove(color.id)}
            />
          ))}
          {isLoading && isAddingColors ? (
            [...Array(numberOfLoadingColors)].map((_, index) => (
              <YarnColorAndEanLoadingSkeleton key={index} />
            ))
          ) : (
            <EmptyYarnColorPlaceholder disabled={isLoading} onClick={() => addYarnColor()} />
          )}
        </ColorContainer>

        <TextContainer>{t('Yarniverse.resizeTextDescription')}</TextContainer>
        <ButtonContainer>
          <ButtonUploadStyled disabled={isLoading} onClick={addYarnColor}>
            + {t('Yarniverse.addColor')}
          </ButtonUploadStyled>

          <input
            type='file'
            multiple
            accept='image/*'
            onChange={handleMultiColorUpload}
            style={{ display: 'none' }}
            id='multi-color-upload'
          />
          <label htmlFor='multi-color-upload'>
            <ButtonUploadStyled disabled={isLoading} component='span'>
              + {t('Yarniverse.uploadMultipleColors')}
            </ButtonUploadStyled>
          </label>
        </ButtonContainer>
      </Grid>
      <Button style={{ marginTop: '2rem' }} colored onClick={onPressNext}>
        {t('common.next')}
      </Button>
    </PageContainer>
  );
};
