import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { styled, Typography } from '@mui/material';
import { getAssetBackendUrl } from '@/utils/getAssetBackendUrl';
import { removeItemInBasketAction } from '@/store/reducers/basket.reducer';
import { ItemInBasketPayload, BasketItemData } from '@/store/types/basket';
import { ProductType } from '@/common/constants/product-type';
import CounterClicker from './counter-clicker';
import { useTranslation } from 'react-i18next';

interface Props {
  id: number;
  type: ProductType;
  data?: Partial<BasketItemData>;
}
const ContainerRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  rowGap: '0.5rem',
});

const DeleteLink = styled('a')(({ theme }) => ({
  ...theme.typography.body1,
  textDecoration: 'underline',
  cursor: 'pointer',
}));

const ResponsiveContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: theme.palette.secondary.main,
}));

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  height: '12rem',
  [theme.breakpoints.down('laptop')]: {
    height: 'auto',
  },
}));

const PhotoContainer = styled('div')(({ theme }) => ({
  width: '12rem',
  minWidth: '12rem',
  [theme.breakpoints.down('laptop')]: {
    border: `1px solid ${theme.palette.primary.text}`,
  },
  [theme.breakpoints.down('tablet')]: {
    width: '8rem',
    minWidth: '8rem',
  },
}));

const Photo = styled('img')({
  height: '100%',
  width: '100%',
  aspectRatio: '1/1',
  objectFit: 'cover',
});

const DesktopProductDescription = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '1.25rem',
  [theme.breakpoints.down('laptop')]: {
    display: 'none',
  },
}));

const MobileProductDescription = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '1.25rem',
  border: `1px solid ${theme.palette.primary.text}`,
  borderTop: 'none',
  [theme.breakpoints.up('laptop')]: {
    display: 'none',
  },
}));

const PriceContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: '1.25rem',
  ...theme.typography.h4,
  [theme.breakpoints.down('laptop')]: {
    flexGrow: 1,
    border: `1px solid ${theme.palette.primary.text}`,
    borderLeft: 'none',
  },
}));

const Name = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  textDecoration: 'underline',
}));

const ProductName = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
}));

const Price = styled('div')({
  padding: '0.25rem',
  textAlign: 'right',
  fontWeight: 600,
});

export const ProductCard: FC<Props> = (props) => {
  const { id, type, data, ...rest } = props;

  const dispatch = useDispatch();
  const handleRemove = async (payload: ItemInBasketPayload) => {
    dispatch(removeItemInBasketAction(payload));
  };

  const { t } = useTranslation();

  return (
    <ContainerRoot {...rest}>
      <DeleteLink onClick={() => handleRemove({ id, type })}> {t('Basket.delete')}</DeleteLink>
      <ResponsiveContainer>
        <Container>
          <PhotoContainer>
            <Photo src={getAssetBackendUrl(data?.photo || '', 200)} />
          </PhotoContainer>
          <DesktopProductDescription>
            <Name>{data?.username}</Name>
            <ProductName>{data?.name}</ProductName>
            <ProductName>{data?.variantName}</ProductName>
          </DesktopProductDescription>
          <PriceContainer>
            {/* <CounterClicker key={`${type}-${id}`} id={id} type={type} data={data} /> */}
            {type !== 'Pattern' ? (
              <CounterClicker key={`${type}-${id}`} id={id} type={type} data={data} />
            ) : (
              <p> </p>
            )}
            <Price>
              {`${((data?.price as number) ?? 0) * ((data?.quantity as number) ?? 1)} - ${t(
                'common.currency',
              )}`}
            </Price>
          </PriceContainer>
        </Container>
        <MobileProductDescription>
          <Name>{data?.username}</Name>
          <ProductName>{data?.name}</ProductName>
          <ProductName>{data?.variantName}</ProductName>
        </MobileProductDescription>
      </ResponsiveContainer>
    </ContainerRoot>
  );
};
