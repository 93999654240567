import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Inventory } from '../types/stores';

export type Store = {
  id: number;
  name: string;
  inventories: Inventory[];
};

export interface StoresState {
  isLoading: boolean;
  currentStore?: Store;
  stores: Store[];
}

const initialState: StoresState = {
  isLoading: false,
  currentStore: undefined,
  stores: [],
};

export const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    getStoresDataAction: (state, _: PayloadAction<number>) => {
      state.isLoading = true;
    },
    getStoresSuccessDataAction: (state, action: PayloadAction<Store>) => {
      state.isLoading = false;
      state.currentStore = action.payload;
    },
    storesErrorAction: (state) => {
      state.isLoading = false;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(logoutSuccessAction, () => initialState);
  // },
});

export const {
    getStoresDataAction,
    getStoresSuccessDataAction,
    storesErrorAction,
} = storesSlice.actions;

export const storesReducer = storesSlice.reducer;
