import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  overflow: 'hidden',
}));

export const TitleContainer = styled('div')({
  borderBottom: '1px solid #000',
});

export const Title = styled('h2')<{ isMobile?: boolean }>(({ isMobile, theme }) => ({
  padding: '1rem 0rem 0rem 1.5rem',
  fontSize: '1.375rem', // 22px
  margin: 0,
  height: '3.5rem',
  fontStyle: 'italic',

  [theme.breakpoints.down('laptop')]: {
    padding: '1rem 1.5rem',
  }
}));

export const InstructionsContainer = styled('div')<{ fontSize: number }>(({ fontSize, theme }) => ({
  padding: '2rem 2rem 0rem 2rem',
  overflowY: 'auto',
  flex: 1,

  [theme.breakpoints.down('laptop')]: {
    padding: '1rem',
    '-webkit-overflow-scrolling': 'touch',
    overscrollBehavior: 'contain',
    touchAction: 'pan-y pinch-zoom',
  }
}));

export const NavigationBar = styled('div')<{ isMobile?: boolean }>(({ isMobile }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: isMobile ? '0.75rem' : '1rem',
  borderTop: '1px solid #000',
  height: '3.5rem',
  background: '#fff',
  position: 'relative',
  zIndex: 1,
}));

export const NavigationGroup = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
});

export const StepNavigationText = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'underline',
  padding: '0.5rem',  // Larger touch target for mobile

  [theme.breakpoints.down('laptop')]: {
    fontSize: '1rem',
  }
}));

export const SwipeHint = styled('div')<{ isVisible: boolean }>(({ isVisible }) => ({
  position: 'fixed',
  bottom: 'calc(3.5rem + 4rem)',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  gap: '2rem',
  justifyContent: 'center',
  opacity: isVisible ? 0.8 : 0,
  transition: 'opacity 0.3s ease-in-out',
  pointerEvents: 'none',
}));

export const SwipeArrow = styled('div')<{ direction: 'left' | 'right' }>(({ direction }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  fontSize: '0.875rem',
  color: '#000',
  '&::before': {
    content: '""',
    width: '20px',
    height: '2px',
    background: '#000',
    position: 'relative',
    transform: direction === 'left' ? 'rotate(-45deg)' : 'rotate(45deg)',
  }
}));

export const DiagramViewerContainer = styled('div')(({ theme }) => ({
  borderTop: '1px solid #000',
  background: '#fff',
  overflow: 'hidden',
  
  [theme.breakpoints.down('laptop')]: {
  }
}));
